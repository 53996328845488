import React from 'react';

function SecRespAlef() {
    return (
        <section className="sec-slider py-4 bg-yellow over-hide">
            <div className="container text-center">
                <h2 className="text-xxl">הקורסים האלו יכינו את ילדיכם לכיתה א׳ בצורה הטובה ביותר, בהתחייבות!</h2>
                <div className="mt-1 mb-1">
                    <p>
                        ׳דמיון בקופסא׳ הוא העוזר אישי של ילדיכם בתקופה הקריטית של תהליך המעבר מגן הילדים לכיתה א׳.
                        הקורסים יגרמו לגלגלים בראשם לדהור קדימה בעולם המספרים והאותיות, ילוו אותם בדרך מיוחדת, משמעותית
                        ויצירתית, כזו שהם עוד לא פגשו.
                    </p>
                    <br></br>

                    <p>עד כמה אני בטוח בזה?</p>
                    <u className="text-xl">יש לכם 7 ימי אחריות על הקורסים.</u>
                    <br></br>
                    <u className="text-xl">לא אהבתם? תקבלו מאיתנו 100% החזר כספי!</u>
                    <br></br>
                    <br></br>

                    <p>בלי אותיות קטנות ובלי שתצטרכו להסביר את עצמכם.</p>
                </div>
            </div>
        </section>
    );
}

export default SecRespAlef;
