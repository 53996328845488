import React from 'react';
import logoImage from '../assets/logo/white.svg';
import MenuImg from '../assets/icons/yellowMenu.svg';
import videoImage from '../assets/images/home.webp';
import messages from '../assets/messages_he.json';
import Video from '../features/video/Video';
import MainLayout from '../common/layouts/MainLayout';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { useHome } from './useHome';
import './Home.css';
// import MicTest from '../features/mic/MicTest';

const home_vid = 'https://youtu.be/RNd_tzPVsEE';

function Home(): JSX.Element {
    const [play, setPlay] = React.useState(false);

    const { render_course_cards, render_drawings, render_login } = useHome();
    const { getMQid, is_horiz } = useMediaQueryWrap();
    const rid = getMQid();
    const orientation = is_horiz ? 'landscape' : 'portrait';

    const render_home = () => {
        return (
            <MainLayout can_url="/home" id={`home_${rid}`} logo={logoImage} appMenuLogo={MenuImg}>
                <div id={rid} className="homeContent">
                    <div id={orientation} className="homeTopContent">
                        <div id={orientation} className="homeTopTextContent">
                            <h1 id={rid} className="homeTextHeader">
                                חובה ל-א׳!
                            </h1>
                            <h2 id={rid} className="homeTextHeader2">
                                דמיון בקופסא: סיפורים אינטראקטיביים לרכישת מיומנויות חשבון, קריאה וכתיבה, לפני כיתה א׳
                                ובמהלכה
                            </h2>
                            <h2 id={rid} className="homeTextHeader3">
                                {messages['home.description2']}
                            </h2>
                            <h3 id={rid} className="homeTextHeaderBullets">
                                {messages['home.description3_1']} <b></b> {messages['home.description3_3']}
                            </h3>
                            {render_login()}
                        </div>
                        {!is_horiz ? render_course_cards() : null}

                        <div
                            id={orientation}
                            className="homeVideo"
                            style={{
                                WebkitMaskImage: !play ? '../assets/images/video-shape.svg' : 'unset',
                                maskImage: !play ? '../assets/images/video-shape.svg' : 'unset',
                            }}
                        >
                            <Video
                                url={home_vid}
                                vid_over_cb={() => null}
                                img={videoImage}
                                play={play}
                                setPlay={setPlay}
                                controls={true}
                                log_click={'home_video_click'}
                            />
                        </div>
                    </div>
                    <div className="homeBottomContent">
                        <div id={rid} className="homeBottomTextContent">
                            <h3 id={rid} className="homDescTextHeader">
                                {messages['home.bottom-description-header']}
                            </h3>
                            <h3 id={rid} className="homDescText">
                                {messages['home.bottom-description-text']}
                            </h3>
                            {is_horiz ? render_course_cards() : null}
                        </div>
                    </div>
                </div>
                {render_drawings()}
                {/* <MicTest /> */}
            </MainLayout>
        );
    };

    return render_home();
}

export default Home;
