import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { ICameraModal } from './CameraModal';
import Webcam from 'react-webcam';

export function useCamera(props: ICameraModal) {
    const { state, setState } = useContext(AppContext);
    const [img, setImg] = React.useState<string | null>(null);
    const webCamRef = React.useRef<Webcam>(null);

    const setCameraOpen = (flag: boolean) => {
        setState({ ...state, cameraOpen: flag });
    };

    const capture = React.useCallback(() => {
        if (webCamRef.current != null) {
            const img_ = webCamRef.current.getScreenshot();
            if (!img_) return;
            setImg(img_);
        }
    }, [webCamRef]);

    const finish = () => {
        setImg(null);
        props.on_camera_over(img!);
    };

    return { setCameraOpen, capture, finish, webCamRef, img, setImg };
}
