import React from 'react';
import { useMediaQueryWrap } from '../../common/useMediaQueryWrap';
import Turnscreen from '../../common/layouts/TurnScreen';
import useSound from 'use-sound';
import sccSound from 'src/assets/sounds/success1.mp3';
import Card from './Card';
import CircleButton from 'src/common/buttons/CircleButton';
import megaphoneSvg from 'src/assets/icons/megaphone.svg';
import { useMemoryCardsConfig } from './MemoryCardsConfig';
import './MemoryCards.css';

export interface IMemoryCards {
    width: number;
    height: number;
    onFinish: () => void;
    lvl: number;
}

const columns = 4;
const rows = 3;
const cells = columns * rows;
const cells_arr = [...Array(cells).keys()];

// config
const finished: boolean[] = cells_arr.map(() => false);
const visable: boolean[] = cells_arr.map(() => false);

const delay = (delayInms: number) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
};

function MemoryCards({ width, height, onFinish, lvl }: IMemoryCards): JSX.Element {
    const { games } = useMemoryCardsConfig();
    const { should_turn_screen } = useMediaQueryWrap();
    const [playSuccess] = useSound(sccSound, { volume: 0.2 });
    const game = games[lvl];
    const [started, setStarted] = React.useState(!game.instructions);

    const [playInstructions] = useSound(game.instructions, {
        volume: 1,
        onend: () => {
            setStarted(true);
        },
    });

    const onInsturctionClick = () => {
        playInstructions();
        setStarted(true);
    };

    const [cardsFinished, setCardsFinished] = React.useState(finished);
    const [cardsVisable, setCardsVisable] = React.useState(visable);
    const [currCard, setCurrCard] = React.useState(-1);
    const [boardLocked, setBoardLock] = React.useState(false);

    const col_step = width / columns;
    const row_step = height / rows;

    const render_size = Math.min(col_step, row_step);
    const min_step = Math.min(col_step, row_step);

    const flat_idx_to_pos = (idx: number) => {
        const x_idx = idx % columns;
        const y_idx = Math.floor(idx / columns);

        let left = width - min_step * (x_idx + 1);
        let top = height - min_step * (rows - y_idx);

        const spare_size = Math.max(col_step, row_step) - min_step;

        if (row_step > col_step) top -= (spare_size * (columns - 1)) / 4;
        else left -= (spare_size * (columns - 1)) / 2;

        return {
            left,
            top,
        };
    };

    const cell_pos = cells_arr.map((_, index) => {
        return flat_idx_to_pos(index);
    });

    const card_click = async (idx: number) => {
        if (boardLocked) return;

        const newCardsVisable = [...cardsVisable];
        newCardsVisable[idx] = true;
        setCardsVisable(newCardsVisable);

        if (currCard === -1) {
            setCurrCard(idx);
            return;
        }

        if (currCard === game.pairs[idx]) {
            playSuccess();
            const newCardsFinished = [...cardsFinished];
            newCardsFinished[idx] = true;
            newCardsFinished[currCard] = true;
            setCardsFinished(newCardsFinished);

            const isFinished = newCardsFinished.every((card) => card);
            if (isFinished) onFinish();
        } else {
            setBoardLock(true);
            await delay(2500);
            setBoardLock(false);

            newCardsVisable[idx] = false;
            newCardsVisable[currCard] = false;
            setCardsVisable(newCardsVisable);
        }

        setCurrCard(-1);
    };

    const render = () => {
        if (should_turn_screen()) return <Turnscreen />;
        return (
            <div className="MemoryCards_wrap" style={{ width: width, height: height }}>
                {cell_pos.map((cell, index) => (
                    <Card
                        finished={cardsFinished[index]}
                        visable={cardsVisable[index]}
                        idx={index}
                        key={index}
                        size={render_size}
                        top={cell.top}
                        left={cell.left}
                        img={game.pairs_imgs[index]}
                        card_click={started ? card_click : () => null}
                    />
                ))}
                {!started && (
                    <div
                        className="game_instruction"
                        style={{
                            width: render_size,
                            height: render_size,
                            top: cell_pos[0].top,
                            left: cell_pos[0].left,
                        }}
                    >
                        <CircleButton
                            svg={megaphoneSvg}
                            color={'white'}
                            onClick={onInsturctionClick}
                            zoom={render_size / 85.3}
                            ariaLabel="הוראות"
                            inf_anim
                        />
                    </div>
                )}
            </div>
        );
    };

    return render();
}

export default MemoryCards;
