import { useNavigate } from 'react-router-dom';
import { log_event } from './common';
import { useContext } from 'react';
import { AppContext } from 'src/AppContext';

export function useNav() {
    const { state } = useContext(AppContext);
    const navigate = useNavigate();

    const navigateToClassroom = () => {
        const path = '/classroom';
        log_event('classroom_click', state.course_key);
        navigate(path);
    };

    const navigateToFolder = () => {
        const path = '/folder';
        log_event('folder_click', state.course_key);
        navigate(path);
    };

    const navigateToHome = () => {
        const path = '/home';
        navigate(path);
    };

    const navigateToLanding = () => {
        const path = '/';
        navigate(path);
    };

    const navigateToClass = () => {
        const path = '/class';
        navigate(path);
    };

    const navigateToMoreTasks = () => {
        log_event('more_tasks_click', state.course_key);
        const path = '/more-tasks';
        navigate(path);
    };

    const navigateToaMoreTask = () => {
        const path = '/more-task';
        navigate(path);
    };

    const navigateToNiceToMeet = () => {
        const path = '/nice-to-meet';
        log_event('nice_to_meet_click');
        navigate(path);
    };

    const navigateToAboutRoy = () => {
        const path = '/about-roy';
        log_event('about_roy_click');
        navigate(path);
    };

    const navigateToFirstGrade = () => {
        const path = '/first-grade';
        log_event('first_grade_click');
        navigate(path);
    };

    const navigateToContact = () => {
        const path = '/contact';
        log_event('contact_us_click');
        navigate(path);
    };

    const navigateToTerms = () => {
        const path = '/terms';
        navigate(path);
    };

    const navigateToPrivacy = () => {
        const path = '/privacy';
        navigate(path);
    };

    const navigateToGames = () => {
        const path = '/games';
        log_event('games_click', state.course_key);
        navigate(path);
    };

    const navigateToAGame = () => {
        const path = '/game';
        navigate(path);
    };

    return {
        navigateToClassroom,
        navigateToFolder,
        navigateToHome,
        navigateToClass,
        navigateToMoreTasks,
        navigateToNiceToMeet,
        navigateToFirstGrade,
        navigateToContact,
        navigateToaMoreTask,
        navigateToTerms,
        navigateToPrivacy,
        navigateToGames,
        navigateToAGame,
        navigateToLanding,
        navigateToAboutRoy,
    };
}
