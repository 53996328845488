import React from 'react';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import AbRuler from 'src/features/letters/AbRuler';
import Mic from 'src/features/mic/Mic';
import { ITask } from 'src/wrapper';

export interface IClassProgMicTask {
    task?: ITask;
    letterZoom: number;
    setPlayProgVid: Function;
    on_task_over: Function;
}
export function useClassProgMicTask({ task, letterZoom, setPlayProgVid, on_task_over }: IClassProgMicTask) {
    const { get_action_btn_zoom } = useMediaQueryWrap();

    const [progTaskIndex, setProgTaskIndex] = React.useState(0);
    const [progTaskStopIndex, setProgTaskStopIndex] = React.useState(0);
    const progTask = task?.progressVideo?.tasks[progTaskIndex];

    const render_ab_ruler_prog = () => {
        if (!task) return null;
        if (!progTask?.ab_highlight && !progTask?.micTask?.ab_match) return null;
        if (!task.progressVideo?.ab_ruler) return null;
        const ab_ruler_letter = progTask.ab_highlight ?? progTask.micTask?.ab_match;
        return <AbRuler letters={[ab_ruler_letter!]} zoom={letterZoom} id={progTask!.ab_type} />;
    };

    const nextProgTask = (on_vid: boolean) => {
        if (!task) return;
        if (progTaskIndex == task.progressVideo!.tasks.length - 1) {
            on_task_over();
        } else {
            setProgTaskIndex(progTaskIndex + 1);
            if (on_vid) setProgTaskStopIndex(progTaskStopIndex + 1);
        }
    };

    const on_mic_prog_over = () => {
        nextProgTask(false);
        setPlayProgVid(true);
    };

    const render_mic_prog = () => {
        if (progTask?.micTask)
            return <Mic task={progTask.micTask} mic_over_cb={on_mic_prog_over} btn_zoom={get_action_btn_zoom()} />;
        if (progTask?.ab_highlight) return null;
        return null;
    };

    return {
        render_mic_prog,
        render_ab_ruler_prog,
        nextProgTask,
        progTaskStopIndex,
        setProgTaskIndex,
        setProgTaskStopIndex,
    };
}
