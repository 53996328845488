import React from 'react';
import CircleButton from '../../common/buttons/CircleButton';
import cameraSvg from '../../assets/icons/camera.svg';
import CameraModal from './CameraModal';
import { useCamera } from './useCamera';

interface ICamera {
    on_camera_over: (img: string) => void;
    btn_zoom: number;
}

function Camera(props: ICamera) {
    const { setCameraOpen } = useCamera(props);

    return (
        <div>
            <CircleButton
                svg={cameraSvg}
                color={'purple'}
                onClick={() => setCameraOpen(true)}
                zoom={props.btn_zoom}
                ariaLabel="לצלם עבודה"
            />
            <CameraModal {...props} />
        </div>
    );
}

export default Camera;
