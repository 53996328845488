import React from 'react';
import messages from '../assets/messages_he.json';
import { layoutId } from './layouts/MainLayout';
import { useNav } from './useNav';
import './AppRights.css';

export interface IAppRights {
    id: layoutId;
}
function AppRights({ id }: IAppRights): JSX.Element {
    const { navigateToTerms, navigateToPrivacy } = useNav();
    return (
        <footer className="appRightsWrapper">
            <p className="appRightsText">
                {messages['app.rights']}
                {id.includes('home') && (
                    <>
                        {' | '}
                        <button
                            id={'link'}
                            className="appRightsText"
                            aria-label={messages['app.rights.link1']}
                            onClick={navigateToTerms}
                        >
                            {messages['app.rights.link1']}
                        </button>
                        {' | '}
                        <button
                            id={'link'}
                            aria-label={messages['app.rights.link2']}
                            onClick={navigateToPrivacy}
                            className="appRightsText"
                        >
                            {messages['app.rights.link2']}
                        </button>
                    </>
                )}
            </p>
        </footer>
    );
}

export default AppRights;
