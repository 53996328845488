import React from 'react';
import { buttonColors, buttonColorType } from './colors';
import './CircleButton.css';

interface ICircleButton {
    onClick: () => void;
    color?: buttonColorType;
    svg: string;
    ariaLabel: string;
    zoom?: number;
    inf_anim?: boolean;
    disabled?: boolean;
}

function CircleButton(props: ICircleButton): JSX.Element {
    const background = props.color ? buttonColors[props.color].background : 'transparent';
    const shadow = props.color ? buttonColors[props.color].shadow : 'transparent';
    return (
        <button
            id={props.inf_anim ? 'endless' : ''}
            className="cb_button"
            onClick={props.onClick}
            style={{
                background: background,
                boxShadow: `1px 2px 0px 3px ${shadow}`,
                zoom: props.zoom ?? 1,
            }}
            disabled={props.disabled}
            aria-label={props.ariaLabel}
        >
            <img src={props.svg} className="cb_button_img" alt={props.ariaLabel} />
        </button>
    );
}

export default CircleButton;
