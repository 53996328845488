import React from 'react';
import ShipA from '../../assets/images/ship-A.svg';
import ShipB from '../../assets/images/ship-B.svg';
import ShipC from '../../assets/images/ship-C.svg';
import Night1 from '../../assets/images/night-1.svg';
import Night2 from '../../assets/images/night-2.svg';
import Night3 from '../../assets/images/night-3.svg';
import Night4 from '../../assets/images/night-4.svg';
import Video from 'src/features/video/Video';
import videoImage from 'src/assets/images/landing_heb_vid_image.webp';
import { useMediaQueryWrap } from 'src/common/useMediaQueryWrap';
import wizardSvg from 'src/landing/assets/images/wizard.svg';
import rudiSvg from 'src/assets/drawings/Rabbit.svg';
import { log_event, scroll_to_element } from 'src/common/common';
import './sec-night.css';

const vid_url = 'https://youtu.be/RK5yNEDRCWc?si=TPbPWoz4z_ixLFoY';

interface secNightProps {
    primBtnText: string;
}

function SecNightHeb({ primBtnText }: secNightProps) {
    const [play, setPlay] = React.useState(false);
    const { getMQid, render_float_anim } = useMediaQueryWrap();
    const rid = getMQid();

    const render_drawings = () => {
        if (rid === 'mobile' || !render_float_anim) return null;
        return (
            <>
                <img src={wizardSvg} className="landing_float_wizard float float-delay" alt="animation" />
                <img src={rudiSvg} className="landing_float_left float float-delay" alt="animation" />
            </>
        );
    };

    return (
        <section id="SecNight" className="sec-night py-4 relative text-center text-white">
            {render_drawings()}
            <div className="container z-2">
                <div className="all-ships flex justify-center">
                    <img className="ship_svg" src={ShipA} alt="ShipA" />
                    <img className="ship_svg" src={ShipB} alt="ShipB" />
                    <img className="ship_svg" src={ShipC} alt="ShipC" />
                </div>
                <h2 className="title-42 mx-auto">קריאה וכתיבה עם רודי הארנב</h2>
                <p className="text-xl font-bold">
                    {' '}
                    הופכים את הילדים <span className="text-yellow">לאלופי האותיות והצלילים</span>
                </p>
                <br></br>
                <p>
                    רודי עומד לעלות לכיתה א׳. הוא מאד מתרגש, אך גם קצת חושש. בגינה על יד ביתו מוצא את הקופסא שהכין לו
                    הקוסם צליל. כדי לפתוח אותה, עליו לבצע משימות שונות שיעזרו לו להתכונן לבית הספר. עזרו לרודי לגלות מה
                    מסתתר בקופסא המסתורית.
                </p>
                <div className="night-icons grid grid-cols-4">
                    <div>
                        <img src={Night1} alt="night1" />
                        <div className="text-base mx-auto">
                            פיתוח וחיזוק ההיכרות עם אותיות וצלילים (מודעות פונולוגית)
                        </div>
                    </div>
                    <div>
                        <img src={Night2} alt="night2" />
                        <div className="text-base mx-auto">
                            סיפור אינטראקטיבי המלווה באנימציה שילדים אוהבים בו מתקדמים על ידי פתרון חידות, הפעלת
                            מיקרופון, יצירה וצילום עבודות
                        </div>
                    </div>
                    <div>
                        <img src={Night3} alt="night3" />
                        <div className="text-base mx-auto">
                            גישה לכל החיים <br></br>מתאים לנייד, לטאבלט ולמחשב
                        </div>
                    </div>
                    <div>
                        <img src={Night4} alt="night4" />
                        <div className="text-base mx-auto">
                            תיק עבודות - איזור אישי <br></br>בונוס: מגוון חידות ומשחקים נוספים באתר
                        </div>
                    </div>
                </div>
                <div className="mx-auto mt-1 max-w-670">
                    <Video
                        url={vid_url}
                        vid_over_cb={() => null}
                        img={videoImage}
                        play={play}
                        setPlay={setPlay}
                        controls={true}
                        log_click={'landing_heb_vid'}
                    />
                </div>

                <div className="flex-col items-center justify-center mt-1">
                    <div className="flex items-center justify-center">
                        <div className="flex text-start">
                            <ul className="text-white ul-ok">
                                <li>הכרה וזיהוי סדר הא׳ ב׳ בדפוס ובעל פה</li>
                                <li> פיתוח היכולת לפרק מילה לצלילים</li>
                                <li>
                                    זיהוי צלילים פותחים וסוגרים במילים <br></br>והבנת הקשר ביניהם לבין האותיות הגרפיות
                                </li>
                                <li>היכולת להרכיב מילה מצלילים</li>
                                <li>היכולת לחרוז מילים ולהמציא חרוזים</li>
                                <li>היכולת למנות כמה צלילים יש בכל מילה</li>
                                <li>היכרות עם האותיות הסופיות</li>
                                <li>פיתוח חשיבה יצירתית</li>
                                <li>פיתוח המוטוריקה העדינה</li>
                                <li>הכרת כיווני הכתיבה</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <button
                    className="bt-primary green"
                    onClick={() => {
                        scroll_to_element('SecSell');
                        log_event('landing_payment_modal3');
                    }}
                >
                    {primBtnText}
                </button>
            </div>
        </section>
    );
}

export default SecNightHeb;
