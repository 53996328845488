import axios, { AxiosResponse } from 'axios';
import { CourseKey, IAddress, IAppState, IClass, IImage, IMoreTask, IUserState, PaymentItem } from '../../wrapper';
import { base_url, get_device_semi_id } from './common';
import { more_tasks_metadata } from 'src/metadata';

export type IAddDeviceResponse = 'success' | 'fail' | 'error';

export const addDevice = async (email: string, onFinish: (res: IAddDeviceResponse) => void) => {
    try {
        const response = (await axios.post(base_url + 'addDevice', {
            email,
            device: get_device_semi_id(),
            headers: {
                Accept: 'application/json',
            },
        })) as AxiosResponse;
        const client_resp = response.data.res ? 'success' : 'fail';
        onFinish(client_resp);
    } catch (error) {
        onFinish('error');
    }
};

export type ILoginEmailPassResponse = 'success' | 'not_exist' | 'wrong_password' | 'error';

export const loginEmailPassword = async (
    email: string,
    password: string,
    onFinish: (res: ILoginEmailPassResponse) => void,
) => {
    try {
        const response = (await axios.post(base_url + 'loginEmailPassword', {
            email,
            password,
            headers: {
                Accept: 'application/json',
            },
        })) as AxiosResponse;
        onFinish(response.data.res);
    } catch (error) {
        onFinish('error');
    }
};

export type IEmailPassResetResponse = 'success' | 'not_exist' | 'error';

export const EmailPasswordReset = async (email: string, onFinish: (res: IEmailPassResetResponse) => void) => {
    try {
        const response = (await axios.post(base_url + 'EmailPasswordReset', {
            email,
            headers: {
                Accept: 'application/json',
            },
        })) as AxiosResponse;
        onFinish(response.data.res);
    } catch (error) {
        onFinish('error');
    }
};

export const user2state = (user: IUserState, state: IAppState): Partial<IAppState> => {
    const courses = { ...user.courses };

    for (const course_key in courses) {
        const course = user.courses[course_key];

        // this support - looping over state; adding more tasks on client side. no need to add on server side
        const more_tasks: IMoreTask[] = [];

        for (let i = 0; i < more_tasks_metadata[course_key].length; i++) {
            const new_task: IMoreTask = {
                locked: true,
                progress: 0,
            };
            more_tasks.push(new_task);
        }
        more_tasks.forEach((mt, idx) => {
            if (idx < course.more_tasks.length) mt.progress = course.more_tasks[idx].progress ?? 0;
            else mt.progress = 0;
        });
        course.more_tasks = more_tasks;
    }

    let address = { ...state.address };
    if (user.address) address = user.address;

    return {
        courses: courses,
        subscribed: Object.keys(user.courses).length > 0,
        address: address,
    };
};

export const uploadClasses = async (
    token: string,
    classes: IClass[],
    course: CourseKey,
    handle_no_token_err: (error: any) => void,
) => {
    try {
        await axios.post(base_url + 'uploadClasses', {
            token,
            classes,
            course,
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error: any) {
        handle_no_token_err(error);
    }
};

export const uploadClassesAndImg = async (
    token: string,
    classes: IClass[],
    img: IImage,
    course: CourseKey,
    handle_no_token_err: (error: any) => void,
) => {
    try {
        await axios.post(base_url + 'uploadClassesAndImg', {
            token,
            classes,
            img,
            course,
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        handle_no_token_err(error);
    }
};

export const uploadMoreTasksAndImg = async (
    token: string,
    moretasks: IMoreTask[],
    img: IImage,
    course: CourseKey,
    handle_no_token_err: (error: any) => void,
) => {
    try {
        await axios.post(base_url + 'uploadMoreTasksAndImg', {
            token,
            more_tasks: moretasks,
            img,
            course,
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        handle_no_token_err(error);
    }
};

export const uploadValue = async (
    token: string,
    value_name: string,
    value: number,
    course: CourseKey,
    handle_no_token_err: (error: any) => void,
) => {
    try {
        await axios.post(base_url + 'uploadSimpleValue', {
            token,
            value_name,
            value,
            course,
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        handle_no_token_err(error);
    }
};

export const uploadAddress = async (
    token: string,
    address: IAddress,
    course: CourseKey,
    onFinish: () => void,
    handle_no_token_err: (error: any) => void,
) => {
    try {
        await axios.post(base_url + 'uploadaAddress', {
            token,
            address,
            course,
            headers: {
                Accept: 'application/json',
            },
        });
        onFinish();
    } catch (error) {
        handle_no_token_err(error);
    }
};

export const deleteFolderImg = async (token: string, img_id: string, handle_no_token_err: (error: any) => void) => {
    try {
        await axios.post(base_url + 'deleteFolderImg', {
            token,
            img_id,
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        handle_no_token_err(error);
    }
};

export const downloadFolderImg = async (
    token: string,
    img_id: string,
    onFinish: (img: string) => void,
    handle_no_token_err: (error: any) => void,
) => {
    try {
        const response = (await axios.post(base_url + 'downloadFolderImg', {
            token,
            img_id,
            headers: {
                Accept: 'application/json',
            },
        })) as AxiosResponse;
        onFinish(response.data.img);
    } catch (error) {
        handle_no_token_err(error);
        onFinish('');
    }
};

export const getUserState = async (token: string, onFinish: (user_data: IUserState | undefined) => void) => {
    try {
        const response = (await axios.request({
            data: { token },
            url: base_url + 'getUserState',
            method: 'POST',
        })) as AxiosResponse;
        onFinish(response.data);
    } catch (error) {
        onFinish(undefined);
    }
};

export const getUserToken = async (email: string, onFinish: (token: string | undefined) => void) => {
    try {
        const response = (await axios.request({
            data: { email },
            url: base_url + 'getUserToken',
            method: 'POST',
        })) as AxiosResponse;
        onFinish(response.data.token);
    } catch (error: any) {
        onFinish(undefined);
    }
};

export const getPaymentURL = async (email: string, item: PaymentItem, onFinish: (url?: string) => void) => {
    try {
        const response = (await axios.request({
            data: { email, item },
            url: base_url + 'getPaymentURL',
            method: 'POST',
        })) as AxiosResponse;
        onFinish(response.data.url);
    } catch (error) {
        onFinish(undefined);
    }
};

export const uploadLead = async (name: string, email: string, phone: string, type: string) => {
    try {
        await axios.post(base_url + 'uploadLead', {
            name,
            email,
            phone,
            type,
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        console.error(error);
    }
};

export const logErrorinServer = async (log: string, id: string) => {
    try {
        await axios.post(base_url + 'clientErrorLog', {
            log,
            id,
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        console.error(error);
    }
};
