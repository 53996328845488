import { base_storage_path } from 'src/metadata_heb1';

const image_path = base_storage_path + '/game_word_drag';

interface IWordDragLetter {
    letter: string;
    cell: number;
}

interface WordDragGameConfig {
    letters: IWordDragLetter[];
    image: any;
}

interface WordDragGameDict {
    [key: string]: WordDragGameConfig;
}

export function useWordDragConfig() {
    const computer: IWordDragLetter[] = [
        { letter: 'מ', cell: 14 },
        { letter: 'ח', cell: 19 },
        { letter: 'ש', cell: 15 },
        { letter: 'ב', cell: 13 },
        { letter: 'א', cell: 20 },
        { letter: 'ג', cell: 21 },
        { letter: 'ק', cell: 16 },
        { letter: 'ל', cell: 22 },
    ];

    const lion: IWordDragLetter[] = [
        { letter: 'א', cell: 21 },
        { letter: 'ר', cell: 19 },
        { letter: 'י', cell: 15 },
        { letter: 'ה', cell: 13 },
        { letter: 'ש', cell: 20 },
        { letter: 'ד', cell: 14 },
        { letter: 'ל', cell: 16 },
        { letter: 'ח', cell: 22 },
    ];

    const rubbit: IWordDragLetter[] = [
        { letter: 'א', cell: 22 },
        { letter: 'ר', cell: 19 },
        { letter: 'נ', cell: 15 },
        { letter: 'ב', cell: 13 },
        { letter: 'ת', cell: 20 },
        { letter: 'ז', cell: 21 },
        { letter: 'ח', cell: 16 },
        { letter: 'י', cell: 14 },
    ];

    const fork: IWordDragLetter[] = [
        { letter: 'מ', cell: 15 },
        { letter: 'ז', cell: 19 },
        { letter: 'ל', cell: 14 },
        { letter: 'ג', cell: 13 },
        { letter: 'א', cell: 21 },
        { letter: 'ש', cell: 20 },
        { letter: 'ך', cell: 16 },
        { letter: 'ס', cell: 22 },
    ];

    const orange: IWordDragLetter[] = [
        { letter: 'ת', cell: 14 },
        { letter: 'פ', cell: 19 },
        { letter: 'ו', cell: 13 },
        { letter: 'ז', cell: 16 },
        { letter: 'ב', cell: 20 },
        { letter: 'נ', cell: 21 },
        { letter: 'ח', cell: 15 },
        { letter: 'ם', cell: 22 },
    ];

    const mouse: IWordDragLetter[] = [
        { letter: 'ע', cell: 14 },
        { letter: 'כ', cell: 16 },
        { letter: 'ב', cell: 13 },
        { letter: 'ר', cell: 19 },
        { letter: 'צ', cell: 20 },
        { letter: 'מ', cell: 21 },
        { letter: 'י', cell: 15 },
        { letter: 'ג', cell: 22 },
    ];

    const plane: IWordDragLetter[] = [
        { letter: 'מ', cell: 16 },
        { letter: 'ט', cell: 14 },
        { letter: 'ו', cell: 19 },
        { letter: 'ס', cell: 13 },
        { letter: 'ר', cell: 20 },
        { letter: 'כ', cell: 21 },
        { letter: 'ל', cell: 15 },
        { letter: 'ב', cell: 22 },
    ];

    const plate: IWordDragLetter[] = [
        { letter: 'צ', cell: 14 },
        { letter: 'ל', cell: 16 },
        { letter: 'ח', cell: 19 },
        { letter: 'ת', cell: 13 },
        { letter: 'ד', cell: 20 },
        { letter: 'פ', cell: 21 },
        { letter: 'א', cell: 15 },
        { letter: 'ט', cell: 22 },
    ];

    const train: IWordDragLetter[] = [
        { letter: 'ר', cell: 21 },
        { letter: 'כ', cell: 22 },
        { letter: 'ב', cell: 19 },
        { letter: 'ת', cell: 20 },
        { letter: 'נ', cell: 13 },
        { letter: 'א', cell: 14 },
        { letter: 'ח', cell: 15 },
        { letter: 'ל', cell: 16 },
    ];

    const pillow: IWordDragLetter[] = [
        { letter: 'כ', cell: 22 },
        { letter: 'ר', cell: 16 },
        { letter: 'י', cell: 19 },
        { letter: 'ת', cell: 13 },
        { letter: 'ס', cell: 20 },
        { letter: 'ל', cell: 21 },
        { letter: 'מ', cell: 15 },
        { letter: 'ש', cell: 14 },
    ];

    const tire: IWordDragLetter[] = [
        { letter: 'צ', cell: 22 },
        { letter: 'מ', cell: 14 },
        { letter: 'י', cell: 19 },
        { letter: 'ג', cell: 21 },
        { letter: 'ז', cell: 20 },
        { letter: 'ל', cell: 13 },
        { letter: 'ם', cell: 15 },
        { letter: 'ס', cell: 16 },
    ];

    const pouch: IWordDragLetter[] = [
        { letter: 'ק', cell: 21 },
        { letter: 'ל', cell: 15 },
        { letter: 'מ', cell: 16 },
        { letter: 'ר', cell: 22 },
        { letter: 'א', cell: 20 },
        { letter: 'צ', cell: 13 },
        { letter: 'ג', cell: 14 },
        { letter: 'ת', cell: 19 },
    ];

    const choper: IWordDragLetter[] = [
        { letter: 'מ', cell: 14 },
        { letter: 'ס', cell: 16 },
        { letter: 'ו', cell: 15 },
        { letter: 'ק', cell: 13 },
        { letter: 'פ', cell: 20 },
        { letter: 'צ', cell: 21 },
        { letter: 'ג', cell: 22 },
        { letter: 'ת', cell: 19 },
    ];

    const magnet: IWordDragLetter[] = [
        { letter: 'מ', cell: 19 },
        { letter: 'ג', cell: 14 },
        { letter: 'נ', cell: 13 },
        { letter: 'ט', cell: 15 },
        { letter: 'א', cell: 20 },
        { letter: 'צ', cell: 21 },
        { letter: 'ש', cell: 22 },
        { letter: 'ח', cell: 16 },
    ];

    const chair: IWordDragLetter[] = [
        { letter: 'כ', cell: 20 },
        { letter: 'י', cell: 14 },
        { letter: 'ס', cell: 13 },
        { letter: 'א', cell: 15 },
        { letter: 'ר', cell: 16 },
        { letter: 'צ', cell: 21 },
        { letter: 'ש', cell: 22 },
        { letter: 'ב', cell: 19 },
    ];

    const trash: IWordDragLetter[] = [
        { letter: 'א', cell: 15 },
        { letter: 'ש', cell: 14 },
        { letter: 'פ', cell: 21 },
        { letter: 'ה', cell: 20 },
        { letter: 'ז', cell: 16 },
        { letter: 'צ', cell: 13 },
        { letter: 'ג', cell: 22 },
        { letter: 'ל', cell: 19 },
    ];

    const sandal: IWordDragLetter[] = [
        { letter: 'ס', cell: 20 },
        { letter: 'נ', cell: 15 },
        { letter: 'ד', cell: 13 },
        { letter: 'ל', cell: 14 },
        { letter: 'א', cell: 21 },
        { letter: 'י', cell: 16 },
        { letter: 'ג', cell: 22 },
        { letter: 'ב', cell: 19 },
    ];

    const fox: IWordDragLetter[] = [
        { letter: 'ש', cell: 15 },
        { letter: 'ו', cell: 20 },
        { letter: 'ע', cell: 16 },
        { letter: 'ל', cell: 14 },
        { letter: 'ב', cell: 21 },
        { letter: 'ס', cell: 13 },
        { letter: 'ג', cell: 22 },
        { letter: 'ר', cell: 19 },
    ];

    const pig: IWordDragLetter[] = [
        { letter: 'ח', cell: 21 },
        { letter: 'ז', cell: 14 },
        { letter: 'י', cell: 19 },
        { letter: 'ר', cell: 15 },
        { letter: 'פ', cell: 20 },
        { letter: 'א', cell: 16 },
        { letter: 'ג', cell: 22 },
        { letter: 'ס', cell: 13 },
    ];

    const donkey: IWordDragLetter[] = [
        { letter: 'ח', cell: 19 },
        { letter: 'מ', cell: 14 },
        { letter: 'ו', cell: 13 },
        { letter: 'ר', cell: 15 },
        { letter: 'ס', cell: 20 },
        { letter: 'צ', cell: 21 },
        { letter: 'ש', cell: 22 },
        { letter: 'ב', cell: 16 },
    ];

    const eggplant: IWordDragLetter[] = [
        { letter: 'ח', cell: 16 },
        { letter: 'צ', cell: 14 },
        { letter: 'י', cell: 13 },
        { letter: 'ל', cell: 15 },
        { letter: 'ר', cell: 20 },
        { letter: 'ת', cell: 21 },
        { letter: 'ש', cell: 22 },
        { letter: 'ב', cell: 19 },
    ];

    const ball: IWordDragLetter[] = [
        { letter: 'כ', cell: 15 },
        { letter: 'ד', cell: 14 },
        { letter: 'ו', cell: 13 },
        { letter: 'ר', cell: 16 },
        { letter: 'א', cell: 20 },
        { letter: 'צ', cell: 21 },
        { letter: 'ג', cell: 22 },
        { letter: 'ל', cell: 19 },
    ];

    const cat: IWordDragLetter[] = [
        { letter: 'ח', cell: 21 },
        { letter: 'ת', cell: 14 },
        { letter: 'ו', cell: 19 },
        { letter: 'ל', cell: 15 },
        { letter: 'פ', cell: 20 },
        { letter: 'צ', cell: 16 },
        { letter: 'ג', cell: 22 },
        { letter: 'ר', cell: 13 },
    ];

    const ring: IWordDragLetter[] = [
        { letter: 'ט', cell: 20 },
        { letter: 'ב', cell: 15 },
        { letter: 'ע', cell: 13 },
        { letter: 'ת', cell: 14 },
        { letter: 'י', cell: 21 },
        { letter: 'צ', cell: 16 },
        { letter: 'ג', cell: 22 },
        { letter: 'ק', cell: 19 },
    ];

    const gift: IWordDragLetter[] = [
        { letter: 'מ', cell: 15 },
        { letter: 'ת', cell: 20 },
        { letter: 'נ', cell: 16 },
        { letter: 'ה', cell: 14 },
        { letter: 'ב', cell: 21 },
        { letter: 'ס', cell: 13 },
        { letter: 'ג', cell: 22 },
        { letter: 'ר', cell: 19 },
    ];

    const key: IWordDragLetter[] = [
        { letter: 'מ', cell: 20 },
        { letter: 'פ', cell: 15 },
        { letter: 'ת', cell: 13 },
        { letter: 'ח', cell: 14 },
        { letter: 'א', cell: 21 },
        { letter: 'י', cell: 16 },
        { letter: 'ג', cell: 22 },
        { letter: 'ז', cell: 19 },
    ];

    const cart: IWordDragLetter[] = [
        { letter: 'ע', cell: 15 },
        { letter: 'ג', cell: 20 },
        { letter: 'ל', cell: 16 },
        { letter: 'ה', cell: 14 },
        { letter: 'ב', cell: 21 },
        { letter: 'ס', cell: 13 },
        { letter: 'ח', cell: 22 },
        { letter: 'ר', cell: 19 },
    ];

    const games: WordDragGameDict = {
        מחשב: { letters: computer, image: `${image_path}/computer.webp` },
        אריה: { letters: lion, image: `${image_path}/lion.webp` },
        מזלג: { letters: fork, image: `${image_path}/fork.webp` },
        תפוז: { letters: orange, image: `${image_path}/orange.webp` },
        ארנב: { letters: rubbit, image: `${image_path}/rubbit.webp` },
        עכבר: { letters: mouse, image: `${image_path}/mouse-min.webp` },
        מטוס: { letters: plane, image: `${image_path}/plane-min.webp` },
        צלחת: { letters: plate, image: `${image_path}/plate-min.webp` },
        רכבת: { letters: train, image: `${image_path}/train-min.webp` },

        כרית: { letters: pillow, image: `${image_path}/pillow-min.webp` },
        צמיג: { letters: tire, image: `${image_path}/tire-min.webp` },
        קלמר: { letters: pouch, image: `${image_path}/pouch-min.webp` },
        מסוק: { letters: choper, image: `${image_path}/choper-min.webp` },
        מגנט: { letters: magnet, image: `${image_path}/magnet-min.webp` },
        כיסא: { letters: chair, image: `${image_path}/chair-min.webp` },
        אשפה: { letters: trash, image: `${image_path}/trash-min.webp` },
        חתול: { letters: cat, image: `${image_path}/cat-min.webp` },
        סנדל: { letters: sandal, image: `${image_path}/sandal-min.webp` },

        שועל: { letters: fox, image: `${image_path}/fox-min.webp` },
        חזיר: { letters: pig, image: `${image_path}/pig-min.webp` },
        חמור: { letters: donkey, image: `${image_path}/donkey-min.webp` },
        חציל: { letters: eggplant, image: `${image_path}/eggplant-min.webp` },
        כדור: { letters: ball, image: `${image_path}/ball-min.webp` },
        טבעת: { letters: ring, image: `${image_path}/ring-min.webp` },
        מתנה: { letters: gift, image: `${image_path}/gift-min.webp` },
        מפתח: { letters: key, image: `${image_path}/key-min.webp` },
        עגלה: { letters: cart, image: `${image_path}/cart-min.webp` },
    };
    return { games };
}
