import React from 'react';
import { letterColorType, letterColors } from 'src/common/buttons/colors';
import Letter from 'src/common/buttons/Letter';
import { ILetterId } from 'src/wrapper';
import './Maze.css';

export interface IMazeLetter {
    text: string;
    size: number;
    top: number;
    left: number;
    color: letterColorType;
    type: ILetterId;
}

function MazeLetter(props: IMazeLetter): JSX.Element {
    return (
        <div
            className="maze_letter"
            style={{ top: props.top, left: props.left, width: props.size, height: props.size }}
        >
            <Letter text={props.text} id={props.type} zoom={props.size / 85.3} color={letterColors[props.color]} />
        </div>
    );
}

export default MazeLetter;
