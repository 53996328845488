import React from 'react';
import { letterColorsStyle } from './colors';
import { ILetterId } from 'src/wrapper';
import './Letter.css';

interface ILetter {
    text: string;
    id: ILetterId;
    onClick?: (letter: string) => void;
    zoom: number;
    color: letterColorsStyle;
}

function Letter(props: ILetter): JSX.Element {
    const { shade, bg, txt } = props.color;

    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.text);
        }
    };

    return (
        <button
            className="letter_button"
            onClick={onClick}
            style={{ zoom: props.zoom, cursor: props.onClick ? 'pointer' : 'default' }}
            aria-label={props.text}
        >
            <p id={props.id} className="letter_btn_txt" style={{ color: txt }}>
                {props.text}
            </p>
            <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    className="shade-path"
                    fill={shade}
                    d="M51.0472 26.5189C47.325 43.5347 45.7298 52.0425 25.5236 52.0425C11.4273 52.0425 0 47.2568 0
                26.5189C0 12.4227 0 6.31277 25.5236 0.995357C51.0472 -4.32206 54.0595 12.7483 51.0472 26.5189Z"
                />
                <path
                    className="bg-path"
                    fill={bg}
                    d="M49 25.9359C45.5 41.936 44 49.936 25 49.936C11.7452 49.936 1 45.4359 1 25.9359C1 12.6811 1 6.93594 25 1.93594C49 -3.06406 51.8325 12.9873 49 25.9359Z"
                />
            </svg>
        </button>
    );
}

export default Letter;
