import React from 'react';
import { useMediaQueryWrap } from '../../useMediaQueryWrap';
import ImageList from '@mui/material/ImageList';
import './MainList.css';

export interface IMainList {
    text: React.ReactNode;
    bottomButtons?: React.ReactNode;
    mainList: any;
    cols?: () => number;
}

function MainList(props: IMainList): JSX.Element {
    const { get_grid_list_cols, getMQid, get_grid_list_width } = useMediaQueryWrap();
    const rid = getMQid();
    const cols = props.cols ? props.cols() : get_grid_list_cols();
    const gap = rid === 'tablet' ? 32 : 16;

    return (
        <div className="mlContent">
            <div className={'mlTextWrapper'}>{props.text}</div>
            <div id={'mlGridWrapper'} className={'mlGridWrapper'}>
                <ImageList
                    sx={{ maxWidth: get_grid_list_width(), justifyItems: 'center', overflow: 'hidden' }}
                    cols={cols}
                    rowHeight={'auto'}
                    gap={gap}
                >
                    {props.mainList ?? []}
                </ImageList>
            </div>
            <div id={rid} className={'mlBottomButtons'}>
                {props.bottomButtons}
            </div>
        </div>
    );
}

export default MainList;
