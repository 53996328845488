import React from 'react';
import { ILetterId } from 'src/wrapper';
import './Maze.css';

export interface IMazeLetter {
    text: string;
    size: number;
    top: number;
    left: number;
    isBlock: boolean;
    mazeType: ILetterId;
}

function MazeCell(props: IMazeLetter): JSX.Element {
    let id = props.isBlock ? 'block' : 'tile';
    id += props.mazeType === 'letter' ? '1' : '2';
    return (
        <div
            className="maze_cell"
            id={id}
            style={{
                top: props.top,
                left: props.left,
                width: props.size,
                height: props.size,
            }}
        >
            {/* <p>{props.text}</p> */}
        </div>
    );
}

export default MazeCell;
