import React from 'react';
import MainLayout from '../MainLayout';
import logoImage from 'src/assets/logo/blue.svg';
import MenuImg from 'src/assets/icons/blueMenu.svg';
import { useMediaQueryWrap } from '../../useMediaQueryWrap';
import messages from 'src/assets/messages_he.json';
import { base_storage_path } from 'src/metadata_heb1';
const image_path = base_storage_path + '/first_grade_page_images';

import './TextLayout.css';

function FirstGrade(): JSX.Element {
    const { getMQid } = useMediaQueryWrap();
    const rid = getMQid();

    return (
        <MainLayout can_url="/first-grade" id={'text'} logo={logoImage} appMenuLogo={MenuImg}>
            <div className="tl_wrap">
                <div className="tl_wrap_inside">
                    <h1 id={rid} className="tl_main_header">
                        {messages['ready.header']}
                    </h1>
                    <p id={rid} className="tl_text">
                        {messages['ready.text1']} <br /> {messages['ready.text2']} <br /> {messages['ready.text3']}{' '}
                        <br /> {messages['ready.text4']} <br />
                        {messages['ready.text5']} <br /> <br /> {messages['ready.text6']} <br /> <br />{' '}
                        {messages['ready.text7']}
                    </p>
                    <p id={rid} className="tl_sub_header">
                        {messages['ready.sub1']}
                    </p>
                    <p id={rid} className="tl_text">
                        {messages['ready.text8']} <br /> <br /> {messages['ready.text9']} <br />{' '}
                        {messages['ready.text10']} <br /> <br /> {messages['ready.text11']} <br />{' '}
                        {messages['ready.text12']} <br />{' '}
                    </p>
                    <br></br>
                    <p id={rid} className="tl_main_header">
                        {messages['ready.rudy_sub']}
                    </p>
                    <h2 id={rid} className="tl_sub_header">
                        {messages['ready.sub2']}
                    </h2>
                    <p id={rid} className="tl_text">
                        {messages['ready.text13']}
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            src={`${image_path}/heb/firstgrade5.webp`}
                            alt={messages['ready.sub3']}
                        ></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        {messages['ready.sub3']}
                    </h2>
                    <p id={rid} className="tl_text">
                        {messages['ready.text14']}
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            src={`${image_path}/heb/firstgrade4.webp`}
                            alt={messages['ready.sub4']}
                        ></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        {messages['ready.sub4']}
                    </h2>
                    <p id={rid} className="tl_text">
                        {messages['ready.text15']}
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            src={`${image_path}/heb/firstgrade6.webp`}
                            alt={messages['ready.sub5']}
                        ></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        {messages['ready.sub5']}
                    </h2>
                    <p id={rid} className="tl_text">
                        {messages['ready.text16']}
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            src={`${image_path}/heb/firstgrade2.webp`}
                            alt={messages['ready.sub6']}
                        ></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        {messages['ready.sub6']}
                    </h2>
                    <p id={rid} className="tl_text">
                        {messages['ready.text17']}
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            src={`${image_path}/heb/firstgrade3.webp`}
                            alt={messages['ready.sub7']}
                        ></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        {messages['ready.sub7']}
                    </h2>
                    <p id={rid} className="tl_text">
                        {messages['ready.text18']} <br /> <br /> {messages['ready.text19']}
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            src={`${image_path}/heb/firstgrade1.webp`}
                            alt={messages['ready.sub8']}
                        ></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        {messages['ready.sub8']}
                    </h2>
                    <p id={rid} className="tl_text">
                        {messages['ready.text20']}
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            src={`${image_path}/heb/firstgrade7.webp`}
                            alt={messages['ready.sub9']}
                        ></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        {messages['ready.sub9']}
                    </h2>
                    <p id={rid} className="tl_text">
                        {messages['ready.text21']} <br />
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            src={`${image_path}/heb/firstgrade8.webp`}
                            alt={messages['ready.sub9']}
                        ></img>
                    </div>{' '}
                    <p id={rid} className="tl_text">
                        {messages['ready.text22']} <br /> <br />
                    </p>
                    <br></br>
                    <p id={rid} className="tl_main_header">
                        {messages['ready.billy_sub']}
                    </p>
                    <p id={rid} className="tl_text">
                        כמו בסיפור האותיות של רודי, גם בסיפור המספרים הילדים מתקדמים ומחזקים את המיומנויות החשובות בצורה
                        הדרגתית ומחושבת כאשר כל פעולה שהם מבצעים נשענת על רעיון קודם ומחזקת מיומנות שונה. הפרקים
                        הראשונים של הסיפור עוסקים בתחום העשרת הראשונה, ולאחר מכן את אותן מיומנויות מתרגלים הילדים בתחום
                        העשרת השנייה. כאשר הם מבינים כי החוקיות דומה, הם עוברים לתרגול מיומנויות מתקדמות יותר כמו תרגילי
                        חיבור ושבירת עשרת (מעבר מעשרת ראשונה לשניה על ידי חיבור כמותי).
                        <br /> <br />
                    </p>
                    <h2 id={rid} className="tl_sub_header">
                        זיהוי מספרים בעשרת הראשונה והכרת הסדר
                    </h2>
                    <p id={rid} className="tl_text">
                        הילדים עוזרים לבילי להתאים את שמות המספרים לצורות הוזיאוליות שלהם. בנוסף, ניתן דגש לרעיון שחשוב
                        להעלות כבר מההתחלה: כיוון הכתיבה - משמאל לימין.
                    </p>
                    <h2 id={rid} className="tl_sub_header">
                        תרגול מושגי יסוד בהקשר לסדר המספרים (לפני, אחרי, ראשון, אחרון)
                    </h2>
                    <div className="tl_img_wrap">
                        <img className="tl_img" src={`${image_path}/math/1.webp`} alt="מושגי יסוג"></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        מנייה והתאמת כמות למספר בעשרת הראשונה
                    </h2>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            src={`${image_path}/math/2.webp`}
                            alt="מנייה והתאמת כמות למספר בעשרת הראשונה"
                        ></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        {messages['ready.symbols']}
                    </h2>
                    <p id={rid} className="tl_text">
                        הסימנים הראשונים שחושפים את הילדים לתבנית משוואתית. לאחר הכרת התבנית, לילדים קל יותר לגשת
                        למשוואות כמו תרגילי חיבור וחיסור.
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            src={`${image_path}/math/3.webp`}
                            alt="מנייה והתאמת כמות למספר בעשרת הראשונה"
                        ></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        חיבור בעשרת הראשונה
                    </h2>
                    <div className="tl_img_wrap">
                        <img className="tl_img" src={`${image_path}/math/4.webp`} alt="חיבור"></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        חיסור בעשרת הראשונה
                    </h2>
                    <div className="tl_img_wrap">
                        <img className="tl_img" src={`${image_path}/math/5.webp`} alt="חיסור"></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        מנייה והתאמת כמות למספר בעשרת השנייה
                    </h2>
                    <div className="tl_img_wrap">
                        <img className="tl_img" src={`${image_path}/math/6.webp`} alt="מנייה"></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        חיבור וחיסור בעשרת השנייה
                    </h2>
                    <div className="tl_img_wrap">
                        <img className="tl_img" src={`${image_path}/math/7.webp`} alt="חיבור וחיסור"></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        מספרים זוגיים ואי זוגיים
                    </h2>
                    <div className="tl_img_wrap">
                        <img className="tl_img" src={`${image_path}/math/8.webp`} alt="מספרים זוגיים ואי זוגיים"></img>
                    </div>{' '}
                    <h2 id={rid} className="tl_sub_header">
                        הכרת משפחות מרובעים ומשולשים{' '}
                    </h2>
                    <div className="tl_img_wrap">
                        <img className="tl_img" src={`${image_path}/math/9.webp`} alt="גאומטריה"></img>
                    </div>{' '}
                    <p id={rid} className="tl_sub_header">
                        {messages['ready.sub10']}
                    </p>
                    <p id={rid} className="tl_text">
                        {messages['ready.text24']} <br /> {messages['ready.text25']} <br /> <br />
                        {messages['ready.text26']} <br /> {messages['ready.text27']}{' '}
                    </p>
                </div>
            </div>
        </MainLayout>
    );
}

export default FirstGrade;
