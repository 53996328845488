interface buttonColorsStyle {
    background: string;
    shadow: string;
}

export type buttonColorType =
    | 'green'
    | 'orange'
    | 'orangeAction'
    | 'blueAnswer'
    | 'purple'
    | 'pink'
    | 'yellow'
    | 'blue'
    | 'blueAction'
    | 'red'
    | 'blueFacebook'
    | 'instagram'
    | 'tiktok'
    | 'white';

type buttonColorDict = {
    [key in buttonColorType]: buttonColorsStyle;
};

export const buttonColors: buttonColorDict = {
    green: {
        background: '#6FCF4D',
        shadow: '#4EA62E',
    },
    orange: {
        background: '#F3A96B',
        shadow: '#DB8A45',
    },
    orangeAction: {
        background: '#F76E5B',
        shadow: '#F45640',
    },
    purple: {
        background: '#9DAEFF',
        shadow: '#7990FC',
    },
    pink: {
        background: '#FFB6EF',
        shadow: '#F389DC',
    },
    yellow: {
        background: '#FFF7B2',
        shadow: '#FEDD3A',
    },
    blue: {
        background: '#6CBFF7',
        shadow: '#6BAADA',
    },
    blueAction: {
        background: '#58B4F0',
        shadow: '#58B4F0',
    },
    blueAnswer: {
        shadow: '#8096FB',
        background: '#C8D1FA',
    },
    red: {
        background: '#F76E5B',
        shadow: '#ED4D37',
    },
    white: {
        background: 'white',
        shadow: 'white',
    },
    blueFacebook: {
        background: '#4064B0',
        shadow: '#284C97',
    },
    instagram: {
        background: '#FC8EE4',
        shadow: '#DB6FC3',
    },
    tiktok: {
        background: '#2F2B3C',
        shadow: '#000000',
    },
};

export interface letterColorsStyle {
    shade: string;
    bg: string;
    txt: string;
}

export type letterColorType = 'greenNum' | 'blue' | 'gray' | 'green' | 'orange' | 'red';

type letterColorDict = {
    [key in letterColorType]: letterColorsStyle;
};

export const letterColors: letterColorDict = {
    greenNum: {
        shade: '#52AB32',
        bg: '#6FCF4D',
        txt: 'white',
    },
    blue: {
        shade: '#8096FB',
        bg: '#C8D1FA',
        txt: '#8096FB',
    },
    green: {
        shade: '#92D27C',
        bg: '#BFF0AE',
        txt: '#6FCF4D',
    },
    gray: {
        shade: '#E8EBF4',
        bg: 'white',
        txt: '#D2DBE2',
    },
    orange: {
        shade: '#FE9741',
        bg: '#FFE9CB',
        txt: '#F2984C',
    },
    red: {
        bg: '#F76E5B',
        shade: '#ED4D37',
        txt: 'white',
    },
};
