import React from 'react';
import MainLayout from '../MainLayout';
import logoImage from '../../../assets/logo/blue.svg';
import MenuImg from '../../../assets/icons/blueMenu.svg';
import { useMediaQueryWrap } from '../../useMediaQueryWrap';
import messages from '../../../assets/messages_he.json';
import CircleButton from 'src/common/buttons/CircleButton';
import facebookSvg from 'src/assets/icons/facebook.svg';
import instagramSvg from 'src/assets/icons/instagram.svg';
import tikTokSvg from 'src/assets/icons/tiktok.svg';
import { openRoyFacebook, openRoyInstagram, openRoyTikTok } from 'src/common/common';
import { base_storage_path } from 'src/metadata_heb1';
import './TextLayout.css';

const base_assests_url = base_storage_path + '/about_roy';

function AboutRoy(): JSX.Element {
    const { getMQid } = useMediaQueryWrap();
    const rid = getMQid();

    return (
        <MainLayout can_url="/about-roy" id={'text'} logo={logoImage} appMenuLogo={MenuImg}>
            <div className="tl_wrap">
                <div className="tl_wrap_inside">
                    <h1 id={rid} className="tl_main_header">
                        {messages['roy.header']}
                    </h1>

                    <p id={rid} className="tl_text">
                        {messages['roy.text1']} <br /> <br /> {messages['roy.text2']}
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            style={{ maxWidth: '95%' }}
                            src={`${base_assests_url}/secret_life.jpg`}
                            alt="החיים הסודיים"
                        ></img>
                    </div>
                    <p id={rid} className="tl_text">
                        {messages['roy.text3']} <br /> {messages['roy.text4']}
                    </p>
                    <div className="tl_img_wrap">
                        <img
                            className="tl_img"
                            style={{ maxWidth: '50%' }}
                            src={`${base_assests_url}/ynet_ab.jpg`}
                            alt="כתבה א ב"
                        ></img>
                    </div>
                    <button
                        id={rid}
                        className="tl_link"
                        style={{ marginBottom: '1rem', textAlign: 'center' }}
                        onClick={() => window.open('https://www.ynet.co.il/articles/0,7340,L-5065510,00.html')}
                        aria-label={messages['roy.link1']}
                    >
                        {messages['roy.link1']}
                    </button>
                    <p id={rid} className="tl_text">
                        {messages['roy.text5']}
                    </p>
                    <div className="tl_img_wrap">
                        <img className="tl_img" src={`${base_assests_url}/gan.jpg`} alt="גן ילדים"></img>
                    </div>
                    <p id={rid} className="tl_text">
                        {messages['roy.text6']}
                    </p>
                    <div className="tl_img_wrap">
                        <video className="tl_img" controls src={`${base_assests_url}/cubes.mp4`}></video>
                    </div>
                    <p id={rid} className="tl_text">
                        {messages['roy.text7_1']} {messages['roy.text7_2']}
                    </p>
                    <p id={rid} className="tl_text">
                        {messages['roy.text8']} <br /> <br /> {messages['roy.text9']}
                    </p>
                    <p id={rid} className="tl_text">
                        <br />
                        {messages['roy.text10']}
                    </p>
                    <div className="tl_img_wrap">
                        <video
                            className="tl_img"
                            controls
                            poster={`${base_assests_url}/highfive.jpeg`}
                            src={`${base_assests_url}/highfive.mp4`}
                        ></video>
                    </div>
                    <button
                        id={rid}
                        style={{ marginBottom: '1rem', textAlign: 'center' }}
                        className="tl_link"
                        onClick={() =>
                            window.open('https://www.mako.co.il/nexter-internet/viral/Article-41f6fb6bfdd9a51006.htm')
                        }
                        aria-label={messages['roy.link5']}
                    >
                        {messages['roy.link5']}
                    </button>
                    <p id={rid} className="tl_sub_header">
                        {messages['roy.text11']}
                    </p>
                    <div className="tl_img_wrap">
                        <img className="tl_img" src={`${base_assests_url}/ynet_tours.jpg`} alt="טורים בווינט"></img>
                    </div>
                    <button
                        id={rid}
                        className="tl_link"
                        onClick={() => window.open('https://www.ynet.co.il/article/5071859')}
                        aria-label={messages['roy.link2']}
                    >
                        {messages['roy.link2']}
                    </button>
                    <button
                        id={rid}
                        className="tl_link"
                        onClick={() => window.open('https://www.ynet.co.il/articles/0,7340,L-5274748,00.html')}
                        aria-label={messages['roy.link3']}
                    >
                        {messages['roy.link3']}
                    </button>
                    <button
                        id={rid}
                        className="tl_link"
                        onClick={() => window.open('https://www.ynet.co.il/articles/0,7340,L-5119660,00.html')}
                        aria-label={messages['roy.link4']}
                    >
                        {messages['roy.link4']}
                    </button>
                    <div className="tl_icons_horiz_wrap">
                        <CircleButton
                            svg={facebookSvg}
                            color={'blueFacebook'}
                            onClick={openRoyFacebook}
                            ariaLabel={'פייסבוק'}
                        />
                        <CircleButton
                            svg={instagramSvg}
                            color={'instagram'}
                            onClick={openRoyInstagram}
                            ariaLabel={'אינסטגרם'}
                        />
                        <CircleButton svg={tikTokSvg} color={'tiktok'} onClick={openRoyTikTok} ariaLabel={'טיקטוק'} />
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default AboutRoy;
