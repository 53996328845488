import React from 'react';
import './PaintMaze.css';

export interface IPaintMazeCell {
    text: string;
    size: number;
    index: number;
    top: number;
    left: number;
    color?: string;
    on_click?: (index: number) => void;
    isCorrect: boolean;
}

function PaintMazeCell(props: IPaintMazeCell): JSX.Element {
    const on_cell_click = () => {
        if (props.isCorrect) return;
        if (props.on_click) props.on_click(props.index);
    };

    const bg_color = () => {
        if (props.isCorrect) return props.color;
        if (props.text == '') return '';
        return ''; //'#E0DBC0';
    };

    const border_color = () => {
        if (props.text == '') return '';
        if (props.isCorrect) return '';
        return '2px solid white';
    };

    return (
        <div
            className="paint_maze_cell"
            style={{
                top: props.top,
                left: props.left,
                width: props.size,
                height: props.size,
                backgroundColor: bg_color(),
                border: border_color(),
            }}
            onClick={on_cell_click}
        >
            <p className="paint_maze_riddle">{props.text}</p>
        </div>
    );
}

export default PaintMazeCell;
