import React, { useContext } from 'react';
import timeImage from '../../../assets/icons/time.svg';
import { ReactComponent as Penicon } from '../../../assets/icons/pen.svg';
import playSvg from '../../../assets/icons/play.svg';
import lockSvg from '../../../assets/icons/Lock.svg';
import { LinearProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CircleButton from '../../buttons/CircleButton';
import CircularProgress from '@mui/material/CircularProgress';
import { AppContext } from '../../../AppContext';
import './MainListItem.css';

interface IMainListItem {
    title: string;
    locked: boolean;
    main_img: string;
    materials?: string[];
    time_len?: string;
    progress?: number;
    small_text?: string;
    onClick: () => void;
    onLoadImgCB?: () => void;
}

const theme = createTheme({
    direction: 'rtl',
});

function MainListItem(props: IMainListItem): JSX.Element {
    const [loaded, setLoaded] = React.useState(false);
    const { state } = useContext(AppContext);

    const on_load = () => {
        setLoaded(true);
        if (props.onLoadImgCB) props.onLoadImgCB();
    };

    const render_overlay_state = () => {
        if (!props.locked)
            return <CircleButton svg={playSvg} color={'green'} onClick={props.onClick} ariaLabel="צפייה" />;

        if (!(state.course_key in state.courses)) {
            return <img className="lockImg" src={lockSvg} onClick={props.onClick} alt="תוכן נעול"></img>;
        }

        // locked + subscribed
        return (
            <CircleButton svg={playSvg} color={'green'} onClick={() => null} disabled={true} ariaLabel="תוכן נעול" />
        );
    };

    const render_materials = () => {
        if (!props.materials) return null;
        return props.materials.map((material, index) => {
            return (
                <div key={index} className={'mliTextDotWrap'}>
                    <p className={'mliSubText'}>{material} </p>
                    {index < props.materials!.length - 1 ? <p className={'mliDot'}>&#x2022;</p> : <></>}
                </div>
            );
        });
    };

    return (
        <>
            <div style={{ display: !loaded ? 'block' : 'none' }}>
                <CircularProgress style={{ width: 100, height: 100, color: '#0761A6' }} />
            </div>
            <div className={'mliItem'} style={{ display: !loaded ? 'none' : 'block' }}>
                <div className={'mliImgWrapper'}>
                    <img
                        src={props.main_img}
                        className={'mliImg'}
                        alt={props.title}
                        onLoad={() => on_load()}
                        style={{ filter: props.locked ? 'brightness(50%)' : 'brightness(100%)' }}
                    />
                    <div className={'mliBtnWrapper'}>{render_overlay_state()}</div>
                    {props.time_len && (
                        <div className="mliTimeTextWrapper">
                            <img src={timeImage} className={'mliTimeImg'} alt="timer icon" />
                            <p className="mliTimeText">{props.time_len} דקות </p>
                        </div>
                    )}
                </div>
                {props.progress !== undefined ? (
                    <ThemeProvider theme={theme}>
                        <LinearProgress
                            sx={{
                                backgroundColor: '#e2e8ed',
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: '#F76E5B',
                                },
                            }}
                            variant="determinate"
                            value={props.progress}
                        />
                    </ThemeProvider>
                ) : null}
                <div className={'mliTextWrapper'}>
                    <p className={'mliTextHeader'}>{props.title}</p>
                    {props.materials ? (
                        <>
                            <div className={'mliSubTextWrapper'}>
                                <Penicon className={'mliPenIcon'} />
                                {render_materials()}
                            </div>
                        </>
                    ) : (
                        <>
                            <p className={'mliSubText'} style={{ margin: '0.25em' }}>
                                {props.small_text}
                            </p>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default MainListItem;
