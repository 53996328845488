import React from 'react';
import turnImg from '../../assets/images/turn_device.svg';
import messages from '../../assets/messages_he.json';
import './Turnscreen.css';

function Turnscreen(): JSX.Element {
    return (
        <>
            <div className="turnScreenWrapper">
                <p className="turnScreenText">{messages['turnscreen']}</p>
                <img src={turnImg} alt="סובב את המסך"></img>
            </div>
        </>
    );
}

export default Turnscreen;
