import React from 'react';
import MainLayout from '../MainLayout';
import logoImage from '../../../assets/logo/blue.svg';
import MenuImg from '../../../assets/icons/blueMenu.svg';
import './TextLayout.css';

function Terms(): JSX.Element {
    return (
        <MainLayout can_url="/terms" id={'text'} logo={logoImage} appMenuLogo={MenuImg}>
            <div className="tl_wrap" style={{ padding: '0em 1em' }}>
                <div className="tl_wrap_inside">
                    <p dir="RTL">
                        <strong>
                            <u>&shy;&shy;תנאי שימוש באתר דמיון בקופסא&nbsp;</u>
                        </strong>
                    </p>
                    <p dir="RTL">
                        <strong>
                            <u>מבוא</u>
                        </strong>
                    </p>
                    <ol>
                        <li>
                            ברוכים הבאים לאתר דמיון בקופסא (להלן: &quot;<strong>האתר</strong>&quot;) אשר מציע קורס
                            דיגיטלי אינטראקטיבי להכנה לכיתה א&apos;.&nbsp;
                        </li>
                        <li>המשתמשים מתבקשים לקרוא בעיון תנאי שימוש אלו על כל סעיפיהם טרם הגלישה באתר.&nbsp;</li>
                        <li>הגלישה באתר מבטאת הסכמה בלתי מסויגת להוראות תנאי השימוש.&nbsp;</li>
                        <li>
                            הסכמה זו היא תנאי מקדים לשימוש באתר ו/או בקורס בהם ניתן להשתתף במסגרת השימוש באתר (להלן:
                            &quot;
                            <strong>הקורסים</strong>&quot;) ולא תשמע כל טענה בעניין זה.&nbsp;
                        </li>
                        <li>
                            אם אינך מסכים לאיזה מהתנאים המפורטים להלן, עליך להימנע מהרשמה ו/או התחברות לשירותי
                            האתר.&nbsp;
                        </li>
                        <li>
                            תקנון זה יחול גם על תכנים שיפורסמו ע&quot;י האתר באתרים אחרים במרשתת או בדוא&quot;ל ובפרט
                            ברשתות חברתיות כדוגמת פייסבוק ואינסטגרם, בשינויים המחויבים.&nbsp;
                        </li>
                        <li>
                            התקנון עשוי להשתנות מזמן לזמן על פי שיקול הדעת הבלעדי של צוות האתר. על המשתמש לוודא את תנאי
                            התקנון העדכניים בכל עת כפי שיפורסמו באתר. תקנון זה יכנס לתוקף מיד עם פרסומו באתר.
                        </li>
                        <li>
                            צוות האתר רשאי להפסיק את פעילות האתר בכל עת וללא הודעה מראש באופן זמני או לצמיתות. המשתמש
                            מוותר מראש על כל טענה או תביעה בדבר הפסקת פעילות האתר. הסעיפים הנוגעים לקניין רוחני, להגבלת
                            אחריות וחבות ולשיפוי ופיצוי יעמדו בתוקפם גם לאחר סגירת האתר או פקיעת תוקף תקנון זה ככל שלא
                            יבוא אחר במקומו.&nbsp;
                        </li>
                        <li>
                            מובהר כי השימוש בלשון זכר במסמך זה נעשה לצרכי נוחות בלבד וכל פנייה במסמך זה מתייחסת לכל
                            המינים ו/או המגדרים ללא יוצא מן הכלל.&nbsp;
                        </li>
                    </ol>
                    <p dir="RTL">
                        <strong>
                            <u>כללי התנהגות באתר</u>
                        </strong>
                    </p>
                    <ol>
                        <li>משתמש המעלה חומר, מידע או תגובה מכל סוג שהיא לאתר נושא באחריות מלאה למעשיו.</li>
                        <li>
                            מבלי לגרוע מכלליות האמור, חל איסור מוחלט להעלות תכנים הפוגעים בשמם הטוב של אחרים; חומרים
                            המטרידים או מאיימים על אחרים; חומרים העוסקים בפעילות בלתי-חוקית; חומרים המפרים את קניינו
                            הרוחני של אחר; חומרים מבזים, פורנוגרפיים, בוטים, לא מכבדים או בלתי חוקיים; חומרים הכוללים
                            דברי פרסומת או שידול מסחרי; חומרים הקשורים לפעילות פוליטית מפלגתית וכל חומר הכולל, במכוון,
                            מידע מטעה או בלתי מדויק המועלה בכוונה להטעות אחרים.
                        </li>
                        <li>
                            חל על המשתמש איסור מוחלט לעשות שימוש באתר ובשירותים הניתנים בו לרעה, לרבות בדרך של שיבוש או
                            הכבדה על השירותים הניתנים באתר, יצירת גישה אליהם מרחוק, בכל אמצעי שהוא, העתקה של חומרים
                            מהאתר באמצעים טכנולוגיים או אחרים וכיו&quot;ב. כמו כן, חל איסור על הפרעה לשימוש או הנאה של
                            משתמש אחר באתר או על כניסה בלתי מורשית לאתר או לחשבונות משתמשים אחרים בו.
                        </li>
                        <li>חל איסור לעשות באתר פעולות שעומדות בניגוד לדין או לתקנת הציבור.</li>
                        <li>המשתמש באתר מתחייב שלא לבצע מי מהפעילויות הבאות:</li>
                        <li>
                            להפעיל יישום או אמצעי כלשהו המאפשר סריקה, חיפוש, העתקה, קידוד, כריית מידע, תרגום או אחזור
                            אוטומטי של תכנים מהאתר. האמור בסעיף זה חל גם על פעולות שמיועדות לאסוף מידע ונתונים על משתמשי
                            האתר.&nbsp;
                        </li>
                        <li>
                            ליצור בדרך כלשהי מאגר או אוסף של תכנים מהאתר שלא מיועדים לשימוש אישי וישיר של המשתמש בהיקף
                            סביר.
                        </li>
                        <li>
                            להתערב בדרך כלשהי בכתובת ה-<span dir="LTR">URL</span> של עמודים באתר.
                        </li>
                        <li>לשנות או להתערב בקשר בין האתר לשרתים או לפלטפורמות המארחות אותו.</li>
                        <li>
                            לשנות או להעתיק בדרך כלשהי את קוד האתר, את עיצובו, את שירותיו או את התכנים המופיעים בו שלא
                            בהתאם לתקנון זה או להרשאה מראש ובכתב מצוות האתר.
                        </li>
                        <li>
                            לבצע פעולה המהווה עבירה על פי דין או כזו שמנוגדת לתקנת הציבור לרבות פרסום תוכן הפוגע בזכות
                            יוצרים, בסוד מסחרי, בסימן מסחר, במדגם או בפטנט של מאן דהו.
                        </li>
                        <li>
                            למען הסר ספק, אין לפרסם, להפיץ, להעתיק, לשנות, למכור, להציג וכיוצא בזה, בין אם באופן מסחרי
                            ובין אם באופן פרטי, כל תוכן ומידע המצוי באתר ללא רשות מפורשת בכתב מצוות האתר.
                        </li>
                        <li>
                            על אדם הנתקל בפעילות אסורה באתר על פי תקנון זה או על פי חוקי מדינת ישראל להודיע על כך מיד
                            לאתר.&nbsp;
                        </li>
                        <li>
                            שימוש באתר לרבות הגישה לתכניו לא מקנה למשתמש&nbsp;זכויות כלשהן באתר למעט זכות שימוש בשירותי
                            האתר ו/או בתכניו,&nbsp;<span dir="LTR">AS IS</span>. הקניין הרוחני בחומרים באתר, בעיצובי
                            התכשיטים ובתכניו יוותרו בכל מקרה בבעלות האתר או מי מטעמו לרבות זכויות היוצרים בהם. רכישת
                            זכות שימוש בתכנים ושירותים אחרים באתר ושימוש בהם יעשה אלא על פי הקבוע בתקנון זה.
                        </li>
                        <li>
                            צוות האתר רשאי למנוע מאדם מלעשות שימוש באתר אם ביצע פעולה אסורה כאמור לעיל, על פי שיקול הדעת
                            הבלעדי של צוות האתר.
                        </li>
                    </ol>

                    <p dir="RTL">
                        <strong>
                            <u>היעדר אחריות</u>
                        </strong>
                    </p>
                    <ol>
                        <li>
                            צוות האתר עושה ככל שביכולתו על מנת להבטיח שהאתר, התכנים והשירותים בו יהיו זמינים בכל עת. עם
                            זאת לא ניתן להתחייב שהאתר יהיה זמין בכל זמן. סביר כי יהיו זמנים בהם פעילות וזמינות האתר
                            תוגבל, בין בשל תקלה שאינה תלוי בצוות האתר ובין בשל פעולת תחזוקה מתוכננת.&nbsp;האתר לא יהיה
                            אחראי ולא תשמע כל תביעה לפיצוי או לנשיאה בנזק שיגרם למשתמש באתר או לאדם כלשהו כתוצאה מהשבתה
                            חלקית או מלאה של האתר או עקב אי זמינות של תכנים ושירותים הנכללים בו, לרבות הליך רכישה.
                            &nbsp;
                        </li>
                        <li>
                            השימוש באתר נעשה על אחריות המשתמש. צוות האתר לא יישא באחריות להתאמת השירותים הניתנים ו/או
                            התכנים המוצגים במסגרת האתר לצרכי המשתמש, כמו כן לא יישא באחריות לטעויות או לחסרים בחומרים או
                            במידע המוצג באתר. בכלל זה, לא יישא צוות האתר באחריות לכל נזק או הוצאה שנגרמו למשתמש או לכל
                            צד ג&apos; כתוצאה ישירה או עקיפה מן השימוש באתר, לרבות נזק שנגרם עקב השימוש ביישומי תוכנה
                            שהורדו ישירות באמצעות האתר או הופעלו כתוצאה מהשימוש באתר במישרין או בעקיפין.
                        </li>
                        <li>
                            האתר לא יישא באחריות לשינויים שנעשו בחומר המוצג באתר, על ידי המשתמש או על ידי כל צד ג&apos;.
                        </li>
                        <li>
                            לצוות האתר הזכות, לפי שיקול דעתו, לערוך ביקורת ביחס לפרסומים מכל סוג שהוא אשר יועלו לאתר, על
                            ידי משתמשים או על ידי כל גורם אחר, באמצעות עריכתם או הסרתם &ndash; כולם או חלקם &ndash; מכל
                            סיבה שהיא, וזאת מבלי להודיע על כך מראש או בדיעבד לגורם המפרסם.
                        </li>
                    </ol>

                    <p dir="RTL">
                        <strong>
                            <u>קישורים לאתרים חיצוניים</u>
                        </strong>
                    </p>
                    <ol>
                        <li>
                            באתר יכול שיופיעו קישורים (<span dir="LTR">Links</span>) המפנים לאתרים חיצוניים לאתר. אלה
                            נוצרו לנוחות המשתמש, אך אינם נמצאים בשליטת צוות האתר וצוות האתר אינו אחראי בכל דרך שהיא
                            לתוכן המופיע בהם, כך שגם אין לראות בקישורים אלה כאישור של צוות האתר לאמור בהם. יובהר כי אין
                            בכך כדי לגרוע מהעובדה כי לעיתים המשתמש יופנה כחלק ממהלך הקורס לתוכן באתר חיצוני.
                        </li>
                        <li>
                            משתמש באתר הסבור כי קישור לתוכן או אתר חיצוני אינו ראוי/תקין מכל סיבה שהיא, מתבקש לפנות לאתר
                            באמצעות דוא&quot;ל לכתובת&nbsp;<span dir="LTR">dimyonbekufsa@gmail.com</span> והקישור
                            יתוקן/יוסר ככל שפנייתו תמצא מוצדקת.
                        </li>
                    </ol>
                    <p dir="RTL">
                        <strong>
                            <u>רכישה והזמנת שירותים באתר</u>
                        </strong>
                    </p>
                    <ol>
                        <li>
                            הזמנת מוצרים ושירותים המוצעים באתר תעשה ישירות באמצעות האתר או בכל דרך אחרת שתקבע על ידי
                            צוות האתר מעת לעת.&nbsp;
                        </li>
                        <li>מילוי או מסירת כל הפרטים, באופן מדויק ומלא, מהווה תנאי מוקדם לביצוע הרכישה.&nbsp;</li>
                        <li>
                            אופן רכישת מוצרים ושירותים וביטול עסקה באתר כפוף לחוק הגנת הצרכן, תשמ&quot;א-1981 ויתר
                            הוראות הדין הרלוונטיות.&nbsp;
                        </li>
                        <li>המחירים באתר כוללים מע&quot;מ, אלא אם צוין אחרת.</li>
                        <li>
                            ככלל הרכישות באתר יבוצעו דרך ממשק אינטרנטי באמצעות כרטיס חיוב (שייקרא כמקובל בציבור: &quot;
                            <strong>כרטיס אשראי</strong>&quot;), הכול באמצעות שירות סליקה מאובטח (בהתאם לתקן אבטחה מחמיר
                            פרטי האשראי אינם נשמרים באתר).
                        </li>
                        <li>
                            על מנת להימנע מתקלות יש להקפיד על מסירת פרטים נכונים ומלאים במהלך הרכישה. בין היתר יש לציין
                            את הפרטים הבאים: שם מלא, תעודת זהות, טלפון, דואר אלקטרוני (אימייל), פרטי אמצעי חיוב, כתובת
                            דוא&quot;ל למשלוח קבלה וכתובת פיזית למשלוח ההזמנה.&nbsp;
                        </li>
                        <li>
                            יש לוודא שפרטי המוצר והכתובת למשלוח שהוזנו על ידי המשתמש, נכונים. צוות האתר לא יישא באחריות
                            במקרים בהם תהליך ההזמנה בוצע ברשלנות, לרבות מקרים בהם מולאו על ידי המשתמש פרטי משלוח שגויים,
                            נפלו טעויות בכמויות ההזמנה או הוזמן מוצר שגוי.
                        </li>
                        <li>
                            מסירת פרטים שגויים ביודעין מהווה הפרה של התקנון כמו גם של הוראות הדין ובנסיבות מסוימות אפשר
                            שיינקטו הליכים משפטיים נגד משתמש בהקשר זה.
                        </li>
                        <li>
                            לאחר השלמת הליך הרכישה תימסר למשתמש הודעה על אישור ההזמנה באתר (להלן: &quot;
                            <strong>אישור הזמנה</strong>&quot;) בדוא&quot;ל. אישור זה יכלול חשבונית ואת פרטי המוצרים
                            והשירותים שכלולים בעסקה.&nbsp;
                        </li>
                        <li>
                            במקרה בו הרכישה לא תאושר על ידי חברת האשראי ו/או חברת הסליקה, תימסר על כך הודעה למשתמש והוא
                            יידרש להסדיר את התשלום. בנסיבות אלו צוות האתר אינו מתחייב לשמור את פרטי ההזמנה או את המוצרים
                            ו/או השירותים שנכללו בה. יובהר כי הזמנה לא תטופל בטרם אישור חברת האשראי או חברת הסליקה.
                        </li>
                        <li>
                            צוות האתר רשאי לעדכן את מחירי המוצרים והשירותים ואת תעריפי המשלוח ללא הודעה מוקדמת. המחיר
                            המחייב את צוות האתר ואת המשתמש הוא מחיר המוצר שיהא בתוקף בזמן קבלת אישור חברת הסליקה על
                            ביצוע התשלום ו/או שליחת אישור ההזמנה על ידי האתר.&nbsp;
                        </li>
                        <li>תשלום עבור מנוי באתר יקנה לגולשים גישה לזמן לא מוגבל.&nbsp;</li>
                        <li>גלישת מנויים רשומים מוגבלת לשלושה מכשירים.&nbsp;</li>
                    </ol>
                    <p dir="RTL">
                        <strong>
                            <u>ביטול עסקה</u>
                        </strong>
                    </p>
                    <ol>
                        <li>על העסקאות באתר יחולו הוראות סעיף 14 ג&apos; לחוק הגנת הצרכן, התשמ&quot;א-1981 כדלקמן:</li>
                    </ol>
                    <p dir="RTL">(ג) בעסקת מכר מרחוק רשאי הצרכן לבטל את העסקה &ndash;&nbsp;</p>
                    <p dir="RTL">(1) ...</p>
                    <p dir="RTL">
                        (2) בשירות &ndash; בתוך 14 יום מיום עשיית העסקה או מיום קבלת המסמך המכיל את הפרטים האמורים בסעיף
                        קטן (ב), לפי המאוחר, כמפורט להלן: בעסקה מתמשכת &ndash; בין אם הוחל במתן השירות ובין אם לאו,
                        ובעסקה שאינה מתמשכת &ndash; בתנאי שביטול כאמור ייעשה לפחות שני ימים, שאינם ימי מנוחה, קודם למועד
                        שבו אמור השירות להינתן.&nbsp;
                    </p>
                    <p dir="RTL">&nbsp;</p>
                    <p dir="RTL">הוראות סעיף קטן (ג) לא יחולו על עסקת מכר מרחוק של &ndash;&nbsp;</p>
                    <ol>
                        <li>טובין פסידים;</li>
                        <li>
                            שירותי הארחה, נסיעה, חופש או בילוי, אם מועד ביטול העסקה חל בתוך שבעה ימים שאינם ימי מנוחה,
                            קודם למועד שבו אמור השירות להינתן;
                        </li>
                        <li>מידע כהגדרתו בחוק המחשבים, תשנ&quot;ה-1995;</li>
                        <li>טובין שיוצרו במיוחד בעבור הצרכן בעקבות העסקה;</li>
                        <li>
                            <strong>טובין הניתנים להקלטה, לשעתוק או לשכפול, שהצרכן פתח את אריזתם המקורית.</strong>
                        </li>
                    </ol>
                    <p dir="RTL">
                        כך גם <strong>יחול</strong>, ס&apos; 14 ה&apos; לחוק הגנת הצרכן על העסקה כדלקמן:
                    </p>
                    <p dir="RTL">תוצאות ביטול העסקה</p>
                    <ul>
                        <li>
                            ביטל צרכן חוזה לפי סעיפים 14א(ג), 14ג(ג) או 14ג1(ג) עקב פגם בנכס נושא החוזה או העסקה, עקב אי
                            התאמה בין הנכס או בין השירות, לבין הפרטים שנמסרו לו לפי סעיפים 14א(א) ו-(ב) או 14ג(א) ו-(ב),
                            עקב אי-אספקת הנכס או השירות במועד שנקבע לכך בחוזה או בשל כל הפרה אחרת בשל החוזה בידי העוסק
                            &ndash;&nbsp;
                        </li>
                        <li>
                            יחזיר העוסק לצרכן, בתוך 14 ימים מיום קבלת ההודעה על הביטול, את אותו חלק ממחיר העסקה ששולם על
                            ידי הצרכן, יבטל את חיובו של הצרכן בשל העסקה וימסור לו עותק מהודעת ביטול החיוב כאמור ולא יגבה
                            מהצרכן סכום כלשהו, זולת דמי ביטול בשיעור שלא יעלה על 5% ממחיר הנכס נושא החוזה או העסקה, או
                            100 שקלים חדשים, לפי הנמוך מביניהם;
                        </li>
                    </ul>
                    <p dir="RTL">
                        <strong>האמור לעיל ניתן למען הסר ספק, ואין בו כדי להוות יעוץ משפטי.</strong>
                    </p>
                    <p dir="RTL">
                        <strong>
                            זכות הביטול לעיל תקפה ביחס לצרכן בהגדרתו בחוק הגנת הצרכן, אך אינה תקפה או קיימת למי שאינו
                            צרכן.&nbsp;
                        </strong>
                    </p>
                    <p dir="RTL">
                        הלקוח ימסור הודעה על ביטול העסקה, בתוך תקופת הביטול כאמור אשר תכלול את הפרטים המלאים שלו לרבות
                        שם מלא, תעודת זהות אשר תמסר באמצעות הודעת דואר אלקטרוני לכתובת&nbsp;
                        <span dir="LTR">dimyonbekufsa@gmail.com</span> ו/או באמצעות קישור ייעודי בדף האינטרנט של האתר
                        בכתובת&nbsp;<span dir="LTR">https://imagineinabox.com/contact</span> ו/או באמצעות דואר רשום
                        לכתובת חיפה.
                    </p>
                    <p dir="RTL">
                        (להלן: &quot;<strong>הודעת הביטול&quot;).&nbsp;</strong>מועד קבלת הודעת הביטול את האתר בפועל
                        ייחשב למועד הקובע לעניין מועד הודעת הביטול (להלן:&quot;המועד הקובע&quot;). כל הודעה שתשלח לאחר
                        השעה 16:00, תיחשב כאילו התקבלה אצל החברה, ביום שלאחר יום תאריך המשלוח.
                    </p>
                    <p dir="RTL">
                        אם יחליט התלמד לבטל ו/או להפסיק השתתפותו במסלול הלימודים מכל סיבה שהיא, לאחר תקופת הביטול, הוא
                        יהיה רשאי לעשות כן, אך לא יהא זכאי להחזר כספי ולא תהיה לו כך כל טענה, תביעה או דרישה כלפי החברה
                        ו/או מי מטעמה. &nbsp;
                    </p>
                    <p dir="RTL">
                        <strong>
                            <u>קניין רוחני</u>
                        </strong>
                    </p>
                    <ol>
                        <li>
                            אתר זה כולל תכנים מקוריים, אשר צוות אתר זה הינו בעלת זכויות הקניין הרוחני בהם, וכן תכנים
                            ומידע אשר צוות אתר זה הינו בעלת ההרשאה הנדרשת לשם עשיית שימוש בהם. כל זכויות הקניין הרוחני
                            בתכנים המופיעים באתר, לרבות עיצוב אתר זה, הסידור וההצגה של המידע, סרטונים, קבצי שמע, הרצאות,
                            מצגות, תמונות, קבצים גרפיים, יישומים, קוד מחשב, טקסט ו/או כל חומר אחר, שייכים לאתר זה או לצד
                            שלישי שהתיר לאתר זה לעשות בהם שימוש.&nbsp;
                        </li>
                        <li>
                            השימוש באתר זה אינו מקנה למשתמש כל זכות לעשות שימוש בקניינו הרוחני של צוות אתר זה ו/או של
                            צדדים שלישיים. כל שימוש הפוגע בזכויות היוצרים ו/או בקניין רוחני כמפורט לעיל, ישמש עילה
                            להגבלת השימוש באתר על ידו, והמשתמש יישא בכל ההוצאות אשר יגרמו לצוות אתר זה ו/או לכל צד שלישי
                            מבלי לגרוע מכל סעד אחר המגיע לצוות אתר זה. יובהר כי צוות האתר שומר לעצמו את הזכות לתבוע את
                            נזקיו בגין הפרת זכויות הקניין הרוחני שלו.&nbsp;
                        </li>
                        <li>
                            אין להעתיק באופן מלא או חלקי, להציג בפומבי, להפיץ, לבצע בפומבי, להעביר לרשות הציבור, לשנות,
                            לעבד או ליצור יצירות נגזרות, למכור או להשכיר כל חלק מהתכנים הנ&quot;ל, בכל אמצעי &nbsp;שהוא,
                            בלי הסכמה בכתב ומראש מצד צוות אתר זה. חל איסור על כל שימוש בתכנים הנ&quot;ל ובסימני מסחר
                            המופיעים באתר ו/או לוגו אתר זה ללא אישור מפורש לכך מראש ובכתב מצוות אתר זה.
                        </li>
                        <li>
                            מובהר כי חל איסור מוחלט לעשות שימוש בסימני המסחר הרשומים ושאינם רשומים של האתר. חל איסור
                            מוחלט להפיץ חומרים פרסומיים הכוללים קורסים ו/או שירותים ו/או מוצרים של אתר זה מבלי לקבל את
                            אישור צוות האתר מראש.&nbsp;
                        </li>
                        <li>
                            משתמש באתר הסבור כי מופיע בו חומר שאין להציגו מכל סיבה שהיא, מתבקש לפנות לאתר באמצעות שליחת
                            דוא&quot;ל לכתובת&nbsp;<span dir="LTR">dimyonbekufsa@gmail.com</span> והחומר יוסר ככל
                            שפנייתו תמצא מוצדקת.
                        </li>
                    </ol>

                    <ol>
                        <li>
                            משתמש המעלה ו/או משתף חומרים, מידע ותגובות לאתר מקנה בכך לצוות האתר הרשאה חופשית ומלאה, ללא
                            תמורה, לעשות בעצמו, או באמצעות אחרים, שימוש באלה בכל צורה, מדיה ופורמט שהוא.
                        </li>
                    </ol>
                    <p dir="RTL">
                        <strong>
                            <u>הפרת התקנון</u>
                        </strong>
                    </p>
                    <ol>
                        <li>
                            המשתמש מתחייב לשפות ולפצות את האתר ו/או צוות האתר וכל גורם מטעמם או צד ג&apos; בגין נזק,
                            הוצאה, הפסד או אבדן רווח שיגרמו להם עקב הפרת תקנון זה על ידו או על ידי מי מטעמו. כל זאת
                            בנוסף לכל סעד על פי דין. המשתמש מסכים כי התחייבות זו לשיפוי ולפיצוי תחול&nbsp;בין&nbsp;אם
                            האתר או מי מטעמו הם צד לתובענה ובין אם לאו.
                        </li>
                        <li>
                            המשתמש ישלם את סכומי הפיצוי או השיפוי אשר נובעים מהפרת תקנון זה, כאמור לעיל, מיד עם דרישתו
                            הראשונה של צוות האתר או מי מהגורמים לעיל. דרישת תשלום זו תהווה ראיה מכרעת בדבר הצורך בשיפוי
                            ופיצוי באותו עניין.
                        </li>
                    </ol>
                    <p dir="RTL">
                        <strong>
                            <u>ברירת הדין ושונות</u>
                        </strong>
                    </p>
                    <ol>
                        <li>
                            <strong>כשירות לבצע פעולות</strong>:<span dir="LTR">&nbsp;</span>השימוש באתר מהווה הצהרה של
                            המשתמש כי הוא איננו פסול דין והוא כשיר לביצוע פעולות משפטיות (אחרת אנא המנע משימוש באתר). אף
                            על פי כן פעולה אשר&nbsp;תבוצע ע&quot;י משתמש קטין או שאינו כשיר לביצוע פעולות משפטיות תעשה
                            רק לאחר יידוע האפוטרופוס הטבעי או הממונה של הקטין או נציגו אשר כשיר לעשות פעולות בשם הקטין
                            והכל כמובנם בחוק הכשרות המשפטית והאפוטרופסות, תשכ&quot;ב-1962 לפי העניין (&apos;הנציג&apos;)
                            בתנאיי תקנון זה ובכפוף להסכמת הנציג. כל פעולה כאמור שתבוצע באתר ע&quot;י משתמש קטין או
                            שאיננו כשיר לביצוע פעולות משפטיות תהיה על דעת הנציג, בהסכמתו ותחת פיקוחו ותחייב את המשתמש
                            ואת נציגו כאחד. האמור בסעיף זה יחול גם אם מדובר בפעולה משפטית שאין בדרכם של קטין או אדם
                            שאינו כשיר לביצוע פעולה משפטית לעשות. &nbsp;&nbsp;
                        </li>
                        <li>הדינים אשר&nbsp;יחולו&nbsp;על תקנון זה הם דיני מדינת ישראל בלבד.&nbsp;</li>
                        <li>
                            במקרה בו מי מסעיפי תקנון זה יימצא כבטל או בלתי חוקי, לא יהיה בכך להשפיע על שאר חלקי תקנון
                            זה.&nbsp;
                        </li>
                        <li>
                            המשתמשים מוזמנים לעמוד בקשר עם צוות האתר בדבר התנאים בתקנון זה. עם זאת, אין בפנייה לאתר
                            בעניין התקנון בכדי להשפיע על תוקפו ועל הסכמת המשתמש לתנאיו ולתוכנו. ניתן ליצור
                            עמנו&nbsp;בדוא&quot;ל&nbsp;
                            <a href="mailto:dimyonbekufsa@gmail.com">
                                <span dir="LTR">dimyonbekufsa@gmail.com</span>
                            </a>
                            . &nbsp;
                        </li>
                    </ol>
                </div>
            </div>
        </MainLayout>
    );
}

export default Terms;
