import React from 'react';
import Maze from 'src/features/maze/Maze';
import { ITask } from 'src/wrapper';

export interface IClassMaze {
    task?: ITask;
    contentHeight: number;
    onFinish: () => void;
}

export function useClassMaze({ task, contentHeight, onFinish }: IClassMaze) {
    const render_maze = () => {
        if (!task) return null;
        if (!task.maze) return null;
        return (
            <Maze
                width={window.innerWidth * 0.85}
                height={contentHeight}
                onFinish={onFinish}
                lvl={task.maze.lvl}
                type={task.maze.letter_type}
            />
        );
    };

    const is_maze = () => {
        if (!task) return false;
        return task.maze !== undefined;
    };

    return {
        render_maze,
        is_maze,
    };
}
