import React, { useContext } from 'react';
import Webcam from 'react-webcam';
import { useMediaQueryWrap } from '../../common/useMediaQueryWrap';
import CircleButton from '../../common/buttons/CircleButton';
import cameraSvg from '../../assets/icons/camera.svg';
import viSvg from '../../assets/icons/vi.svg';
import agianSvg from '../../assets/icons/agian.svg';
import FramedImg from '../../folder/FramedImg';
import { useCamera } from './useCamera';
import { AppContext } from '../../AppContext';
import TurnScreen from '../../common/layouts/TurnScreen';
import MainModal from '../../common/layouts/MainModal';
import './Camera.css';

export interface ICameraModal {
    on_camera_over: (img: string) => void;
}

// aspect ratio: 1.41 - frame svg - dimensions are set 842*595
function CameraModal(props: ICameraModal): JSX.Element {
    const { state } = useContext(AppContext);
    const { setCameraOpen, webCamRef, finish, capture, img, setImg } = useCamera(props);
    const { should_turn_screen, get_action_btn_zoom, get_modal_width, is_horiz } = useMediaQueryWrap();
    const FACING_MODE = 'environment';
    const [mw, setMw] = React.useState(window.innerWidth * get_modal_width());

    React.useEffect(() => {
        if (!is_horiz) return;
        const custom_shrink = 0.9;
        const newwidth = window.innerHeight * 1.145 * custom_shrink;
        setMw(newwidth);
    }, [is_horiz]);

    const render = () => {
        if (should_turn_screen()) return <TurnScreen />;
        return (
            <div className="camera_modal_wrap">
                {img && <FramedImg img_src={img} id={'tmp_img'} />}
                {!img && (
                    <Webcam
                        ref={webCamRef}
                        videoConstraints={{
                            facingMode: FACING_MODE,
                        }}
                        audio={false}
                        screenshotFormat="image/png"
                        width={mw}
                        height={'fit-content'}
                        style={{ maxWidth: '100%', overflow: 'hidden' }}
                    />
                )}
                {img && (
                    <div className="camera_modal_wrap_btns">
                        <CircleButton
                            svg={agianSvg}
                            color={'red'}
                            onClick={() => setImg(null)}
                            ariaLabel="צלם שוב"
                            zoom={get_action_btn_zoom() - 0.2}
                        />
                        <CircleButton
                            svg={viSvg}
                            color={'green'}
                            onClick={finish}
                            ariaLabel="צילום מאושר"
                            zoom={get_action_btn_zoom() - 0.2}
                        />
                    </div>
                )}
                {!img && (
                    <div className="camera_modal_wrap_btns">
                        <CircleButton
                            svg={cameraSvg}
                            color={'purple'}
                            onClick={capture}
                            ariaLabel="לצלם"
                            zoom={get_action_btn_zoom() - 0.2}
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <MainModal id={'camera'} open={state.cameraOpen} close={() => setCameraOpen(false)}>
            {render()}
        </MainModal>
    );
}

export default CameraModal;
