import React from 'react';
import folderImage from '../assets/images/folderImage.svg';
import './FramedImg.css';

interface IFramedImg {
    img_src: string;
    onLoad?: () => void;
    id?: string;
}

function FramedImg({ id, img_src, onLoad }: IFramedImg): JSX.Element {
    return (
        <div id={id} className="folderImgWrapper">
            <img src={folderImage} className={'folderImageFrame'} alt="מסגרת" />
            <img src={img_src} onLoad={onLoad} className={'folderImage'} alt="תמונה של העבודה" />
        </div>
    );
}

export default FramedImg;
