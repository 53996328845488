import { IClassMetadata, IGameGroup, IMoreTaskMetadata, ITask } from './wrapper';
export const base_storage_path = 'https://storage.googleapis.com/kalef-app-379711.appspot.com';
export const base_storage_path_class = base_storage_path + '/math1_class';

import soon from 'src/assets/list_images/soon.webp';
import g_maze from 'src/assets/list_images/games/math_maze.webp';
import g_ship from 'src/assets/list_images/games/math_ship.webp';
import g_memory from 'src/assets/list_images/games/math_memory.webp';
import g_cash1 from 'src/assets/list_images/games/cash1.webp';
import g_cash2 from 'src/assets/list_images/games/cash2.webp';
import g_paint from 'src/assets/list_images/games/paint.webp';

import c1 from 'src/assets/list_images/math1_classroom/1.webp';
import c2 from 'src/assets/list_images/math1_classroom/2.webp';
import c3 from 'src/assets/list_images/math1_classroom/3.webp';
import c4 from 'src/assets/list_images/math1_classroom/4.webp';
import c5 from 'src/assets/list_images/math1_classroom/5.webp';
import c6 from 'src/assets/list_images/math1_classroom/6.webp';
import c7 from 'src/assets/list_images/math1_classroom/7.webp';
import c8 from 'src/assets/list_images/math1_classroom/8.webp';

import mt1 from 'src/assets/list_images/math1_more_tasks/1.webp';
import mt2 from 'src/assets/list_images/math1_more_tasks/2.webp';
import mt3 from 'src/assets/list_images/math1_more_tasks/3.webp';

// fixed size - 8
export const classes_metadata: IClassMetadata[] = [
    {
        tasks: [
            {
                video_url: base_storage_path_class + '/Bili1_0.mp4',
                pressNext: true,
                pressBack: false,
            },
            {
                video_url: base_storage_path_class + '/Bili1_1.mp4',
                pressNext: false,
                answers: {
                    answers: ['3', '2', '7'],
                    correct: '3',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili1_2.mp4',
                pressNext: false,
                answers: {
                    answers: ['1', '5', '9'],
                    correct: '5',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili1_3.mp4',
                pressNext: false,
                answers: {
                    answers: ['4', '2', '7'],
                    correct: '7',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili1_4.mp4',
                pressNext: false,
                answers: {
                    answers: ['3', '10', '9'],
                    correct: '10',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili1_5.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/Bili1_6.mp4',
                ab_type: 'number',
                ab_ruler_match: ['3', '8'],
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili1_7.mp4',
                ab_type: 'number',
                ab_ruler_match: ['2', '9'],
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili1_8.mp4',
                ab_type: 'number',
                ab_ruler_match: ['5', '7'],
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili1_9.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/Bili1_10.mp4',
                pressNext: true,
            },
            {
                video_url: '',
                puzzle: { lvl: 1, letter_type: 'number' },
            },
            {
                video_url: base_storage_path_class + '/Bili1_11.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/Bili1_12.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/Bili1_13.mp4',
                pressNext: false,
                render_time: 70,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        materials: ['צבעים', 'דפים'],
        time_len: '25',
        title: 'שיעור ראשון',
        img: c1,
    },
    {
        title: 'שיעור שני',
        materials: ['צבעים', 'דפים', 'מספריים', 'דבק סטיק', 'עיתונים'],
        time_len: '30',
        tasks: [
            {
                video_url: base_storage_path_class + '/Bili_2_0.mp4',
                pressNext: true,
                pressBack: false,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_1.mp4',
                pressNext: false,
                micTask: { word_match: ['תפוח'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_2.mp4',
                pressNext: false,
                micTask: { word_match: ['פרפר'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_3.mp4',
                pressNext: false,
                micTask: { word_match: ['מטוס'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_4.mp4',
                pressNext: false,
                micTask: { word_match: ['כדור'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_5.mp4',
                pressNext: false,
                micTask: { word_match: ['בננה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_6.mp4',
                pressNext: false,
                answers: {
                    answers: ['5', '2', '3'],
                    correct: '2',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_7.mp4',
                pressNext: false,
                answers: {
                    answers: ['4', '1', '3'],
                    correct: '4',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_8.mp4',
                pressNext: false,
                answers: {
                    answers: ['2', '1', '6'],
                    correct: '2',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_9.mp4',
                pressNext: false,
                answers: {
                    answers: ['3', '5', '4'],
                    correct: '4',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_10.mp4',
                pressNext: false,
                answers: {
                    answers: ['11', '10', '9'],
                    correct: '10',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_11.mp4',
                pressNext: false,
                answers: {
                    answers: ['9', '8', '7'],
                    correct: '9',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_12.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_13.mp4',
                pressNext: false,
                micTask: { word_match: ['פרה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_14.mp4',
                pressNext: false,
                micTask: { word_match: ['תרנגול'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_15.mp4',
                pressNext: false,
                micTask: { word_match: ['כלב'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_16.mp4',
                pressNext: false,
                micTask: { word_match: ['תוכי'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_17.mp4',
                pressNext: false,
                micTask: { word_match: ['דולפין'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_18.mp4',
                pressNext: false,
                micTask: { word_match: ['אריה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_19.mp4',
                pressNext: false,
                micTask: { word_match: ['סוס'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_20.mp4',
                pressNext: false,
                micTask: { word_match: ['חזיר'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_21.mp4',
                pressNext: false,
                micTask: { word_match: ['קנגורו'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_22.mp4',
                pressNext: false,
                micTask: { word_match: ['גיראפה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_23.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_2_24.mp4',
                pressNext: false,
                render_time: 60,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        img: c2,
    },
    {
        title: 'שיעור שלישי',
        materials: ['צבעים', 'דפים'],
        time_len: '25',
        tasks: [
            {
                video_url: base_storage_path_class + '/Bili_3_0.mp4',
                pressNext: false,
                pressBack: false,
                micTask: { word_match: ['פרחים_צהוב', 'פרחים_כללי'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_1.mp4',
                pressNext: false,
                micTask: { word_match: ['פרח_לבן', 'פרחים_כללי'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_2.mp4',
                pressNext: false,
                answers: {
                    answers: ['10', '9', '12'],
                    correct: '10',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_3.mp4',
                pressNext: false,
                answers: {
                    answers: ['1', '3', '4'],
                    correct: '3',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_4.mp4',
                pressNext: false,
                answers: {
                    answers: ['5', '7', '2'],
                    correct: '7',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_5.mp4',
                pressNext: false,
                answers: {
                    answers: ['3', '1', '2'],
                    correct: '2',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_6.mp4',
                pressNext: false,
                answers: {
                    answers: ['5', '1', '2'],
                    correct: '5',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_7.mp4',
                pressNext: false,
                answers: {
                    answers: ['3', '2', '1'],
                    correct: '2',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_8.mp4',
                pressNext: false,
                micTask: { word_match: ['תפוח'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_9.mp4',
                pressNext: false,
                answers: {
                    answers: ['>', '=', '<'],
                    correct: '=',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_10.mp4',
                pressNext: true,
            },
            {
                video_url: '',
                paintMaze: 4,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_11.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/Bili_3_12.mp4',
                pressNext: false,
                render_time: 90,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        img: c3,
    },
    {
        title: 'שיעור רביעי',
        materials: ['צבעים', 'דפים'],
        time_len: '25',
        tasks: [
            {
                video_url: base_storage_path_class + '/Bili4_0.mp4',
                answers: {
                    answers: ['8', '10', '1'],
                    correct: '10',
                },
                successSound: true,
                pressNext: false,
                pressBack: false,
            },
            {
                video_url: base_storage_path_class + '/Bili4_1.mp4',
                answers: {
                    answers: ['8', '7', '6'],
                    correct: '8',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili4_2.mp4',
                answers: {
                    answers: ['1', '4', '5'],
                    correct: '5',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili4_3.mp4',
                answers: {
                    answers: ['3', '1', '2'],
                    correct: '2',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili4_4.mp4',
                answers: {
                    answers: ['11', '10', '9'],
                    correct: '11',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili4_5.mp4',
                answers: {
                    answers: ['11', '13', '12'],
                    correct: '13',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili4_6.mp4',
                answers: {
                    answers: ['6', '16', '8'],
                    correct: '16',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili4_7.mp4',
                answers: {
                    answers: ['19', '10', '9'],
                    correct: '19',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili4_8.mp4',
                answers: {
                    answers: ['10', '30', '20'],
                    correct: '20',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/Bili4_9.mp4',
                pressNext: true,
            },
            {
                video_url: '',
                maze: { lvl: 2, letter_type: 'number' },
            },
            {
                video_url: base_storage_path_class + '/Bili4_10.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/Bili4_11.mp4',
                pressNext: false,
                render_time: 52,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        img: c4,
    },
    {
        title: 'שיעור חמישי',
        materials: ['צבעים', 'דפים'],
        time_len: '30',
        tasks: [
            {
                video_url: base_storage_path_class + '/B5_0.mp4',
                pressNext: false,
                pressBack: false,
                micTask: { word_match: ['רודי'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_1.mp4',
                pressNext: false,
                answers: {
                    answers: ['8', '5', '7'],
                    correct: '8',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_2.mp4',
                pressNext: false,
                micTask: { word_match: ['רודי'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_3.mp4',
                pressNext: false,
                answers: {
                    answers: ['1', '2', '3'],
                    correct: '2',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_4.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/B5_5.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/B5_6.mp4',
                pressNext: false,
                micTask: { word_match: ['משולש'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_7.mp4',
                pressNext: false,
                micTask: { word_match: ['עיגול'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_8.mp4',
                pressNext: false,
                micTask: { word_match: ['ריבוע'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_9.mp4',
                pressNext: false,
                micTask: { word_match: ['עיגול'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_10.mp4',
                pressNext: false,
                micTask: { word_match: ['משולש'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_11.mp4',
                pressNext: false,
                micTask: { word_match: ['ריבוע'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_12.mp4',
                pressNext: false,
                micTask: { word_match: ['עיגול'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_13.mp4',
                pressNext: false,
                answers: {
                    answers: ['1', '10', '6'],
                    correct: '6',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_14.mp4',
                pressNext: false,
                answers: {
                    answers: ['3', '2', '7'],
                    correct: '3',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_15.mp4',
                pressNext: false,
                answers: {
                    answers: ['10', '9', '8'],
                    correct: '9',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_16.mp4',
                pressNext: false,
                answers: {
                    answers: ['6', '5', '3'],
                    correct: '5',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_17.mp4',
                pressNext: false,
                answers: {
                    answers: ['4', '6', '8'],
                    correct: '8',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_18.mp4',
                pressNext: false,
                answers: {
                    answers: ['10', '11', '12'],
                    correct: '11',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B5_19.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/B5_20.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/B5_21.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/B5_22.mp4',
                pressNext: false,
                render_time: 40,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        img: c5,
    },
    {
        title: 'שיעור שישי',
        materials: ['צבעים', 'דפים'],
        time_len: '25',
        tasks: [
            {
                video_url: base_storage_path_class + '/B6_0.mp4',
                pressBack: false,
                pressNext: false,
                answers: {
                    answers: ['10', '12', '11'],
                    correct: '11',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_1.mp4',
                pressNext: false,
                answers: {
                    answers: ['5', '15', '13'],
                    correct: '15',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_2.mp4',
                pressBack: false,
                pressNext: false,
                answers: {
                    answers: ['10', '21', '20'],
                    correct: '20',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_3.mp4',
                pressNext: false,
                answers: {
                    answers: ['3', '4', '5'],
                    correct: '4',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_4.mp4',
                pressBack: false,
                pressNext: false,
                answers: {
                    answers: ['3', '1', '2'],
                    correct: '3',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_5.mp4',
                pressNext: false,
                micTask: { word_match: ['עץ_אדום'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_6.mp4',
                pressNext: false,
                micTask: { word_match: ['פרח_צהוב'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_7.mp4',
                pressNext: false,
                micTask: { word_match: ['עץ_כחול'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_8.mp4',
                pressBack: false,
                pressNext: false,
                answers: {
                    answers: ['15', '13', '5'],
                    correct: '15',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_9.mp4',
                pressNext: false,
                answers: {
                    answers: ['1', '10', '8'],
                    correct: '10',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_10.mp4',
                pressBack: false,
                pressNext: false,
                answers: {
                    answers: ['12', '11', '2'],
                    correct: '12',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_11.mp4',
                pressNext: false,
                answers: {
                    answers: ['2', '4', '8'],
                    correct: '8',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_12.mp4',
                pressBack: false,
                pressNext: false,
                answers: {
                    answers: ['6', '5', '7'],
                    correct: '5',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B6_13.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/B6_14.mp4',
                pressNext: false,
                render_time: 30,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        img: c6,
    },
    {
        title: 'שיעור שביעי',
        materials: ['צבעים', 'דפים', 'מספריים', 'דבק סטיק', 'סרגל', 'עיתונים'],
        time_len: '30',
        tasks: [
            {
                video_url: base_storage_path_class + '/B7_0.mp4',
                pressBack: false,
                pressNext: false,
                answers: {
                    answers: ['3', '2', '1'],
                    correct: '2',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_1.mp4',
                pressNext: false,
                answers: {
                    answers: ['2', '3'],
                    correct: '2',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_2.mp4',
                pressNext: false,
                answers: {
                    answers: ['1', '4'],
                    correct: '4',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_3.mp4',
                pressNext: false,
                answers: {
                    answers: ['5', '6'],
                    correct: '6',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_4.mp4',
                pressNext: false,
                answers: {
                    answers: ['8', '7'],
                    correct: '8',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_5.mp4',
                pressNext: false,
                answers: {
                    answers: ['10', '9'],
                    correct: '10',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_6.mp4',
                pressNext: false,
                answers: {
                    answers: ['1', '2'],
                    correct: '1',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_7.mp4',
                pressNext: false,
                answers: {
                    answers: ['4', '3'],
                    correct: '3',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_8.mp4',
                pressNext: false,
                answers: {
                    answers: ['5', '6'],
                    correct: '5',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_9.mp4',
                pressNext: false,
                answers: {
                    answers: ['8', '7'],
                    correct: '7',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_10.mp4',
                pressNext: false,
                answers: {
                    answers: ['10', '9'],
                    correct: '9',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B7_11.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/B7_12.mp4',
                pressNext: false,
                render_time: 30,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        img: c7,
    },
    {
        title: 'שיעור שמיני',
        materials: ['צבעים', 'דפים'],
        time_len: '30',
        tasks: [
            {
                video_url: base_storage_path_class + '/B8_0.mp4',
                pressNext: true,
                pressBack: false,
            },
            {
                video_url: base_storage_path_class + '/B8_1.mp4',
                pressNext: false,
                answers: {
                    answers: ['6', '9', '7'],
                    correct: '7',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_2.mp4',
                pressNext: false,
                answers: {
                    answers: ['10', '8', '2'],
                    correct: '8',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_3.mp4',
                pressNext: false,
                micTask: { word_match: ['תפוח', 'שמונה_תפוחים'] },
                successSound: true,
            },

            {
                video_url: base_storage_path_class + '/B8_4.mp4',
                pressNext: false,
                answers: {
                    answers: ['8', '5', '6'],
                    correct: '8',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_5.mp4',
                pressNext: false,
                answers: {
                    answers: ['10', '14', '12'],
                    correct: '14',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_6.mp4',
                pressNext: false,
                answers: {
                    answers: ['12', '14', '16'],
                    correct: '16',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_7.mp4',
                pressNext: false,
                answers: {
                    answers: ['1', '4', '3'],
                    correct: '4',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_8.mp4',
                pressNext: false,
                answers: {
                    answers: ['20', '19', '21'],
                    correct: '20',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_9.mp4',
                pressNext: false,
                answers: {
                    answers: ['17', '7', '13'],
                    correct: '17',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_10.mp4',
                pressNext: false,
                answers: {
                    answers: ['10', '11', '12'],
                    correct: '10',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_11.mp4',
                pressNext: false,
                micTask: { word_match: ['זוגי'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_12.mp4',
                pressNext: false,
                micTask: { word_match: ['עיניים'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_13.mp4',
                pressNext: false,
                micTask: { word_match: ['זוגות_פנים'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_14.mp4',
                pressNext: false,
                micTask: { word_match: ['משולש'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_15.mp4',
                pressNext: false,
                micTask: { word_match: ['ריבוע'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_16.mp4',
                pressNext: false,
                micTask: { word_match: ['משולש'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_17.mp4',
                pressNext: false,
                answers: {
                    answers: ['3', '5', '7'],
                    correct: '5',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/B8_18.mp4',
                pressNext: true,
                fillAddress: true,
            },
            {
                video_url: base_storage_path_class + '/B8_19.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/B8_20.mp4',
                cameraTask: { finish_to: 'none' },
                render_time: 30,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/B8_21.mp4',
                pressNext: true,
                finish_to_classroom: true,
            },
        ],
        img: c8,
    },
];

export const more_tasks_metadata: IMoreTaskMetadata[] = [
    {
        title: 'חיבור פרחים',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt1.mp4',
        img: mt1,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt1.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [111],
                tasks: [{ none: true }, { answers: { answers: ['8', '6', '9'], correct: '9' } }, { none: true }],
            },
        },
    },
    {
        title: 'כדורי גלידה',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt2.mp4',
        img: mt2,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt2.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [27, 71],
                tasks: [
                    { none: true },
                    { answers: { answers: ['3', '2', '1'], correct: '3' } },
                    { none: true },
                    { answers: { answers: ['6', '7', '8'], correct: '8' } },
                    { none: true },
                ],
            },
        },
    },
    {
        title: 'חיבור צורות',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt3.mp4',
        img: mt3,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt3.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [218],
                tasks: [{ none: true }, { answers: { answers: ['10', '9', '8'], correct: '10' } }, { none: true }],
            },
        },
    },
    {
        title: 'בקרוב',
        materials: ['צבעים', 'דפים'],
        video_url: '',
        img: soon,
    },
];

const cash_game1 = ['6', '9', '4', '3', '7', '6_2', '10', '8', '9_2'];
const cash_game1_tasks: ITask[] = cash_game1.map((id_) => ({
    video_url: '',
    cashGame: id_,
}));

const cash_game2 = [
    '12',
    '17',
    '11',
    '16',
    '13',
    '12_2',
    '18',
    '14',
    '16_2',
    '11_2',
    '13_2',
    '19',
    '18',
    '12_2',
    '19_2',
];
const cash_game2_tasks: ITask[] = cash_game2.map((id_) => ({
    video_url: '',
    cashGame: id_,
}));

const cash_game3 = [
    '11_l2',
    '15_l2',
    '12_l2',
    '14_l2',
    '19_l2',
    '13_l2',
    '14_2_l2',
    '17_l2',
    '16_l2',
    '18_l2',
    '17_2_l2',
];

const cash_game3_tasks: ITask[] = cash_game3.map((id_) => ({
    video_url: '',
    cashGame: id_,
}));

const cash_game4 = ['23', '28', '24', '29', '25', '27', '26', '30'];
const cash_game4_tasks: ITask[] = cash_game4.map((id_) => ({
    video_url: '',
    cashGame: id_,
}));

export const games: IGameGroup[] = [
    {
        title: 'מבוך המספרים',
        desc: 'הובילו את בילי במבוך המספרים',
        games: [
            {
                title: '1 עד 10',
                task: {
                    video_url: '',
                    maze: { lvl: 1, letter_type: 'number' },
                },
                img: g_maze,
            },
            {
                title: '1 עד 20',
                task: {
                    video_url: '',
                    maze: { lvl: 2, letter_type: 'number' },
                },
                img: g_maze,
            },
            {
                title: 'קפיצות של 2 עד 20',
                task: {
                    video_url: '',
                    maze: { lvl: 3, letter_type: 'number' },
                },
                img: g_maze,
            },
            {
                title: 'קפיצות אחורה 10 עד 1',
                task: {
                    video_url: '',
                    maze: { lvl: 4, letter_type: 'number' },
                },
                img: g_maze,
            },
            {
                title: 'קפיצות אחורה 20 עד 1',
                task: {
                    video_url: '',
                    maze: { lvl: 5, letter_type: 'number' },
                },
                img: g_maze,
            },
        ],
        img: g_maze,
    },
    {
        title: 'כבשים מבולבלות',
        desc: 'מסדרים את הכבשים ומחזקים את הכרת סדר המספרים',
        games: [
            {
                title: 'עד 10',
                task: {
                    video_url: '',
                    puzzle: { lvl: 1, letter_type: 'number' },
                },
                img: g_ship,
            },
            {
                title: 'עד 20',
                task: {
                    video_url: '',
                    puzzle: { lvl: 2, letter_type: 'number' },
                },
                img: g_ship,
            },
            {
                title: 'קפיצות של 2 עד 20',
                task: {
                    video_url: '',
                    puzzle: { lvl: 3, letter_type: 'number' },
                },
                img: g_ship,
            },
        ],
        img: g_ship,
    },
    {
        title: 'ציור בחידות',
        desc: 'גלו את הציור המסתתר מאחורי חידות המספרים',
        games: [
            {
                title: 'סימני גדול, קטן שווה',
                task: {
                    video_url: '',
                    paintMaze: 4, // boat
                },
                img: g_paint,
            },
            {
                title: 'סימני גדול, קטן ושווה ותרגילי חיבור',
                task: {
                    video_url: '',
                    paintMaze: 3, // flowers
                },
                img: g_paint,
            },
            {
                title: 'חיבור בעשרת הראשונה',
                task: {
                    video_url: '',
                    paintMaze: 1, // house
                },
                img: g_paint,
            },
            {
                title: 'חיבור בעשרת הראשונה',
                task: {
                    video_url: '',
                    paintMaze: 2, // heart
                },
                img: g_paint,
            },
        ],
        img: g_paint,
    },
    {
        title: 'משחק החנות',
        desc: 'מתנסים בחיבור וחיסור סכומים על ידי שימוש במטבעות',
        games: [
            {
                title: 'עשרת ראשונה',
                mul_tasks: cash_game1_tasks,
                img: g_cash1,
            },
            {
                title: 'עשרת שניה',
                mul_tasks: cash_game2_tasks,
                img: g_cash2,
            },
            {
                title: 'עשרת שניה',
                mul_tasks: cash_game3_tasks,
                img: g_cash2,
            },
            {
                title: 'עשרת שלישית',
                mul_tasks: cash_game4_tasks,
                img: g_cash2,
            },
        ],
        img: g_cash1,
    },
    {
        title: 'משחק הזיכרון',
        desc: 'המשחק האהוב בואריאציות שונות',
        games: [
            {
                title: 'עשרת ראשונה',
                task: {
                    video_url: '',
                    memory_cards: 9,
                },
                img: g_memory,
            },
            {
                title: 'חיבור עד 10',
                task: {
                    video_url: '',
                    memory_cards: 10,
                },
                img: g_memory,
            },
            {
                title: 'חיסור עד 10',
                task: {
                    video_url: '',
                    memory_cards: 11,
                },
                img: g_memory,
            },
            {
                title: 'חיבור מעל 10',
                task: {
                    video_url: '',
                    memory_cards: 12,
                },
                img: g_memory,
            },
        ],
        img: g_memory,
    },
];
