import React from 'react';
import { useState, useEffect } from 'react';
import ArrowUp from '../../assets/images/arrow-up.svg';
import MobileMenu from './MobileMenu';
import { useLogin } from 'src/common/login/useLogin';
import { useShare } from 'src/common/useShare';
import './header.css';
import { scroll_to_element } from 'src/common/common';

export interface IHeaderProps {
    logo: string;
}

function Header({ logo }: IHeaderProps): JSX.Element {
    const [isBtVisible, setIsBtVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { toggle_login_modal } = useLogin();
    const { shareAppLink } = useShare();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            const offsetTop = window.scrollY || document.documentElement.scrollTop;
            setIsBtVisible(offsetTop > 800);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };

    return (
        <>
            <header className="header bg-white">
                <div className="container flex items-center justify-between">
                    <img style={{ height: '65px' }} src={logo} alt="דמיון בקופסא" />
                    <nav className="menu only-desk-xl">
                        <ul className="flex">
                            <li>
                                <a
                                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                                    onClick={() => scroll_to_element('SecSell')}
                                >
                                    להרשמה
                                </a>
                            </li>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={() => toggle_login_modal(true)}>
                                    התחברות
                                </a>
                            </li>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={() => scroll_to_element('SecRecom')}>
                                    הורים ממליצים
                                </a>
                            </li>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={() => scroll_to_element('SecCourse')}>
                                    איך עובד הקורס
                                </a>
                            </li>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={() => scroll_to_element('SecFAQ')}>
                                    שאלות ותשובות
                                </a>
                            </li>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={() => scroll_to_element('SecAbout')}>
                                    אודות
                                </a>
                            </li>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={() => scroll_to_element('Footer')}>
                                    צרו קשר
                                </a>
                            </li>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={shareAppLink}>
                                    שתפו
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <button onClick={toggleMenu} className="app_menu_btn only-tablet" aria-label="פתח תפריט">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <MobileMenu isOpen={isOpen} toggleMenu={toggleMenu} />
                </div>
            </header>

            <button
                className={`backtop flex-center ${isBtVisible ? 'show' : ''}`}
                onClick={scrollToTop}
                aria-label="חזור למעלה"
                title="חזור למעלה"
            >
                <img className="btn-image" src={ArrowUp} alt="חזור למעלה" />
            </button>
        </>
    );
}

export default Header;
