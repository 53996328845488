import {
    letters,
    numbers_to_10_backwards,
    number_to_10,
    number_to_20,
    numbers_to_20_backwards,
} from 'src/common/common';

import maze_heb_ins from 'src/assets/sounds/maze_instructions.mp3';
import maze_math1 from 'src/assets/sounds/math_maze1.mp3';
import maze_math2 from 'src/assets/sounds/math_maze2.mp3';
import maze_math3 from 'src/assets/sounds/math_maze3.mp3';
import maze_math4 from 'src/assets/sounds/math_maze4.mp3';
import maze_math5 from 'src/assets/sounds/math_maze5.mp3';

interface mazeLvl {
    indices: number[];
    content: string[];
    instructions: string;
    cell_blocks: number[];
    mod2_odd?: boolean;
    mod2_even?: boolean;
}

interface mazeLvlDict {
    [key: number]: mazeLvl;
}

interface mazeGame {
    maze_levels: mazeLvlDict;
}

interface mazeGames {
    [key: string]: mazeGame;
}

export function useMazeConfig() {
    const letter_lvl1: mazeLvl = {
        indices: [3, 8, 17, 20, 53, 11, 44, 69, 49, 78, 66, 12, 89, 88, 97, 36, 100, 71, 103, 51, 26, 93],
        cell_blocks: [79, 80, 81, 96, 23, 24, 39, 40, 55, 54, 56, 95, 94],
        content: letters,
        instructions: maze_heb_ins,
    };

    const letter_lvl2: mazeLvl = {
        indices: [3, 50, 52, 7, 80, 21, 56, 69, 45, 78, 98, 14, 31, 59, 40, 65, 100, 71, 103, 34, 26, 62],
        cell_blocks: [35, 36, 37, 51, 88, 89, 104, 13, 28, 27, 43],
        content: letters,
        instructions: maze_heb_ins,
    };

    const letter_lvl3: mazeLvl = {
        indices: [3, 8, 32, 20, 27, 11, 44, 1, 45, 57, 66, 12, 89, 47, 26, 36, 100, 71, 67, 51, 25, 93],
        cell_blocks: [63, 64, 79, 94, 24, 39, 54, 9, 23, 21, 22, 69, 48, 33, 70],
        content: letters,
        instructions: maze_heb_ins,
    };

    const letter_lvl4: mazeLvl = {
        indices: [3, 14, 32, 20, 27, 11, 78, 1, 69, 64, 8, 12, 89, 46, 53, 94, 36, 71, 102, 39, 13, 98],
        cell_blocks: [52, 51, 65, 66, 67, 80, 50, 77, 91, 92, 93, 81, 82, 9, 10, 25, 40, 41, 42, 26],
        content: letters,
        instructions: maze_heb_ins,
    };

    const letter_levels: mazeLvlDict = {
        1: letter_lvl1,
        2: letter_lvl2,
        3: letter_lvl3,
        4: letter_lvl4,
    };

    const letter_game: mazeGame = {
        maze_levels: letter_levels,
    };

    const to10: mazeLvl = {
        content: number_to_10,
        indices: [3, 47, 83, 20, 88, 11, 44, 69, 78, 34],
        cell_blocks: [79, 80, 81, 96, 23, 24, 39, 40, 55, 54, 56, 95, 94],
        instructions: maze_math1,
    };

    const to20: mazeLvl = {
        content: number_to_20,
        indices: [18, 65, 10, 20, 58, 11, 44, 38, 78, 34, 84, 47, 41, 104, 87, 97, 30, 6, 12, 14],
        cell_blocks: [79, 80, 81, 96, 23, 24, 39, 40, 55, 54, 56, 95, 94],
        instructions: maze_math2,
    };

    const to20even: mazeLvl = {
        content: number_to_20,
        indices: [2, 8, 17, 20, 53, 11, 44, 69, 78, 34, 35, 99, 73, 46, 25, 65, 97, 7, 87, 104],
        cell_blocks: [79, 80, 81, 96, 23, 24, 39, 40, 55, 54, 56, 95, 94],
        instructions: maze_math3,
        mod2_even: true,
    };

    const to10backwards: mazeLvl = {
        content: numbers_to_10_backwards,
        indices: [64, 38, 30, 8, 98, 101, 19, 69, 78, 25],
        cell_blocks: [79, 80, 81, 96, 23, 24, 39, 40, 55, 54, 56, 95, 94],
        instructions: maze_math4,
    };

    const to20backwards: mazeLvl = {
        content: numbers_to_20_backwards,
        indices: [98, 17, 15, 65, 38, 14, 42, 70, 78, 34, 46, 102, 11, 64, 7, 57, 35, 67, 59, 5],
        cell_blocks: [79, 80, 81, 96, 23, 24, 39, 40, 55, 54, 56, 95, 94],
        instructions: maze_math5,
    };

    const number_levels: mazeLvlDict = {
        1: to10,
        2: to20,
        3: to20even,
        4: to10backwards,
        5: to20backwards,
    };

    const number_game: mazeGame = {
        maze_levels: number_levels,
    };

    const games: mazeGames = {
        letter: letter_game,
        number: number_game,
    };

    return { games };
}
