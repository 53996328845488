import axios from 'axios';
import { base_url, get_device_semi_id } from './common';

export type TranscribeType = 'eng' | 'heb';

const transcribe = async (
    audio: Blob,
    transcribeType: TranscribeType,
    debug_match: string | string[] | undefined,
    finished: Function,
    handle_no_token_err: (error: any) => void,
) => {
    const formData = new FormData();
    formData.append(`api_${transcribeType}`, audio);
    formData.append('token', new Blob([localStorage.getItem('ib_token') ?? 'removed'], { type: 'plain/text' }));
    formData.append('device', new Blob([get_device_semi_id()], { type: 'plain/text' }));
    if (typeof debug_match === 'string') formData.append('match', new Blob([debug_match], { type: 'plain/text' }));
    else if (debug_match) formData.append('match', new Blob(debug_match, { type: 'plain/text' }));

    try {
        const response = await axios.post(base_url + 'transcribe', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        finished(response.data.res);
    } catch (error: any) {
        handle_no_token_err(error);
        finished('mic error');
    }
};
export default transcribe;
