import React from 'react';
import Wizard from '../../assets/images/wizard.svg';
import Form from '../form/Form';
import AppRights from 'src/common/AppRights';
import Socials from 'src/common/layouts/static/Socials';
import messages from 'src/assets/messages_he.json';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer text-center relative">
            <div id="Footer" className="anchor"></div>

            <div className="container relative">
                <div className="pb-4">
                    <img className="mb-1 mt-1" src={Wizard} alt="wizard" />
                    <h2 className="title-42">יש לכם עוד שאלות?</h2>
                    <div className="text-xl">מוזמנים לפנות אלינו ונחזור אליכם בהקדם</div>
                    <Form />
                    <p className="text-xl" style={{ textAlign: 'center', marginTop: '3rem' }}>
                        {messages['contact.text1']} <br /> {messages['contact.text2']}
                    </p>
                    <Socials />
                </div>

                <AppRights id={'home_desktop'} />
            </div>
        </footer>
    );
}

export default Footer;
