import React from 'react';
import AppMenu from '../AppMenu';
import AppRights from '../AppRights';
import LogoButton from '../buttons/LogoButton';
import { useNav } from '../useNav';
import HeaderAction from '../header/HeaderAction';
import PaymentModal from '../payment/PaymentModal';
import LoginModal from '../login/LoginModal';
import AddresModal from '../address/AddressModal';
import { Helmet } from 'react-helmet';
import { useMediaQueryWrap } from '../useMediaQueryWrap';
import './MainLayout.css';

export type layoutId =
    | 'home_desktop'
    | 'home_mobile'
    | 'home_tablet'
    | 'cr_desktop'
    | 'cr_tablet'
    | 'cr_mobile'
    | 'class_desktop'
    | 'class_tablet'
    | 'class_mobile'
    | 'promo_desktop'
    | 'promo_tablet'
    | 'promo_mobile'
    | 'folder_desktop'
    | 'folder_tablet'
    | 'folder_mobile'
    | 'moretasks_desktop'
    | 'moretasks_tablet'
    | 'moretasks_mobile'
    | 'tips_desktop'
    | 'tips_tablet'
    | 'tips_mobile'
    | 'notfound'
    | 'text';

export interface IMainLayout {
    id: layoutId;
    logo: string;
    appMenuLogo: string;
    can_url?: string;
    children: React.ReactNode;
    header_title?: string;
}

const base_url = 'https://imagineinabox.com';

function MainLayout(props: IMainLayout): JSX.Element {
    const { navigateToHome } = useNav();
    const { hide_header_title } = useMediaQueryWrap();

    return (
        <>
            <Helmet>{props.can_url && <link rel="canonical" href={base_url + props.can_url} />}</Helmet>
            <div id={props.id} className={'mainLayoutBackground'}>
                <div className="mainLayoutContent">
                    <header className="mainLayoutHeader">
                        <LogoButton svg={props.logo} onClick={() => navigateToHome()} ariaLabel="לדף הבית" />
                        {props.header_title && !hide_header_title && (
                            <div className="header_class_title">{props.header_title}</div>
                        )}
                        <div className="mainLayoutHeaderLeft">
                            <HeaderAction id={props.id} />
                            <AppMenu logo={props.appMenuLogo} />
                        </div>
                    </header>
                    {props.children}
                    <AppRights id={props.id} />
                </div>
            </div>
            <AddresModal />
            <PaymentModal />
            <LoginModal />
        </>
    );
}

export default MainLayout;
