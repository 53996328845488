import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import Video from '../features/video/Video';
import Camera from '../features/camera/Camera';
import { IImage } from '../wrapper';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { useNav } from '../common/useNav';
import { uploadMoreTasksAndImg } from '../common/api/server.api';
import { more_tasks_metadata } from 'src/metadata';
import AbRuler from 'src/features/letters/AbRuler';
import { useClassSizes } from 'src/class/useClassSizes';
import { v4 as uuidv4 } from 'uuid';
import Answers from 'src/features/answers/Answers';
import { useServer } from 'src/common/api/useServer';

export function useMoreTask() {
    const { state, setState } = useContext(AppContext);
    const [play, setPlay] = React.useState(true);
    const { get_action_btn_zoom } = useMediaQueryWrap();
    const { navigateToFolder, navigateToHome } = useNav();
    const { handle_no_token_err } = useServer();

    React.useEffect(() => {
        if (!state.subscribed || state.course_key === '' || !(state.course_key in state.courses)) navigateToHome();
    }, []);

    const get_course = () => {
        if (state.course_key === '') return;
        if (!(state.course_key in state.courses)) return;

        return state.courses[state.course_key];
    };

    const get_task = () => {
        if (state.course_key === '') return;
        if (!(state.course_key in state.courses)) return;

        const curr_course = state.courses[state.course_key];
        return more_tasks_metadata[state.course_key][curr_course.curr_more_task_idx];
    };

    const { get_vid_width, letterZoom } = useClassSizes({ is_more_task: true });

    const on_camera_over = (img: string) => {
        const curr_course = get_course();
        if (!curr_course) return;

        const curr_task_idx = curr_course.curr_more_task_idx;
        const tasks = [...curr_course.more_tasks];
        const curr_task = tasks[curr_task_idx];
        curr_task.progress = 100;

        const folder = [...curr_course.img_folder];
        const mt_meta = more_tasks_metadata[state.course_key][curr_task_idx];
        const iimg: IImage = { img_src: img, title: mt_meta.title, sub_folder: 'moretask', id: uuidv4() };
        folder.push(iimg);

        const courses = { ...state.courses };
        courses[state.course_key].img_folder = folder;
        courses[state.course_key].more_tasks = tasks;

        setState({ ...state, courses: courses, cameraOpen: false });
        const token = localStorage.getItem('ib_token');
        uploadMoreTasksAndImg(token!, tasks, folder[folder.length - 1], state.course_key, handle_no_token_err);
        navigateToFolder();
    };

    const render_camera = () => {
        return <Camera on_camera_over={on_camera_over} btn_zoom={get_action_btn_zoom()} />;
    };

    const render_simple_video = () => {
        const task = get_task();
        if (!task) return;

        return (
            <div style={{ width: get_vid_width }}>
                <Video url={task.video_url!} vid_over_cb={() => null} play={play} setPlay={setPlay} controls={true} />
            </div>
        );
    };

    // prog task for answers
    const [progTaskStopIndex, setProgTaskStopIndex] = React.useState(0);
    const [progTaskIndex, setProgTaskIndex] = React.useState(0);

    const [playProgVid, setPlayProgVid] = React.useState(true);

    const next_prog_task = (ov_vid: boolean) => {
        if (ov_vid) setProgTaskStopIndex(progTaskStopIndex + 1);
        setProgTaskIndex(progTaskIndex + 1);
    };

    const on_answers_prog_over = () => {
        next_prog_task(false);
        setPlayProgVid(true);
    };

    const render_answers_prog = () => {
        const gtask = get_task();
        if (!gtask) return;

        if (!gtask.prog_answers_task) return null;

        const task = gtask.prog_answers_task!.progressVideo!.tasks[progTaskIndex];
        if (task.none) return null;
        return (
            <Answers
                answers={task.answers!.answers}
                correct={task.answers!.correct}
                onCorrect={on_answers_prog_over}
                zoom={letterZoom}
                successSound={true}
            />
        );
    };

    const render_video = () => {
        const gtask = get_task();
        if (!gtask) return;
        if (!gtask.prog_answers_task) return render_simple_video();
        return (
            <div style={{ width: get_vid_width }}>
                <Video
                    url={gtask.video_url!}
                    vid_over_cb={() => null}
                    play={playProgVid}
                    setPlay={setPlayProgVid}
                    controls={false}
                    mulTasks={{
                        stops: gtask.prog_answers_task.progressVideo!.video_stops,
                        task_over_cb: () => next_prog_task(true),
                        curr_stop: progTaskStopIndex,
                    }}
                />
            </div>
        );
    };

    const render_ab_ruler = () => {
        const gtask = get_task();
        if (!gtask) return;
        if (!gtask.static_ab_ruler) return null;
        return <AbRuler zoom={letterZoom} id={gtask.static_ab_ruler} />;
    };

    return {
        render_video,
        render_camera,
        render_ab_ruler,
        title: get_task()?.title ?? '',
        render_answers_prog,
    };
}
