import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { log_event } from '../common';
import './PaymentRedirect.css';

export interface IPaymentRedirectProps {
    isSuccessRedirect: boolean;
}

function PaymentRedirect({ isSuccessRedirect }: IPaymentRedirectProps): JSX.Element {
    const [loading, setLoading] = React.useState(true);
    const [err, setErr] = React.useState('');
    const [success, setSuccess] = React.useState(false);

    const renderError = (err: string) => {
        setErr(err);
        setLoading(false);
    };

    React.useEffect(() => {
        if (!isSuccessRedirect) {
            log_event('payment_fail');
            renderError('שגיאה - התשלום נכשל');
            return;
        }
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const publicToken = urlParams.get('Token');
        if (!publicToken) {
            log_event('payment_fail');
            renderError('שגיאה - אין טוקן ציבורי');
            return;
        }
        log_event('payment_success');
        setSuccess(true);
        setLoading(false);
    }, []);

    return (
        <div className="redirectWrapper">
            {loading && <CircularProgress style={{ width: '80px', color: '#6CBFF7' }} />}
            {err !== '' && <p className="redirectText">{err}</p>}
            {success && (
                <p className="redirectText">
                    תודה! <br></br>
                    קישור עם פרטי ההתחברות נשלח לכתובת המייל שלכם
                </p>
            )}
        </div>
    );
}

export default PaymentRedirect;
