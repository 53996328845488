import React from 'react';
import CircleButton from 'src/common/buttons/CircleButton';
import upSvg from 'src/assets/icons/up.svg';
import downSvg from 'src/assets/icons/down.svg';
import leftSvg from 'src/assets/icons/left.svg';
import rightSvg from 'src/assets/icons/play.svg';
import { ILetterId } from 'src/wrapper';
import './Maze.css';

export interface IMazeLetter {
    btn_size: number;
    onClick: (step: number) => void;
    columns: number;
    mazeType: ILetterId;
}

function MazeNavBtns(props: IMazeLetter): JSX.Element {
    const onUp = () => {
        props.onClick(-props.columns);
    };
    const onDown = () => {
        props.onClick(props.columns);
    };
    const onLeft = () => {
        props.onClick(1);
    };
    const onRight = () => {
        props.onClick(-1);
    };
    const button_color = props.mazeType === 'number' ? 'green' : 'blueFacebook';

    return (
        <div
            className="maze_nav_buttons"
            style={{
                width: props.btn_size * 3,
                height: props.btn_size * 3,
            }}
        >
            <div className="maze_nav_btn_row">
                <CircleButton
                    svg={upSvg}
                    color={button_color}
                    onClick={onUp}
                    zoom={props.btn_size / 85.3}
                    ariaLabel="למעלה"
                />
            </div>
            <div className="maze_nav_btn_row" id={'middle_row'}>
                <CircleButton
                    svg={rightSvg}
                    color={button_color}
                    onClick={onRight}
                    zoom={props.btn_size / 85.3}
                    ariaLabel="ימינה"
                />
                <CircleButton
                    svg={leftSvg}
                    color={button_color}
                    onClick={onLeft}
                    zoom={props.btn_size / 85.3}
                    ariaLabel="שמאלה"
                />
            </div>
            <div className="maze_nav_btn_row">
                <CircleButton
                    svg={downSvg}
                    color={button_color}
                    onClick={onDown}
                    zoom={props.btn_size / 85.3}
                    ariaLabel="למטה"
                />
            </div>
        </div>
    );
}

export default MazeNavBtns;
