import React from 'react';
import { useMediaQueryWrap } from '../useMediaQueryWrap';
import Popover from '@mui/material/Popover';
import { useUserProfile } from './useUserProfile';
import messages from '../../assets/messages_he.json';
import './HeaderAction.css';

export interface IUserProfile {
    anchorEl: null | HTMLElement;
    openUserMenu: boolean;
    closeUserMenu: () => void;
}

function UserProfile({ anchorEl, openUserMenu, closeUserMenu }: IUserProfile): JSX.Element {
    const { is_horiz } = useMediaQueryWrap();

    const { render_logout_item, render_course } = useUserProfile({ closeUserMenu });

    return (
        <>
            <Popover
                id={'user_profile_menu'}
                open={openUserMenu}
                anchorEl={anchorEl}
                onClose={closeUserMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: is_horiz ? 'right' : 'center',
                }}
            >
                <div className="UserMenuWrap">
                    <p className="UserMenuHeader">{messages['user_profile.title']}</p>
                    {render_course('heb1')}
                    {render_course('math1')}
                    {render_logout_item()}
                </div>
            </Popover>
        </>
    );
}

export default UserProfile;
