import React, { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import { AppContext } from '../../AppContext';
import { useMediaQueryWrap } from '../useMediaQueryWrap';
import { layoutId } from '../layouts/MainLayout';
import { log_event } from '../common';
import UserProfile from './UserProfile';
import { deepOrange } from '@mui/material/colors';

import './HeaderAction.css';

interface IHeaderAction {
    id: layoutId;
}

function HeaderAction({ id }: IHeaderAction): JSX.Element {
    const { state } = useContext(AppContext);
    const { isSmallHeader } = useMediaQueryWrap();

    const avatar_txt_color = id.includes('home') ? 'white' : '#0761A6';

    const picture = localStorage.getItem('ib_picture');
    const email = localStorage.getItem('ib_email');
    let name: string = localStorage.getItem('ib_name') ?? ''; // only google login has name
    if (name === '') name = email ? email.split('@')[0] : '';

    const render_avatar = () => {
        if (!state.subscribed) return null;
        return (
            <div className="HeaderAvatarWrap">
                <Avatar
                    alt={name}
                    src={picture ?? undefined}
                    sx={{
                        width: '2em',
                        height: '2em',
                        marginBottom: '0.25em',
                        cursor: 'pointer',
                        bgcolor: deepOrange[500],
                    }}
                    onClick={clickUserMenu}
                >
                    {name[0].toUpperCase()}
                </Avatar>

                {!isSmallHeader && (
                    <div className="HeaderAvatarTextsWrap">
                        <p className="HeaderAvatarNameText" style={{ color: avatar_txt_color }}>
                            היי {name} <br />
                        </p>
                    </div>
                )}
            </div>
        );
    };

    // user profile menu

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openUserMenu = Boolean(anchorEl);

    const clickUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        log_event('user_menu_open');
        setAnchorEl(event.currentTarget);
    };
    const closeUserMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {render_avatar()}
            <UserProfile anchorEl={anchorEl} openUserMenu={openUserMenu} closeUserMenu={closeUserMenu} />
        </>
    );
}

export default HeaderAction;
