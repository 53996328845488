import React from 'react';
import Puzzle from 'src/features/puzzle/Puzzle';
import { ITask } from 'src/wrapper';

export interface IClassPuzzle {
    task?: ITask;
    contentHeight: number;
    onFinish: () => void;
}
export function useClassPuzzle({ task, contentHeight, onFinish }: IClassPuzzle) {
    const render_puzzle = () => {
        if (!task) return null;
        if (!task.puzzle) return null;
        return (
            <Puzzle
                width={window.innerWidth * 0.85}
                height={contentHeight * 0.95}
                onFinish={onFinish}
                lvl={task.puzzle.lvl}
                type={task.puzzle.letter_type}
            />
        );
    };

    const is_puzzle = () => {
        if (!task) return false;
        return task.puzzle !== undefined;
    };

    return {
        render_puzzle,
        is_puzzle,
    };
}
