import React, { useContext } from 'react';
import messages from '../assets/messages_he.json';
import { AppContext } from 'src/AppContext';
import './HomeCard.css';

export interface HomeCardProps {
    image: any;
    title: string;
    onClick: () => void;
}

function HomeCard(card: HomeCardProps): JSX.Element {
    const { state } = useContext(AppContext);
    const user_msg = state.subscribed ? messages['home.classes'] : 'להרשמה';

    return (
        <button className="hp-card" aria-label={card.title} onClick={card.onClick}>
            <div className="hp-card-img">
                <img src={card.image} alt={card.title} />
                <p className="hp-card-img-text">{card.title}</p>
            </div>
            <div className="hp-card-bottom">
                <div className="hp-cad-btn">{user_msg + ' >'}</div>
            </div>
        </button>
    );
}

export default HomeCard;
