import React, { useRef } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import FramedImgWrap from './FramedImgWrap';
import { IImage } from 'src/wrapper';
import CloseIcon from 'src/assets/icons/close_blue.svg';
import MainModal from 'src/common/layouts/MainModal';
import 'swiper/css';
import 'swiper/css/navigation';
import 'src/landing/components/sec-slider/sec-slider.css';

interface FolderSwiperProps {
    iimages: IImage[];
    on_close: () => void;
    on_delete: () => void;
}

function FolderSwiper({ iimages, on_close, on_delete }: FolderSwiperProps): JSX.Element {
    const swiperRef = useRef<any>(null);

    const swiperOptions = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 1,
        loop: true,
        onSwiper: (swiper: any) => {
            swiperRef.current = swiper;
        },
    };

    return (
        <MainModal id={'camera'} open={true} close={on_close} closeIcon={CloseIcon}>
            <div className="slider-wrap relative" style={{ marginBottom: '1.5em', width: '100%' }}>
                <Swiper {...swiperOptions} className="slider">
                    {iimages.map((item, i) => (
                        <SwiperSlide key={i}>
                            <div
                                className="slider-item"
                                style={{
                                    width: 'fit-content',
                                    padding: 0,
                                    boxShadow: '0px 4px 22px rgb(48 44 36 / 20%)',
                                    margin: '0 auto',
                                }}
                            >
                                <FramedImgWrap key={i} item={item} on_delete={on_delete} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="swiper-pagination"></div>
            </div>
        </MainModal>
    );
}

export default FolderSwiper;
