import React from 'react';
import './MemoryCards';

export interface IMemoryCard {
    size: number;
    top: number;
    left: number;
    img: string;
    card_click: (idx: number) => void;
    finished: boolean;
    visable: boolean;
    idx: number;
}

function Card(props: IMemoryCard): JSX.Element {
    const on_click = () => {
        if (props.finished) return;
        props.card_click(props.idx);
    };

    return (
        <div
            className="CardPlaceHolder"
            style={{ top: props.top, left: props.left, width: props.size, height: props.size, cursor: 'pointer' }}
            onClick={on_click}
        >
            <div
                className="CardInner"
                style={{
                    background: 'linear-gradient(0deg, rgba(197,224,181,1) 0%, rgba(87,177,181,1) 100%)',
                }}
            >
                {!props.visable && <p className="CardText">{'?'}</p>}
                {props.visable && <img className="CardImg" src={props.img} alt={'כרטיס זכרון'}></img>}
            </div>
        </div>
    );
}

export default Card;
