import React from 'react';
import { IImage } from '../wrapper';
import FramedImg from './FramedImg';
import shareSvg from '../assets/icons/share.svg';
import deleteSvg from '../assets/icons/delete.svg';
import TextButton from '../common/buttons/TextButton';
import messages from '../assets/messages_he.json';
import { useShare } from '../common/useShare';
import { useFolder } from './useFolder';
import CircularProgress from '@mui/material/CircularProgress';
import './FramedImgWrap.css';

interface FramedImgWrapProps {
    item: IImage;
    on_delete: () => void;
}

function FramedImgWrap(props: FramedImgWrapProps): JSX.Element {
    const { shareFolderImage, durShareImg } = useShare();
    const { deleteFolderImage } = useFolder();

    return (
        <div className="fim_wrapper">
            <p className={'fim_desc'}>{props.item.title}</p>
            <FramedImg id={props.item.id} img_src={props.item.img_src} />
            <div className="fim_btns_wrapper">
                <TextButton
                    onClick={() => {
                        props.on_delete(); // closes the swiper so state can be updated
                        deleteFolderImage(props.item.id); // delete from db and state
                    }}
                    text={messages['folder.delete']}
                    textColor={'#8EA3B8'}
                    fontSize={'1rem'}
                    icon={deleteSvg}
                />
                {durShareImg ? (
                    <CircularProgress style={{ width: '1rem', height: '1rem', color: '#0761A6' }} />
                ) : (
                    <TextButton
                        onClick={() => shareFolderImage(props.item)}
                        text={messages['folder.share']}
                        color={'blue'}
                        textColor={'white'}
                        fontSize={'1rem'}
                        icon={shareSvg}
                    />
                )}
            </div>
        </div>
    );
}

export default FramedImgWrap;
