import React from 'react';
import TextField from '@mui/material/TextField';
import { uploadLead } from 'src/common/api/server.api';
import { log_event } from 'src/common/common';
import Snackbar from '@mui/material/Snackbar';
import './form.css';

function Form() {
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [sent, setSent] = React.useState(false);
    const [formMissWarn, setFormMissWarn] = React.useState(false);

    const sumbit = () => {
        if (sent) return;
        if (name === '' || phone === '' || email === '') {
            setFormMissWarn(true);
            return;
        }
        setSent(true);
        uploadLead(name, email, phone, 'landing_deatails');
        log_event('new_lead_submit');
    };

    return (
        <>
            <div className="lp-form flex justify-center">
                <div className="input-wrap">
                    <TextField
                        disabled={sent}
                        required
                        fullWidth
                        label={'שם מלא'}
                        defaultValue={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="input-wrap">
                    <TextField
                        disabled={sent}
                        required
                        fullWidth
                        type={'number'}
                        label={'טלפון'}
                        defaultValue={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                <div className="input-wrap">
                    <TextField
                        fullWidth
                        disabled={sent}
                        required
                        label={'אימייל'}
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
            </div>
            <button className="bt-primary" onClick={sumbit} aria-label="חיזרו אליי">
                חיזרו אליי
            </button>
            {sent && <div className="text-xl font-bold text-green mt-1">מעולה! קיבלנו את הפניה ונחזור אליך בהקדם</div>}
            <Snackbar
                open={formMissWarn}
                autoHideDuration={2500}
                onClose={() => setFormMissWarn(false)}
                message={'נא מלאו את כל הפרטים'}
            />
        </>
    );
}

export default Form;
