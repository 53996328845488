import React from 'react';
import PaintMaze from 'src/features/paintMaze/PaintMaze';
import { ITask } from 'src/wrapper';

export interface IClassPaintMaze {
    task?: ITask;
    contentHeight: number;
    onFinish: () => void;
}

export function useClassPaintMaze({ task, contentHeight, onFinish }: IClassPaintMaze) {
    const render_paint_maze = () => {
        if (!task) return null;
        if (!task.paintMaze) return null;
        return (
            <PaintMaze
                width={window.innerWidth * 0.85}
                height={contentHeight}
                onFinish={onFinish}
                lvl={task.paintMaze}
            />
        );
    };

    const is_paint_maze = () => {
        if (!task) return false;
        return task.paintMaze !== undefined;
    };

    return {
        render_paint_maze,
        is_paint_maze,
    };
}
