import { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { getPaymentURL, uploadLead } from '../api/server.api';
import { log_event, validateEmail } from '../common';
import { PaymentItem } from 'src/wrapper';

export function usePayment() {
    const { state, setState } = useContext(AppContext);
    const [iframeUrl, setIframeUrl] = useState('');
    const [loadingIFrame, setLoadingIFrame] = useState(false);
    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');

    const OnPayClickFinish = (url?: string) => {
        setLoadingIFrame(false);

        if (!url) {
            alert('שגיאה - לא ניתן לקבל קישור לתשלום');
            return;
        }
        setIframeUrl(url);
    };

    const OnPayClick = () => {
        log_event(`payment_pay_click: ${state.paymentStatus}`);
        const user_email = get_email_to_purchase();
        if (!user_email) return;
        getPaymentURL(user_email, state.paymentStatus, OnPayClickFinish);
        uploadLead('', user_email, '', `payment_started: ${state.paymentStatus}`);
        setLoadingIFrame(true);
    };

    // UI

    const get_email_to_purchase = (): string | null => {
        if (state.paymentStatus === 'on_sale') return localStorage.getItem('ib_email');
        return !!validateEmail(email) && email === email2 ? email : null;
    };

    const toggle_payment_modal = (value: PaymentItem) => {
        setIframeUrl('');
        if (value !== 'close_modal') log_event(`payment_modal_open: ${value}`);

        setState({
            ...state,
            paymentStatus: value,
        });
    };

    return {
        OnPayClick,
        iframeUrl,
        loadingIFrame,
        toggle_payment_modal,
        can_purchase: get_email_to_purchase,
        email,
        setEmail,
        email2,
        setEmail2,
    };
}
