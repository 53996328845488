import { useState } from 'react';

interface audioRecorderInterface {
    onAudioReady: (blob: Blob | null) => void;
    setError: (error: string) => void;
}

export function useAudioRecorderFix({ onAudioReady, setError }: audioRecorderInterface) {
    const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);

    const handleDataAvailable = (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
            onAudioReady(event.data);
        } else {
            onAudioReady(null);
        }
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: { channelCount: 1 } });
            setAudioStream(stream);
            const recorder = new MediaRecorder(stream);
            recorder.addEventListener('dataavailable', handleDataAvailable);
            recorder.start();
            setMediaRecorder(recorder);
        } catch (error: any) {
            setError(error.message ?? 'Error accessing microphone');
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            mediaRecorder.removeEventListener('dataavailable', handleDataAvailable);
        }
        if (audioStream) {
            audioStream.getTracks().forEach((track) => track.stop());
        }
        setAudioStream(null);
        setMediaRecorder(null);
    };

    return { startRecording, stopRecording };
}
