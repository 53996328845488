import React from 'react';
import Video from 'src/features/video/Video';
import videoImage from 'src/assets/images/landing1.webp';
import './sec-meet.css';

const first_vid = 'https://youtu.be/1c5GPGZdWkg';

// interface secMeetProps {
//     zoom_gift: boolean;
//     primBtnText: string;
// }

function SecMeetSchool() {
    const [play, setPlay] = React.useState(false);

    return (
        <section className="sec-meet py-4">
            <div className="container flex items-center justify-between">
                <div className="m-100 w-50 desk-pr-1 text-center">
                    <h1 className="title-42">יש דרך לעזור לילדים להתמודד עם הקשיים של כיתה א׳</h1>

                    <p>שלום ילדים והורים!</p>
                    <p>
                        שמי רוי גקט, ואתם אולי מכירים אותי מהטלוויזיה (רוי הגנן). מניסיוני כמחנך כיתות א׳ וב׳, אני יודע
                        שילדים שמתקשים עם החומרים בכיתה א׳, יתקשו גם עם החומרים של הכיתות הבאות – כי הכל מבוסס על...
                        ובכן, על הבסיס.
                    </p>
                    <br></br>
                    <p>
                        ילד או ילדה שאינם בטוחים דיים בעצמם עלולים לחוות קשיים מכל מיני סוגים, כמו למשל תחושת תסכול,
                        להרגיש שהם ׳פחות מוצלחים׳ וכו׳. ללא בסיס טוב, הקשיים יכולים להגיע גם לכיתות הגבוהות, לבחינות
                        הבגרות ומעבר להן.
                    </p>
                    <br></br>
                    <p className="font-bold">
                        הדרך הכי טובה לעזור להם עכשיו, בתחילת כיתה א׳ - היא לאפשר להם ללמוד מתוך חוויה!
                    </p>
                    <p>
                        זה יכול ממש להפוך ׳כדור שלג חיובי׳, שבו חוויה טובה מובילה לעוד חוויה טובה, ותעודה טובה מובילה
                        לעוד תעודה טובה. כך עד לבגרות, לצבא ולאוניברסיטה.
                    </p>

                    <br></br>
                </div>
                <div className="m-100 w-50 desk-pr-1 text-center">
                    <div className="mx-auto mt-1">
                        <h1 className="title-42">חובה ל-א׳!</h1>
                        <h2 className="text-primary text-xl font-bold">דמיון בקופסא: פתאום הילדים אוהבים ללמוד</h2>
                        <h2 className="text-primary text-xl">הופכים את הילדים לאלופי המספרים, האותיות והצלילים</h2>
                        <br></br>

                        <Video
                            url={first_vid}
                            vid_over_cb={() => null}
                            img={videoImage}
                            play={play}
                            setPlay={setPlay}
                            controls={true}
                            log_click={'landing_1st_vid'}
                        />

                        <br></br>
                        <p className="font-bold">* ניתן לגלוש באתר הקורס דרך המחשב, הטאבלט והטלפון הנייד שלכם.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SecMeetSchool;
