import { more_tasks_metadata as heb1_mt_metadata } from 'src/metadata_heb1';
import { more_tasks_metadata as math1_mt_metadata } from 'src/metadata_math1';
import { classes_metadata as math1_classes_metadata } from 'src/metadata_math1';
import { classes_metadata as heb1_classes_metadata } from 'src/metadata_heb1';
import { games as math1_games } from 'src/metadata_math1';
import { games as heb1_games } from 'src/metadata_heb1';

import { IMoreTaskMetadata, IClassMetadata, IGameGroup } from './wrapper';

export type more_tasks_metadata_dict = {
    [key in string]: IMoreTaskMetadata[];
};

export type classes_metadata_dict = {
    [key in string]: IClassMetadata[];
};

export type classes_main_tasks_indices_dict = {
    [key in string]: number[];
};

export type games_dict = {
    [key in string]: IGameGroup[];
};

export const more_tasks_metadata: more_tasks_metadata_dict = {
    heb1: heb1_mt_metadata,
    math1: math1_mt_metadata,
};

export const classes_metadata: classes_metadata_dict = {
    heb1: heb1_classes_metadata,
    math1: math1_classes_metadata,
};

export const classes_main_tasks_indices: classes_main_tasks_indices_dict = {
    heb1: [5, 3, 18, 22, 14, 12, 17, 13],
    math1: [13, 23, 12, 11, 21, 13, 11, 19],
};

export const games: games_dict = {
    heb1: heb1_games,
    math1: math1_games,
};
