import React, { ReactElement, useState } from 'react';
import Apple from '../../assets/images/apple.svg';
import Eraser from '../../assets/images/Eraser.svg';
import BaloonOrange from '../../assets/images/baloon.svg';
import ArrowDown from '../../assets/images/arrow-down.svg';
import './sec-faq.css';

interface question_answer {
    question: string;
    answer: ReactElement;
}

const data: question_answer[] = [
    {
        question: 'מה יש בכל קורס?',
        answer: (
            <ul>
                <li>סיפור אינטראקטיבי שנכתב על ידי רוי הגנן, מומחה בהכנת ילדים למעבר מהגן לבית הספר</li>
                <li>8 שיעורים ממוקדים המחזקים את הנושאים השונים בתחום המודעות הפונולוגית והתפיסה הכמותית</li>
                <li>כל אחד מסיפורי הקורסים מלווה באנימציה שילדים אוהבים</li>
                <li>אינטראקציה חוויתית ואישית בין הילדים לבין רוי</li>
                <li>חידות ומשימות מגוונות הכוללות ציור, כתיבה, צילום, שימוש במיקרופון ועוד</li>
                <li>איזור אישי לשמירת משימות ועבודות</li>
                <li>בונוס - לכל קורס עמוד משחקים מיוחד לתרגול המיומנויות החשובות ביותר</li>
            </ul>
        ),
    },
    {
        question: 'למי הקורסים מתאימים?',
        answer: (
            <p>
                הקורסים מתאימים לילדים הנמצאים לפני ואחרי המעבר לכיתה א׳. כל ילד הוא שונה ולכל ילד קצב משלו. לכן, המערכת
                מאפשרת להריץ אחורה וקדימה את חלקי השיעורים כמה פעמים שרוצים ללא הגבלה. כך, יכול כל ילד על פי הקצב האישי
                שלו להתקדם בסיפורי הקורסים.
            </p>
        ),
    },
    {
        question: 'לכמה זמן הקורס פתוח לאחר ההרשמה?',
        answer: (
            <p>
                ההרשמה לכל אחד מהקורסים מקנה <span className="font-bold">גישה לכל החיים! </span>
                אז אם יש אחים קטנים בבית, גם הם יוכלו להנות מהקורסים בהמשך.
            </p>
        ),
    },
    {
        question: 'מה מקבלים הילדים בסיום?',
        answer: (
            <ul>
                <li>
                    כלים פרקטיים שילוו את הילדים שנים קדימה ויחזקו מאד את בטחונם העצמי לקראת כיתה א׳ (ויחסכו גם מורה
                    פרטי או שניים)
                </li>
                <li>
                    המשכיות - באתר מתעדכנות משימות נוספות באופן שוטף ובאמצעותן ימשיכו הילדים ליצור, לכתוב ולצלם גם אחרי
                    שיסיימו כל קורס
                </li>
                <li>תעודת סיום קורס וסט מדבקות מיוחדות לכיתה א׳ שמגיעות בדואר לביתם של מסיימי כל קורס</li>
            </ul>
        ),
    },
    {
        question: 'אילו כישורים מפתחים בקורס האותיות והצלילים?',
        answer: (
            <ul>
                <li>הכרה וזיהוי סדר הא׳ ב׳ בדפוס ובעל פה</li>
                <li>היכולת לפרק מילה לצלילים</li>
                <li>זיהוי צלילים פותחים וסוגרים במילים והבנת הקשר ביניהם לבין האותיות הגרפיות</li>
                <li>היכולת להרכיב מילה מצלילים</li>
                <li>היכולת לחרוז מילים ולהמציא חרוזים</li>
                <li>היכולת למנות כמה צלילים יש בכל מילה</li>
                <li>היכרות עם האותיות הסופיות</li>
                <li>פיתוח חשיבה יצירתית</li>
                <li>פיתוח המוטוריקה העדינה</li>
                <li>הכרת כיווני הכתיבה</li>
            </ul>
        ),
    },
    {
        question: 'אילו כישורים מפתחים בקורס החשבון והמספרים?',
        answer: (
            <ul>
                <li>זיהוי והכרת המספרים הכתובים בעשרת הראשונה והשנייה</li>
                <li>חיזוק ופיתוח יכולות הספירה והמנייה</li>
                <li>התאמת מספר לכמות</li>
                <li>הכרת המספרים הזוגיים והאי-זוגיים</li>
                <li>השוואת קבוצות והכרת סימני גדול\קטן\שווה</li>
                <li>הכרה ותרגול מושגי מרחב: לפני ואחרי</li>
                <li>פיתוח יכולות חיבור וחיסור בעשרת הראשונה והשנייה</li>
                <li>הכרת הצורות ההנדסיות</li>
                <li>פיתוח חשיבה יצירתית</li>
                <li>פיתוח המוטוריקה העדינה</li>
            </ul>
        ),
    },
    {
        question: 'האם קיימת דרך לקבל משוב על התקדמות הלמידה?',
        answer: (
            <p>
                בהחלט! אני מזמין את הילדים וההורים לשתף את המשימות שמבצעים הילדים, ולקבל פידבק אישי על כל שיתוף. ניתן
                לעשות זאת בקלות דרך האיזור האישי באתר - ׳תיק העבודות שלי׳, בלחיצה על כפתור השיתוף. בנוסף לכך, בסיום כל
                קורס נשלחת תעודת סיום בדואר (ועוד הפתעה קטנה לילדים האלופים).
            </p>
        ),
    },
    {
        question: 'האם הילדים עובדים באתר באופן עצמאי?',
        answer: <p>המערכת קלה ונוחה. הילדים לומדים לתפעל אותה באופן עצמאי במהירות!</p>,
    },
    {
        question: 'האם קיימת אפשרות להתחבר לאתר ממספר מכשירים שונים?',
        answer: (
            <p>
                ניתן להתחבר לאתר הקורס מכל מכשיר דיגיטלי! האתר מותאם למחשב, לטאבלט ולטלפון הנייד. אפשר לגלוש מכל מקום
                ובכל זמן.
            </p>
        ),
    },
    {
        question: 'מה קורה כשמסיימים את הקורסים?',
        answer: (
            <p>
                לאחר שילדכם יסיימו, הם יוכלו להמשיך ללמוד בדרך חוויתית! עמוד ׳המשימות הנוספות׳ מתעדכן באופן שוטף ומלווה
                את הילדים במשך כל שנת הלימודים! נוסף לכך, הילדים יכולים לשחק באופן חופשי בעמודי המשחקים המיוחדים!
            </p>
        ),
    },
    {
        question: 'מה היא מודעות פונולוגית?',
        answer: (
            <p>
                מודעות פונולוגית מבטאת ידע על צלילים והיא מורכבת ממספר מיומנויות הנתמכות זו בזו. הבנה כי המילה השלמה
                מורכבת מצלילים נפרדים, המיוצגים על ידי סמלים גרפיים מוסכמים (אותיות), הינה הבסיס להצלחת תהליכי קריאה
                וכתיבה נכונים. חשוב מאד להדגיש כי ישנן מספר מיומנויות המשתלבות זו בזו והן עקרוניות ומשמעותיות ביותר
                לקידום המודעות הפונולוגית. חלקן נרכשות באופן טבעי ואת חלקן מומלץ (מאד) לחזק בצורה ממוקדת.
            </p>
        ),
    },
];

function SecFAQ() {
    const [expandedRows, setExpandedRows] = useState([] as number[]);

    const toggleRow = (index: number) => {
        const newExpandedRows = [...expandedRows];
        if (newExpandedRows.includes(index)) {
            newExpandedRows.splice(newExpandedRows.indexOf(index), 1);
        } else {
            newExpandedRows.push(index);
        }
        setExpandedRows(newExpandedRows);
    };

    const isRowExpanded = (index: number) => {
        return expandedRows.includes(index);
    };

    return (
        <section className="sec-faq py-4 relative">
            <div id="SecFAQ" className="anchor"></div>
            <div className="container relative">
                <div className="faq-images only-desk">
                    <img className="" src={Apple} alt="Apple" />
                    <img className="" src={Eraser} alt="Eraser" />
                    <img className="" src={BaloonOrange} alt="baloon-orange" />
                </div>
                <h2 className="title-42 text-center">שאלות ותשובות</h2>
                <div className="all-faq mx-auto">
                    {data.map((qa, index) => (
                        <div className={`faq-row ${isRowExpanded(index) && 'active'}`} key={index}>
                            <button
                                onClick={() => toggleRow(index)}
                                className="question w-100 flex items-center justify-between"
                            >
                                <h2 className="text-xl font-medium text-blue-dark flex-1">{qa.question}</h2>
                                <img src={ArrowDown} alt="" />
                            </button>
                            <div className="answer" aria-expanded={isRowExpanded(index)}>
                                <div className="text-base">{qa.answer}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default SecFAQ;
