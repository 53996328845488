import React, { useEffect } from 'react';
import { useMediaQueryWrap } from '../../common/useMediaQueryWrap';
import Turnscreen from '../../common/layouts/TurnScreen';
import WordDragPlace from './WordDragPlace';
import WordDragItem from './WordDragItem';
import { useWordDragConfig } from './WordDragConfig';
import './WordDrag.css';

const columns = 6;
const rows = 5;
const max_letter_len = 4;
const image_rez = 1.5; // image takes 3 cols in width, 2 rows in height
const image_height_ratio = 2 / 5; // 2 first rows
const image_x_semi_idx = 3.5;

export interface IWordDrag {
    width: number;
    height: number;
    onFinish: () => void;
    word: string;
}

function WordDrag({ width, height, onFinish, word }: IWordDrag): JSX.Element {
    const itemRef: React.RefObject<HTMLDivElement> = React.createRef();
    const [bounds, setBounds] = React.useState<undefined | DOMRect>(undefined);
    const { is_horiz, should_turn_screen } = useMediaQueryWrap();
    const [correct_amount, set_correct_amount] = React.useState(0);
    const { games } = useWordDragConfig();
    const [all_letters, set_all_letters] = React.useState(games[word].letters);

    React.useEffect(() => {
        set_all_letters(games[word].letters);
        set_correct_amount(0);
    }, [word]);

    useEffect(() => {
        if (!itemRef.current) return;
        const bounds = itemRef.current.getBoundingClientRect();
        setBounds(bounds);
        set_correct_amount(0);
    }, [is_horiz, height, width]);

    const col_step = width / columns;
    const row_step = height / rows;

    const render_size = Math.min(col_step, row_step);
    const min_step = Math.min(col_step, row_step);

    const flat_idx_to_pos = (idx: number) => {
        const x_idx = idx % columns;
        const y_idx = Math.floor(idx / columns);

        let left = width - min_step * (x_idx + 1);
        let top = height - min_step * (rows - y_idx);

        const spare_size = Math.max(col_step, row_step) - min_step;

        if (row_step > col_step) top -= (spare_size * (columns - 1)) / 4;
        else left -= (spare_size * (columns - 1)) / 2;

        return {
            left,
            top,
        };
    };

    const pos_to_cell_idx = (left: number, top: number) => {
        // Not perfect, but works

        const spare_size = Math.max(col_step, row_step) - min_step;

        if (row_step > col_step) top += (spare_size * (columns - 1)) / 4;
        else left += (spare_size * (columns - 1)) / 2;

        const x_idx = Math.floor((width - left) / min_step);
        const y_idx = rows - 2 - Math.floor((height - top) / min_step);

        const idx = y_idx * columns + x_idx;
        return idx;
    };

    const image_pos = flat_idx_to_pos(image_x_semi_idx);

    const letter_finish_cb = () => {
        const new_amount = correct_amount + 1;
        set_correct_amount(new_amount);
        if (new_amount == max_letter_len) onFinish();
    };

    const place_holders_cells = all_letters.slice(0, 4).map((_, idx) => (rows - 1) * columns + idx + 1);
    const place_holders = place_holders_cells.map((cell) => {
        return flat_idx_to_pos(cell);
    });

    const render = () => {
        if (should_turn_screen()) return <Turnscreen />;

        return (
            <div ref={itemRef} className="word_drag_letter_container" style={{ width: width, height: height }}>
                {place_holders.map((coord, index) => (
                    <WordDragPlace key={`${word}_${index}`} size={render_size} top={coord.top} left={coord.left} />
                ))}
                {all_letters.map((letter, index) => {
                    const { left, top } = flat_idx_to_pos(letter.cell);
                    return (
                        <WordDragItem
                            pos_to_cell_idx={pos_to_cell_idx}
                            bounds={bounds!}
                            key={`${word}_${index}`}
                            text={letter.letter}
                            size={render_size}
                            has_target={index < 4}
                            finish_cb={letter_finish_cb}
                            top={top}
                            left={left}
                            target_cell={index < place_holders.length ? place_holders_cells[index] : 0}
                            target_left={index < place_holders.length ? place_holders[index].left : 0}
                            target_top={index < place_holders.length ? place_holders[index].top : 0}
                        />
                    );
                })}
                <img
                    className="word_drag_image_container"
                    src={games[word].image}
                    width={image_height_ratio * height * image_rez}
                    height={image_height_ratio * height}
                    style={{ left: image_pos.left }}
                    alt={word}
                ></img>
            </div>
        );
    };

    return render();
}

export default WordDrag;
