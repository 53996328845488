import React from 'react';
import animationData from 'src/assets/anim/success.json';
import Lottie from 'lottie-react';
import { useNav } from 'src/common/useNav';

function GameSuccess(): JSX.Element {
    const { navigateToGames } = useNav();

    return (
        <div
            onClick={() => navigateToGames()}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                zIndex: 5,
                position: 'absolute',
                top: '45px',
                right: 0,
            }}
        >
            <Lottie loop={true} autoPlay animationData={animationData} />
        </div>
    );
}

export default GameSuccess;
