import React from 'react';
import HomeCard from 'src/home_page/HomeCard';
import rudyImg from 'src/assets/images/card-rudy.svg';
import billyImg from 'src/assets/images/card-billy.svg';
import rudiBillyImg from 'src/assets/images/card-billy-rudi.svg';
import messages from 'src/assets/messages_he.json';
import { usePayment } from 'src/common/payment/usePayment';
import './sec-best.css';

function SecSell() {
    const { toggle_payment_modal } = usePayment();

    return (
        <section className="sec-best  py-4 relative">
            <div id="SecSell" className="anchor"></div>
            <div className="container relative ">
                <div className="container flex items-center justify-between">
                    <div className="m-100 w-50 desk-pl-1">
                        <h2 className="title-42">מה בא לילדים שלכם יותר?</h2>
                        <div className="top_cards_wrapper">
                            <HomeCard
                                image={rudyImg}
                                title={messages['course.heb1_card']}
                                onClick={() => toggle_payment_modal('heb1')}
                            />
                            <s>מחיר מלא: 697 ₪</s>
                            <p>המחיר היום, לכל החיים: 297 ₪ בלבד!</p>
                            <br></br>
                            <HomeCard
                                image={billyImg}
                                title={messages['course.math1_card']}
                                onClick={() => toggle_payment_modal('math1')}
                            />
                            <s>מחיר מלא: 697 ₪</s>
                            <p>המחיר היום, לכל החיים: 297 ₪ בלבד!</p>
                        </div>
                    </div>
                    <div className="m-100 w-50 desk-pr-1">
                        <div className="top_cards_wrapper">
                            <HomeCard
                                image={rudiBillyImg}
                                title={'צמד חמד חובה ל-א׳ (שני קורסים)'}
                                onClick={() => toggle_payment_modal('all')}
                            />
                            <s>מחיר מלא: 1394 ₪</s>
                            <p>המחיר היום, לכל החיים: 397 ₪ בלבד!</p>
                            <p>*הצעה לזמן מוגבל</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SecSell;
