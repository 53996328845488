import React from 'react';
import coinSvg from 'src/assets/images/cash_game/coin.svg';
import './CashGame.css';

export interface ICashGameCoin {
    amount: number;
    on_click: (amount: number) => void;
    font_size: string;
}

function CashGameCoin({ amount, on_click, font_size }: ICashGameCoin): JSX.Element {
    return (
        <div className="cash_game_coin_wrap">
            <div className="cash_game_coin_img_wrap">
                <img src={coinSvg} style={{ height: '100%' }} alt={'coin'} />
                <p className="cash_game_coin_amount" style={{ fontSize: font_size }}>
                    ₪{amount}
                </p>
            </div>
            <div className="cash_game_coin_wrap_actions">
                <button
                    className="cash_game_coin_action_btn"
                    style={{ fontSize: font_size, borderTopRightRadius: '60px', borderBottomRightRadius: '60px' }}
                    onClick={() => on_click(-amount)}
                    aria-label={`הורדת ${amount}`}
                >
                    {'-'}
                </button>
                <button
                    className="cash_game_coin_action_btn"
                    style={{
                        fontSize: font_size,
                        background: '#6FCF4D',
                        color: 'white',
                        borderTopLeftRadius: '60px',
                        borderBottomLeftRadius: '60px',
                        borderRight: 'none',
                    }}
                    onClick={() => on_click(amount)}
                    aria-label={`העלאת ${amount}`}
                >
                    {'+'}
                </button>
            </div>
        </div>
    );
}

export default CashGameCoin;
