import { IWordFixMatch } from 'src/wrapper';

export function useABFix() {
    const check_ab_fix = (abFix: IWordFixMatch, match: string): boolean => {
        console.log(abFix, match);

        if (abFix.type === 'prefix' && match.charAt(0) === abFix.ab) return true;
        if (abFix.type === 'suffix' && match.charAt(match.length - 1) === abFix.ab) return true;

        return false;
    };

    return { check_ab_fix };
}
