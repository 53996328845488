import React from 'react';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import rabitSvg from '../assets/drawings/Rabbit3.svg';
import penSvg from '../assets/drawings/pen5.svg';
import paintSvg from '../assets/drawings/paint2.svg';
import billySvg from 'src/assets/images/billy.svg';
import palleteSvg from '../assets/drawings/palate.svg';
import eraserSvg from '../assets/drawings/Eraser.svg';
import waveSvg from '../assets/drawings/wave-pink.svg';
import { useNav } from '../common/useNav';
import { uploadValue } from '../common/api/server.api';
import { log_event } from '../common/common';
import { more_tasks_metadata } from 'src/metadata';
import { useServer } from 'src/common/api/useServer';
import { usePayment } from 'src/common/payment/usePayment';

export function useMoreTasks() {
    const { state, setState } = useContext(AppContext);
    const { navigateToaMoreTask, navigateToHome } = useNav();
    const { getMQid, render_float_anim } = useMediaQueryWrap();
    const rid = getMQid();
    const { handle_no_token_err } = useServer();
    const { toggle_payment_modal } = usePayment();

    React.useEffect(() => {
        if (state.course_key === '') navigateToHome();
    }, []);

    const selectMoreTask = (task_idx: number) => {
        if (state.course_key != '' && !(state.course_key in state.courses)) {
            log_event(`moretasks_payment_modal_${state.course_key}`);
            const payment_item = state.subscribed ? 'on_sale' : state.course_key;
            toggle_payment_modal(payment_item);
            return;
        }

        const token = localStorage.getItem('ib_token');
        if (!token) return;

        const courses = { ...state.courses };
        courses[state.course_key].curr_more_task_idx = task_idx;

        setState({ ...state, courses: courses });
        uploadValue(token, 'curr_more_task_idx', task_idx, state.course_key, handle_no_token_err);
        log_event(`more_task_select_${task_idx}`, state.course_key);
        navigateToaMoreTask();
    };

    const is_locked = (idx: number) => {
        if (!(state.course_key in state.courses)) return true;
        if (!more_tasks_metadata[state.course_key][idx].video_url) return true;
        return false;
    };

    const render_drawings = () => {
        if (rid === 'mobile' || !render_float_anim) return null;
        return (
            <>
                {state.course_key === 'heb1' && <img src={rabitSvg} className="mtRabbit float" alt="animation" />}
                {state.course_key === 'math1' && <img src={billySvg} className="mtRabbit float" alt="animation" />}
                <img src={penSvg} className="mtPen float float-delay" alt="pen animation" />
                <img src={palleteSvg} className="mtPallete float float-delay" alt="pallete animation" />
                <img src={eraserSvg} className="mtEraser float float" alt="eraser animation" />
                <img src={paintSvg} className="mtPaint float float-delay" alt="paint animation" />
                <img src={waveSvg} className="mtWave" alt="wave animation" />
            </>
        );
    };

    return { render_drawings, selectMoreTask, is_locked };
}
