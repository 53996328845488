import { base_storage_path } from 'src/metadata_math1';
export const game_image_path = base_storage_path + '/game_cash';

interface ICashGameObject {
    image: any;
    price: number;
}

interface ICashGameDict {
    [key: string]: ICashGameObject;
}

export function useCashGameConfig() {
    const games: ICashGameDict = {
        '3': {
            image: `${game_image_path}/3.webp`,
            price: 3,
        },
        '4': {
            image: `${game_image_path}/4.webp`,
            price: 4,
        },
        '6': {
            image: `${game_image_path}/6.webp`,
            price: 6,
        },
        '6_2': {
            image: `${game_image_path}/6_2.webp`,
            price: 6,
        },
        '7': {
            image: `${game_image_path}/7.webp`,
            price: 7,
        },
        '8': {
            image: `${game_image_path}/8.webp`,
            price: 8,
        },
        '9': {
            image: `${game_image_path}/9.webp`,
            price: 9,
        },
        '9_2': {
            image: `${game_image_path}/9_2.webp`,
            price: 9,
        },
        '10': {
            image: `${game_image_path}/10.webp`,
            price: 10,
        },
        '11': {
            image: `${game_image_path}/11.webp`,
            price: 11,
        },
        '11_2': {
            image: `${game_image_path}/11_2.webp`,
            price: 11,
        },
        '12': {
            image: `${game_image_path}/12.webp`,
            price: 12,
        },
        '12_2': {
            image: `${game_image_path}/12_2.webp`,
            price: 12,
        },
        '12_3': {
            image: `${game_image_path}/12_3.webp`,
            price: 12,
        },
        '13': {
            image: `${game_image_path}/13.webp`,
            price: 13,
        },
        '13_2': {
            image: `${game_image_path}/13.webp`,
            price: 13,
        },
        '14': {
            image: `${game_image_path}/14.webp`,
            price: 14,
        },
        '15': {
            image: `${game_image_path}/15.webp`,
            price: 15,
        },
        '16': {
            image: `${game_image_path}/16.webp`,
            price: 16,
        },
        '16_2': {
            image: `${game_image_path}/16_2.webp`,
            price: 16,
        },
        '17': {
            image: `${game_image_path}/17.webp`,
            price: 17,
        },
        '18': {
            image: `${game_image_path}/18.webp`,
            price: 18,
        },
        '19': {
            image: `${game_image_path}/19.webp`,
            price: 19,
        },
        '19_2': {
            image: `${game_image_path}/19.webp`,
            price: 19,
        },
        '23': {
            image: `${game_image_path}/23.webp`,
            price: 23,
        },
        '24': {
            image: `${game_image_path}/24.webp`,
            price: 24,
        },
        '25': {
            image: `${game_image_path}/25.webp`,
            price: 25,
        },
        '26': {
            image: `${game_image_path}/26.webp`,
            price: 26,
        },
        '27': {
            image: `${game_image_path}/27.webp`,
            price: 27,
        },
        '28': {
            image: `${game_image_path}/28.webp`,
            price: 28,
        },
        '29': {
            image: `${game_image_path}/29.webp`,
            price: 29,
        },
        '30': {
            image: `${game_image_path}/30.webp`,
            price: 30,
        },
        '11_l2': {
            image: `${game_image_path}/11_l2.webp`,
            price: 11,
        },
        '12_l2': {
            image: `${game_image_path}/12_l2.webp`,
            price: 12,
        },
        '13_l2': {
            image: `${game_image_path}/13_l2.webp`,
            price: 13,
        },
        '14_l2': {
            image: `${game_image_path}/14_l2.webp`,
            price: 14,
        },
        '14_2_l2': {
            image: `${game_image_path}/14_2_l2.webp`,
            price: 14,
        },
        '15_l2': {
            image: `${game_image_path}/15_l2.webp`,
            price: 15,
        },
        '16_l2': {
            image: `${game_image_path}/16_l2.webp`,
            price: 16,
        },
        '17_l2': {
            image: `${game_image_path}/17_l2.webp`,
            price: 17,
        },
        '17_2_l2': {
            image: `${game_image_path}/17_2_l2.webp`,
            price: 17,
        },
        '18_l2': {
            image: `${game_image_path}/18_l2.webp`,
            price: 18,
        },
        '19_l2': {
            image: `${game_image_path}/19_l2.webp`,
            price: 19,
        },
    };
    return { games };
}
