import { IAnswerTask, IClassMetadata, IGameGroup, IMoreTaskMetadata, ITask } from './wrapper';
export const base_storage_path = 'https://storage.googleapis.com/kalef-app-379711.appspot.com';
export const base_storage_path_class = base_storage_path + '/class';

const ab_prog_task1: ITask = {
    video_url: base_storage_path_class + '/R1_2_2_new.mp4',
    progressVideo: {
        ab_ruler: true,
        video_stops: [5.5, 8, 10.2, 11.8, 14, 16.2, 18.2, 20, 21.9, 23.5, 25],
        tasks: [
            { none: true },
            { micTask: { ab_match: 'א' } },
            { ab_highlight: 'ב' },
            { micTask: { ab_match: 'ג' } },
            { ab_highlight: 'ד' },
            { micTask: { ab_match: 'ה' } },
            { ab_highlight: 'ו' },
            { micTask: { ab_match: 'ז' } },
            { ab_highlight: 'ח' },
            { micTask: { ab_match: 'ט' } },
            { ab_highlight: 'י' },
            { micTask: { ab_match: 'כ' } },
            { ab_highlight: 'ל' },
            { micTask: { ab_match: 'מ' } },
            { ab_highlight: 'נ' },
            { micTask: { ab_match: 'ס' } },
            { ab_highlight: 'ע' },
            { micTask: { ab_match: 'פ' } },
            { ab_highlight: 'צ' },
            { micTask: { ab_match: 'ק' } },
            { ab_highlight: 'ר' },
            { micTask: { ab_match: 'ש' } },
            { ab_highlight: 'ת' },
        ],
    },
};

import c1 from 'src/assets/list_images/classroom/1.webp';
import c2 from 'src/assets/list_images/classroom/2.webp';
import c3 from 'src/assets/list_images/classroom/3.webp';
import c4 from 'src/assets/list_images/classroom/4.webp';
import c5 from 'src/assets/list_images/classroom/5.webp';
import c6 from 'src/assets/list_images/classroom/6.webp';
import c7 from 'src/assets/list_images/classroom/7.webp';
import c8 from 'src/assets/list_images/classroom/8.webp';

import mt1 from 'src/assets/list_images/more_tasks/1.webp';
import mt2 from 'src/assets/list_images/more_tasks/2.webp';
import mt3 from 'src/assets/list_images/more_tasks/3.webp';
import mt4 from 'src/assets/list_images/more_tasks/4.webp';
import mt5 from 'src/assets/list_images/more_tasks/5.webp';
import mt6 from 'src/assets/list_images/more_tasks/6.webp';
import mt7 from 'src/assets/list_images/more_tasks/7.webp';
import mt8 from 'src/assets/list_images/more_tasks/8.webp';
import mt9 from 'src/assets/list_images/more_tasks/9.webp';

import g_maze from 'src/assets/list_images/games/maze.webp';
import g_ship from 'src/assets/list_images/games/ship.webp';
import g_book_sound from 'src/assets/list_images/games/sound_book.webp';
import g_compose from 'src/assets/list_images/games/compose_word.webp';
import g_compose_hard from 'src/assets/list_images/games/compose_word_hard.webp';
import g_word_drag_easy from 'src/assets/list_images/games/word_drag_easy.webp';
import g_word_drag_med from 'src/assets/list_images/games/word_drag_med.webp';
import g_word_drag_hard from 'src/assets/list_images/games/word_drag_hard.webp';
import g_memory from 'src/assets/list_images/games/memory1.webp';

// fixed size - 8
export const classes_metadata: IClassMetadata[] = [
    {
        tasks: [
            {
                video_url: base_storage_path_class + '/R1_1.mp4',
                pressNext: true,
                pressBack: false,
            },
            {
                video_url: base_storage_path_class + '/R1_2_1.mp4',
                pressNext: true,
            },
            {
                ...ab_prog_task1,
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R1_4.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R1_5.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R1_6.mp4',
                pressNext: false,
                ab_select: 1,
            },
            {
                video_url: base_storage_path_class + '/R1_7.mp4',
                pressNext: true,
                ab_select_hold: true,
            },
            {
                video_url: base_storage_path_class + '/R1_8.mp4',
                cameraTask: { finish_to: 'none' },
                render_time: 110,
                pressNext: false,
                ab_select_hold: true,
            },
            {
                video_url: base_storage_path_class + '/R1_9.mp4',
                ab_select_hold: false,
                ab_select_finish: true,
                pressNext: true,
            },
            {
                video_url: '',
                maze: { lvl: 1, letter_type: 'letter' },
                finish_to_folder: true,
            },
        ],
        materials: ['צבעים', 'דפים'],
        time_len: '30',
        title: 'שיעור ראשון',
        img: c1,
    },
    {
        title: 'שיעור שני',
        materials: ['צבעים', 'דפים', 'מספריים', 'דבק סטיק', 'עיתונים'],
        time_len: '30',
        tasks: [
            {
                video_url: base_storage_path_class + '/R2_1.mp4',
                pressNext: true,
                pressBack: false,
            },
            {
                video_url: base_storage_path_class + '/R2_2.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R2_3.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R2_4.mp4',
                pressNext: false,
                ab_select: 7,
            },
            {
                video_url: base_storage_path_class + '/R2_5.mp4',
                pressNext: true,
                ab_select_hold: true,
            },
            {
                video_url: base_storage_path_class + '/R2_6.mp4',
                pressNext: true,
                pressNextDur: true,
                ab_select_hold: true,
            },
            {
                video_url: base_storage_path_class + '/R2_7.mp4',
                pressNext: true,
                ab_select_hold: true,
            },
            {
                video_url: base_storage_path_class + '/R2_8.mp4',
                pressNext: false,
                cameraTask: { finish_to: 'none' },
                ab_select_hold: true,
            },
            {
                video_url: base_storage_path_class + '/R2_9.mp4',
                pressNext: true,
                ab_select_hold: false,
                ab_select_finish: true,
            },
            {
                video_url: base_storage_path_class + '/R2_10.mp4',
                pressNext: false,
                ab_select_finish: true,
                answers: {
                    answers: ['2', '3', '4'],
                    correct: '3',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R2_11.mp4',
                pressNext: true,
                ab_select_finish: true,
            },
            {
                video_url: base_storage_path_class + '/R2_12.mp4',
                pressNext: true,
                ab_select_finish: true,
            },
            {
                video_url: '',
                ab_select_finish: true,
                puzzle: { lvl: 1, letter_type: 'letter' },
            },
            {
                video_url: base_storage_path_class + '/R2_13.mp4',
                pressNext: true,
                ab_select_finish: true,
                finish_to_classroom: true,
            },
        ],
        img: c2,
    },
    {
        title: 'שיעור שלישי',
        materials: ['צבעים', 'דפים', 'מספריים', 'דבק סטיק', 'עיתונים'],
        time_len: '25',
        tasks: [
            {
                video_url: base_storage_path_class + '/E3_1.mp4',
                pressNext: false,
                pressBack: false,
                answers: {
                    answers: ['1', '2', '3'],
                    correct: '2',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/E3_2.mp4',
                pressNext: false,
                answers: {
                    answers: ['2', '4', '3'],
                    correct: '3',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/E3_3.mp4',
                pressNext: false,
                answers: {
                    answers: ['2', '1', '3'],
                    correct: '2',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/E3_4.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/E3_5.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/E3_6.mp4',
                pressNext: false,
                micTask: { ab_match: 'ס' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/E3_7.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R3_7_1.mp4',
                pressNext: false,
                micTask: { ab_match: 'מ' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R3_7_2.mp4',
                pressNext: false,
                micTask: { ab_match: 'נ' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R3_7_3.mp4',
                pressNext: false,
                micTask: { ab_match: 'א' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/E3_89.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/E3_10.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/E3_11.mp4',
                pressNext: false,
                micTask: { word_match: ['משקפיים'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/E3_12.mp4',
                pressNext: false,
                micTask: { word_match: ['מזלג'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/E3_13.mp4',
                pressNext: false,
                micTask: { word_match: ['מורה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/E3_14.mp4',
                pressNext: false,
                micTask: { word_match: ['מכונית'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/E3_15.mp4',
                pressNext: false,
                micTask: { word_match: ['מוסיקה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/E3_16.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/E3_17.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/E3_18.mp4',
                cameraTask: { finish_to: 'none' },
                render_time: 180,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/E3_19.mp4',
                pressNext: true,
                finish_to_classroom: true,
            },
        ],
        img: c3,
    },
    {
        title: 'שיעור רביעי',
        materials: ['צבעים', 'דפים'],
        time_len: '30',
        tasks: [
            {
                video_url: base_storage_path_class + '/R4_1.mp4',
                answers: {
                    answers: ['2', '3', '4'],
                    correct: '4',
                },
                successSound: true,
                pressNext: false,
                pressBack: false,
            },
            {
                video_url: base_storage_path_class + '/R4_2.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R4_2_1.mp4',
                pressNext: false,
                micTask: { word_match: ['מצלמה', 'שולחן', 'פעמון', 'מחשב'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_2_2.mp4',
                pressNext: false,
                micTask: { word_match: ['מצלמה', 'שולחן', 'פעמון', 'מחשב'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_3.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R4_4.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R4_5.mp4',
                pressNext: false,
                micTask: { word_match: ['ספסל'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_6.mp4',
                pressNext: false,
                micTask: { word_match: ['סוכריה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_7.mp4',
                pressNext: false,
                micTask: { word_match: ['סירה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_8.mp4',
                pressNext: false,
                micTask: { word_match: ['ספר', 'ספל'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_9.mp4',
                pressNext: false,
                micTask: { word_match: ['סוניק'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_10.mp4',
                pressNext: false,
                micTask: { word_match: ['שלום'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_11.mp4',
                pressNext: false,
                micTask: { word_match: ['בחנות'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_12.mp4',
                pressNext: false,
                micTask: { word_match: ['גלידריה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_13.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R4_14.mp4',
                pressNext: false,
                micTask: { word_match: ['לימון'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_15.mp4',
                pressNext: false,
                micTask: { word_match: ['שוקולד'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_16.mp4',
                pressNext: false,
                micTask: { word_match: ['וניל'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R4_17.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R4_18.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R4_19.mp4',
                answers: {
                    answers: ['5', '4', '3'],
                    correct: '5',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/R4_20.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R4_21.mp4',
                pressNext: true,
                progressVideo: {
                    video_stops: [55.21, 74.4, 85.2, 94.34, 109.35],
                    ab_ruler: false,
                    tasks: [
                        { none: true },
                        { micTask: { ab_match: 'מ' } },
                        { none: true },
                        { micTask: { ab_match: 'ח' } },
                        { none: true },
                        { micTask: { ab_match: 'ב' } },
                        { none: true },
                        { micTask: { ab_match: 'ר' } },
                        { none: true },
                        { micTask: { ab_match: 'ת' } },
                        { none: true },
                    ],
                },
            },
            {
                video_url: base_storage_path_class + '/R4_22.mp4',
                pressNext: false,
                render_time: 17,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        img: c4,
    },
    {
        title: 'שיעור חמישי',
        materials: ['צבעים', 'דפים'],
        time_len: '30',
        tasks: [
            {
                video_url: base_storage_path_class + '/R5_1.mp4',
                pressNext: false,
                pressBack: false,
                micTask: { word_match: ['מזלטוב'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R5_2.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R5_3.mp4',
                pressNext: false,
                micTask: { word_match: ['בריאות'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R5_4.mp4',
                pressNext: false,
                micTask: { word_match: ['אהבה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R5_5.mp4',
                pressNext: false,
                micTask: { word_match: ['חברים'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R5_6.mp4',
                answers: {
                    answers: ['1', '4', '3'],
                    correct: '4',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/R5_7.mp4',
                pressNext: false,
                micTask: { ab_match: 'ס' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R5_8.mp4',
                answers: {
                    answers: ['3', '2', '5'],
                    correct: '3',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/R5_9.mp4',
                pressNext: false,
                micTask: { ab_match: 'מ' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R5_10.mp4',
                answers: {
                    answers: ['1', '3', '2'],
                    correct: '3',
                },
                successSound: true,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/R5_11.mp4',
                pressNext: false,
                micTask: { ab_match: 'ג' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R5_12.mp4',
                pressNext: false,
                ab_ruler_match: ['ס', 'ח'],
            },
            {
                video_url: base_storage_path_class + '/R5_13.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R5_14.mp4',
                pressNext: false,
                micTask: { word_match: ['אושר'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R5_15.mp4',
                pressNext: false,
                render_time: 60,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        img: c5,
    },
    {
        title: 'שיעור שישי',
        materials: ['צבעים', 'דפים'],
        time_len: '25',
        tasks: [
            {
                video_url: base_storage_path_class + '/R6_1.mp4',
                pressNext: false,
                pressBack: false,
                micTask: { word_match: ['חתול'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R6_2.mp4',
                pressNext: false,
                micTask: { word_match: ['שועל'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R6_3.mp4',
                pressNext: false,
                micTask: { word_match: ['פיל'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R6_4.mp4',
                pressNext: false,
                micTask: { word_match: ['נמר'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R6_5.mp4',
                pressNext: false,
                micTask: { word_match: ['נמר'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R6_6.mp4',
                pressNext: false,
                micTask: { word_match: ['ילדה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R6_7.mp4',
                pressNext: false,
                micTask: { word_match: ['מסמר'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R6_8.mp4',
                pressNext: false,
                micTask: { word_match: ['גדר'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R6_9.mp4',
                pressNext: false,
                micTask: { rhyme: 'בלון' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R6_10.mp4',
                pressNext: true,
                progressVideo: {
                    video_stops: [2.45, 4.05, 5.3, 6.8],
                    ab_ruler: false,
                    tasks: [
                        { none: true },
                        { micTask: { rhyme: 'בלון' } },
                        { none: true },
                        { micTask: { rhyme: 'בלון' } },
                        { none: true },
                        { micTask: { rhyme: 'בלון' } },
                        { none: true },
                        { micTask: { rhyme: 'בלון' } },
                        { none: true },
                    ],
                },
            },
            {
                video_url: '',
                puzzle: { lvl: 2, letter_type: 'letter' },
            },
            {
                video_url: base_storage_path_class + '/R6_11.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R6_12.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R6_13.mp4',
                pressNext: false,
                render_time: 45,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        img: c6,
    },
    {
        title: 'שיעור שביעי',
        materials: ['צבעים', 'דפים', 'מספריים', 'דבק סטיק', 'סרגל', 'עיתונים'],
        time_len: '30',
        tasks: [
            {
                video_url: base_storage_path_class + '/R7_1.mp4',
                pressNext: false,
                pressBack: false,
                micTask: { rhyme: 'שיניים' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_2.mp4',
                pressNext: true,

                progressVideo: {
                    video_stops: [1.19, 2.35, 4.17, 5.29],
                    ab_ruler: false,
                    tasks: [
                        { none: true },
                        { micTask: { rhyme: 'שיניים' } },
                        { none: true },
                        { micTask: { rhyme: 'שיניים' } },
                        { none: true },
                        { micTask: { rhyme: 'שיניים' } },
                        { none: true },
                        { micTask: { rhyme: 'שיניים' } },
                        { none: true },
                    ],
                },
            },
            {
                video_url: base_storage_path_class + '/R7_2_1.mp4',
                pressNext: false,

                micTask: { word_match: ['מזלג', 'דג'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_3.mp4',
                pressNext: false,

                micTask: { word_match: ['מזלג', 'דג'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_4.mp4',
                pressNext: false,

                micTask: { word_match: ['פעמון'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_5.mp4',
                pressNext: false,

                micTask: { word_match: ['סולם'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_6.mp4',
                pressNext: false,

                micTask: { word_match: ['קוף'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_7.mp4',
                pressNext: false,

                micTask: { word_match: ['עציץ'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_8.mp4',
                pressNext: false,

                micTask: { word_match: ['מלך'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_9.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R7_10.mp4',
                pressNext: false,
                micTask: { word_match: ['final_m'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_11.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R7_12.mp4',
                pressNext: false,
                micTask: { word_match: ['final_n'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_13.mp4',
                pressNext: false,
                micTask: { word_match: ['final_cha'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_14.mp4',
                pressNext: false,
                micTask: { word_match: ['final_f'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_15.mp4',
                pressNext: false,
                micTask: { word_match: ['final_kaf'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R7_16.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R7_17.mp4',
                pressNext: false,
                render_time: 160,
                cameraTask: { finish_to: 'classroom' },
            },
        ],
        img: c7,
    },
    {
        title: 'שיעור שמיני',
        materials: ['צבעים', 'דפים'],
        time_len: '30',
        tasks: [
            {
                video_url: base_storage_path_class + '/R8_1.mp4',
                pressNext: false,
                pressBack: false,
                micTask: { ab_match: 'א' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R8_2.mp4',
                pressNext: true,
                progressVideo: {
                    ab_ruler: true,
                    video_stops: [2, 4, 5.37, 7.4, 9.15, 11.18, 13.2, 15.21, 17.12, 19.12],
                    tasks: [
                        { ab_highlight: 'ב' },
                        { micTask: { ab_match: 'ג' } },
                        { ab_highlight: 'ד' },
                        { micTask: { ab_match: 'ה' } },
                        { ab_highlight: 'ו' },
                        { micTask: { ab_match: 'ז' } },
                        { ab_highlight: 'ח' },
                        { micTask: { ab_match: 'ט' } },
                        { ab_highlight: 'י' },
                        { micTask: { ab_match: 'כ' } },
                        { ab_highlight: 'ל' },
                        { micTask: { ab_match: 'מ' } },
                        { ab_highlight: 'נ' },
                        { micTask: { ab_match: 'ס' } },
                        { ab_highlight: 'ע' },
                        { micTask: { ab_match: 'פ' } },
                        { ab_highlight: 'צ' },
                        { micTask: { ab_match: 'ק' } },
                        { ab_highlight: 'ר' },
                        { micTask: { ab_match: 'ש' } },
                        { ab_highlight: 'ת' },
                    ],
                },
            },
            {
                video_url: base_storage_path_class + '/R8_3.mp4',
                micTask: { ab_match: 'צ' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R8_4.mp4',
                answers: {
                    answers: ['2', '3', '4'],
                    correct: '4',
                },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R8_5.mp4',
                micTask: { word_match: ['ענבים', 'תותים'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R8_6.mp4',
                micTask: { rhyme: 'חילזון' },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R8_7.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R8_8.mp4',
                micTask: { word_match: ['סולם'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R8_9.mp4',
                micTask: { word_match: ['נדנדה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R8_10.mp4',
                micTask: { word_match: ['ארגז_חול'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R8_11.mp4',
                micTask: { word_match: ['מיגלשה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R8_12.mp4',
                micTask: { word_match: ['קרוסלה'] },
                successSound: true,
            },
            {
                video_url: base_storage_path_class + '/R8_13.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R8_14.mp4',
                cameraTask: { finish_to: 'none' },
                render_time: 30,
                pressNext: false,
            },
            {
                video_url: base_storage_path_class + '/R8_15.mp4',
                pressNext: true,
            },
            {
                video_url: base_storage_path_class + '/R8_16.mp4',
                pressNext: true,
                fillAddress: true,
            },
            {
                video_url: base_storage_path_class + '/R8_17.mp4',
                pressNext: true,
                finish_to_classroom: true,
            },
        ],
        img: c8,
    },
];

export const more_tasks_metadata: IMoreTaskMetadata[] = [
    {
        title: 'קשת אותיות',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt1.mp4',
        img: mt1,
        static_ab_ruler: 'letter',
    },
    {
        title: 'נהדר',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt2.mp4',
        img: mt2,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt2.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [17.5, 42, 63, 88],
                tasks: [
                    { none: true },
                    { answers: { answers: ['א', 'נ', 'ג'], correct: 'נ' } },
                    { none: true },
                    { answers: { answers: ['ה', 'א', 'ב'], correct: 'ה' } },
                    { none: true },
                    { answers: { answers: ['ס', 'פ', 'ד'], correct: 'ד' } },
                    { none: true },
                    { answers: { answers: ['ר', 'י', 'צ'], correct: 'ר' } },
                    { none: true },
                ],
            },
        },
    },
    {
        title: 'שנה טובה',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt3.mp4',
        img: mt3,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt3.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [15, 36, 50, 71, 87, 100, 117],
                tasks: [
                    { none: true },
                    { answers: { answers: ['א', 'ש', 'ג'], correct: 'ש' } },
                    { none: true },
                    { answers: { answers: ['נ', 'א', 'ב'], correct: 'נ' } },
                    { none: true },
                    { answers: { answers: ['ס', 'פ', 'ה'], correct: 'ה' } },
                    { none: true },
                    { answers: { answers: ['ר', 'י', 'ט'], correct: 'ט' } },
                    { none: true },
                    { answers: { answers: ['ו', 'י', 'ב'], correct: 'ו' } },
                    { none: true },
                    { answers: { answers: ['ד', 'צ', 'ב'], correct: 'ב' } },
                    { none: true },
                    { answers: { answers: ['ס', 'ה', 'ע'], correct: 'ה' } },
                    { none: true },
                ],
            },
        },
    },
    {
        title: 'מזל טוב',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt4.mp4',
        img: mt4,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt4.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [95, 114, 132.5, 152, 166, 182.5],
                tasks: [
                    { none: true },
                    { answers: { answers: ['נ', 'מ', 'ג'], correct: 'מ' } },
                    { none: true },
                    { answers: { answers: ['ל', 'א', 'ז'], correct: 'ז' } },
                    { none: true },
                    { answers: { answers: ['ל', 'פ', 'ה'], correct: 'ל' } },
                    { none: true },
                    { answers: { answers: ['ר', 'י', 'ט'], correct: 'ט' } },
                    { none: true },
                    { answers: { answers: ['ו', 'י', 'ב'], correct: 'ו' } },
                    { none: true },
                    { answers: { answers: ['ד', 'צ', 'ב'], correct: 'ב' } },
                    { none: true },
                ],
            },
        },
    },
    {
        title: 'ישראל שלי',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt5.mp4',
        img: mt5,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt5.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [17, 35, 56.5, 75, 94],
                tasks: [
                    { none: true },
                    { answers: { answers: ['א', 'י', 'ג'], correct: 'י' } },
                    { none: true },
                    { answers: { answers: ['ה', 'ש', 'ב'], correct: 'ש' } },
                    { none: true },
                    { answers: { answers: ['ס', 'ר', 'ד'], correct: 'ר' } },
                    { none: true },
                    { answers: { answers: ['ר', 'א', 'צ'], correct: 'א' } },
                    { none: true },
                    { answers: { answers: ['ר', 'ל', 'צ'], correct: 'ל' } },
                    { none: true },
                ],
            },
        },
    },
    {
        title: 'תודה',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt6.mp4',
        img: mt6,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt6.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [22.5, 44, 65, 82],
                tasks: [
                    { none: true },
                    { answers: { answers: ['ת', 'ד', 'א'], correct: 'ת' } },
                    { none: true },
                    { answers: { answers: ['י', 'ו', 'ג'], correct: 'ו' } },
                    { none: true },
                    { answers: { answers: ['ל', 'פ', 'ד'], correct: 'ד' } },
                    { none: true },
                    { answers: { answers: ['ר', 'ה', 'ט'], correct: 'ה' } },
                    { none: true },
                ],
            },
        },
    },
    {
        title: 'חנוכה',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt7.mp4',
        img: mt7,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt7.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [71, 91, 111, 126, 142, 162, 180],
                tasks: [
                    { none: true },
                    { answers: { answers: ['מ', 'נ', 'א'], correct: 'נ' } },
                    { none: true },
                    { answers: { answers: ['ס', 'א', 'ג'], correct: 'ס' } },
                    { none: true },
                    { answers: { answers: ['ל', 'כ', 'ד'], correct: 'כ' } },
                    { none: true },
                    { answers: { answers: ['ר', 'ה', 'ד'], correct: 'ד' } },
                    { none: true },
                    { answers: { answers: ['י', 'ה', 'ג'], correct: 'ה' } },
                    { none: true },
                    { answers: { answers: ['ש', 'פ', 'ד'], correct: 'ש' } },
                    { none: true },
                    { answers: { answers: ['ר', 'ה', 'מ'], correct: 'מ' } },
                    { none: true },
                ],
            },
        },
    },
    {
        title: 'המשפחה שלי',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt8.mp4',
        img: mt8,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt8.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [24, 39, 52, 69, 85, 101, 116, 132, 144],
                tasks: [
                    { none: true },
                    { answers: { answers: ['ו', 'ה', 'ד'], correct: 'ה' } },
                    { none: true },
                    { answers: { answers: ['מ', 'א', 'ג'], correct: 'מ' } },
                    { none: true },
                    { answers: { answers: ['ל', 'כ', 'ש'], correct: 'ש' } },
                    { none: true },
                    { answers: { answers: ['ר', 'ה', 'פ'], correct: 'פ' } },
                    { none: true },
                    { answers: { answers: ['י', 'ח', 'ג'], correct: 'ח' } },
                    { none: true },
                    { answers: { answers: ['ה', 'נ', 'ד'], correct: 'ה' } },
                    { none: true },
                    { answers: { answers: ['ר', 'ש', 'ת'], correct: 'ש' } },
                    { none: true },
                    { answers: { answers: ['ש', 'פ', 'ל'], correct: 'ל' } },
                    { none: true },
                    { answers: { answers: ['ר', 'י', 'מ'], correct: 'י' } },
                    { none: true },
                ],
            },
        },
    },
    {
        title: 'חכם בשמש',
        materials: ['צבעים', 'דפים'],
        video_url: base_storage_path_class + '/mt9.mp4',
        img: mt9,
        prog_answers_task: {
            video_url: base_storage_path_class + '/mt9.mp4',
            progressVideo: {
                ab_ruler: false, // not used
                video_stops: [58, 79, 105, 127, 142.5, 161.5, 184],
                tasks: [
                    { none: true },
                    { answers: { answers: ['ו', 'ח', 'ל'], correct: 'ח' } },
                    { none: true },
                    { answers: { answers: ['כ', 'ב', 'ג'], correct: 'כ' } },
                    { none: true },
                    { answers: { answers: ['ם', 'מ', 'ש'], correct: 'ם' } },
                    { none: true },
                    { answers: { answers: ['ר', 'ב', 'ש'], correct: 'ב' } },
                    { none: true },
                    { answers: { answers: ['י', 'ח', 'ש'], correct: 'ש' } },
                    { none: true },
                    { answers: { answers: ['ה', 'נ', 'מ'], correct: 'מ' } },
                    { none: true },
                    { answers: { answers: ['ר', 'ש', 'ת'], correct: 'ש' } },
                    { none: true },
                ],
            },
        },
    },
];

const base_compose_game_url = base_storage_path + '/game_compose_word';
const easy_compose_games = ['אריה', 'אגס', 'גמל', 'חלב', 'כינור', 'כלב', 'מטוס', 'מלון', 'נחש', 'שועל', 'תפוז'];
const easy_compose_games_tasks: ITask[] = easy_compose_games.map((word) => ({
    video_url: base_compose_game_url + `/${word}.mp4`,
    micTask: { word_match: [word] },
    successSound: true,
}));

const med_compose_games = [
    'אדום',
    'בננה',
    'גיטרה',
    'חזיר',
    'חליל',
    'חמור',
    'ירוק',
    'כחול',
    'כריש',
    'לבן',
    'לטאה',
    'חתול',
];
const med_compose_games_tasks: ITask[] = med_compose_games.map((word) => ({
    video_url: base_compose_game_url + `/${word}.mp4`,
    micTask: { word_match: [word] },
    successSound: true,
}));

const hard_compose_games = ['אופנוע', 'ארנב', 'חילזון', 'ינשוף', 'מכונית', 'עכביש', 'פילפל', 'תכלת'];
const hard_compose_games_tasks: ITask[] = hard_compose_games.map((word) => ({
    video_url: base_compose_game_url + `/${word}.mp4`,
    micTask: { word_match: [word] },
    successSound: true,
}));

const base_book_game_url = base_storage_path + '/game_sound_book';
const first_game_answers: IAnswerTask[] = [
    {
        answers: ['ש', 'א', 'ב'],
        correct: 'א',
    },
    {
        answers: ['ב', 'א', 'ח'],
        correct: 'ב',
    },
    {
        answers: ['ש', 'ת', 'ד'],
        correct: 'ד',
    },
    {
        answers: ['ח', 'א', 'ד'],
        correct: 'ח',
    },
    {
        answers: ['ה', 'מ', 'י'],
        correct: 'מ',
    },
    {
        answers: ['פ', 'ג', 'צ'],
        correct: 'פ',
    },
    {
        answers: ['ו', 'ת', 'צ'],
        correct: 'צ',
    },
    {
        answers: ['ש', 'ט', 'ק'],
        correct: 'ט',
    },
    {
        answers: ['ל', 'ב', 'ט'],
        correct: 'ל',
    },
    {
        answers: ['ש', 'ג', 'מ'],
        correct: 'ג',
    },
    {
        answers: ['ה', 'ל', 'פ'],
        correct: 'ה',
    },
    {
        answers: ['צ', 'כ', 'פ'],
        correct: 'כ',
    },
    {
        answers: ['ס', 'מ', 'ב'],
        correct: 'ס',
    },
    {
        answers: ['ו', 'ר', 'ד'],
        correct: 'ר',
    },
    {
        answers: ['ע', 'ק', 'נ'],
        correct: 'נ',
    },
    {
        answers: ['ט', 'ז', 'ע'],
        correct: 'ז',
    },
    {
        answers: ['ו', 'צ', 'ר'],
        correct: 'ו',
    },
    {
        answers: ['נ', 'ש', 'ת'],
        correct: 'ש',
    },
    {
        answers: ['ט', 'ע', 'ס'],
        correct: 'ע',
    },
    {
        answers: ['ת', 'צ', 'י'],
        correct: 'י',
    },
    {
        answers: ['ה', 'ק', 'ס'],
        correct: 'ק',
    },
    {
        answers: ['ת', 'א', 'ל'],
        correct: 'ת',
    },
];
const first_book_game: ITask[] = [...Array(22).keys()].map((idx) => ({
    instructions: 'game_book_1',
    video_url: base_book_game_url + `/${idx}.mp4`,
    successSound: true,
    answers: first_game_answers[idx],
}));

const easy_word_drag_game = ['מחשב', 'אריה', 'מזלג', 'תפוז', 'ארנב', 'עכבר', 'מטוס', 'צלחת', 'רכבת'];
const easy_word_drag_game_tasks: ITask[] = easy_word_drag_game.map((word) => ({
    video_url: '',
    wordDragGame: word,
}));

const med_word_drag_game = ['כרית', 'צמיג', 'קלמר', 'מסוק', 'מגנט', 'כיסא', 'אשפה', 'חתול', 'סנדל'];
const med_word_drag_game_tasks: ITask[] = med_word_drag_game.map((word) => ({
    video_url: '',
    wordDragGame: word,
}));

const hard_word_drag_game = ['שועל', 'חזיר', 'חמור', 'חציל', 'כדור', 'טבעת', 'מתנה', 'מפתח', 'עגלה'];
const hard_word_drag_game_tasks: ITask[] = hard_word_drag_game.map((word) => ({
    video_url: '',
    wordDragGame: word,
}));

export const games: IGameGroup[] = [
    {
        title: 'כבשים מבולבלות',
        desc: 'מסדרים את הכבשים ומחזקים את הכרת סדר הא׳-ב׳ וזיהוי האותיות',
        games: [
            {
                title: 'רמה קלה',
                task: {
                    video_url: '',
                    puzzle: { lvl: 1, letter_type: 'letter' },
                },
                img: g_ship,
            },
            {
                title: 'רמה בינונית',
                task: {
                    video_url: '',
                    puzzle: { lvl: 2, letter_type: 'letter' },
                },
                img: g_ship,
            },
            {
                title: 'רמה קשה',
                task: {
                    video_url: '',
                    puzzle: { lvl: 3, letter_type: 'letter' },
                },
                img: g_ship,
            },
            {
                title: 'רמה קשה מאד',
                task: {
                    video_url: '',
                    puzzle: { lvl: 4, letter_type: 'letter' },
                },
                img: g_ship,
            },
        ],
        img: g_ship,
    },
    {
        title: 'ספר הצלילים',
        desc: 'חידות בתמונות - מחזקים את הבנת הקשר בין אות לצליל פותח במילה',
        games: [
            {
                title: 'חידות צליל פותח',
                mul_tasks: first_book_game,
                img: g_book_sound,
            },
            // {
            //     title: 'חידות צליל סוגר',
            //     mul_tasks: easy_compose_games_tasks,
            //     img: g_book_sound,
            // },
        ],
        img: g_book_sound,
    },
    {
        title: 'מבוך האותיות',
        desc: 'מובילים את רודי במבוך האותיות ומחזקים את זיהוי האותיות והכרת סדר הא׳-ב׳',
        games: [
            {
                title: 'רמה קלה',
                task: {
                    video_url: '',
                    maze: { lvl: 1, letter_type: 'letter' },
                },
                img: g_maze,
            },
            {
                title: 'רמה בינונית',
                task: {
                    video_url: '',
                    maze: { lvl: 2, letter_type: 'letter' },
                },
                img: g_maze,
            },
            {
                title: 'רמה קשה',
                task: {
                    video_url: '',
                    maze: { lvl: 3, letter_type: 'letter' },
                },
                img: g_maze,
            },
            {
                title: 'רמה קשה מאד',
                task: {
                    video_url: '',
                    maze: { lvl: 4, letter_type: 'letter' },
                },
                img: g_maze,
            },
        ],
        img: g_maze,
    },
    {
        title: 'הרכב את המילה - מיקרופון',
        desc: 'מחברים צלילים למילים שלמות ומפתחים את היכולת להרכיב מילה מצלילים בודדים',
        games: [
            {
                title: 'רמה קלה',
                mul_tasks: easy_compose_games_tasks,
                img: g_compose,
            },
            {
                title: 'רמה בינונית',
                mul_tasks: med_compose_games_tasks,
                img: g_compose,
            },
            {
                title: 'רמה קשה',
                mul_tasks: hard_compose_games_tasks,
                img: g_compose_hard,
            },
        ],
        img: g_compose,
    },
    {
        title: 'הרכב את המילה - גרירת אותיות',
        desc: 'גוררים אותיות למקומן ומתרגלים את זיהוי האותיות והרכבת מילים שלמות',
        games: [
            {
                title: 'רמה קלה',
                mul_tasks: easy_word_drag_game_tasks,
                img: g_word_drag_easy,
            },
            {
                title: 'רמה בינונית',
                mul_tasks: med_word_drag_game_tasks,
                img: g_word_drag_med,
            },
            {
                title: 'רמה קשה',
                mul_tasks: hard_word_drag_game_tasks,
                img: g_word_drag_hard,
            },
        ],
        img: g_word_drag_easy,
    },
    {
        title: 'משחק הזיכרון',
        desc: 'המשחק האהוב בואריאציות שונות. מחזקים את זיהוי האותיות, אותיות סופיות, צליל פותח, צליל סוגר',
        games: [
            {
                title: "זיהוי אותיות א'-ו'",
                task: {
                    video_url: '',
                    memory_cards: 5,
                },
                img: g_memory,
            },
            {
                title: "זיהוי אותיות ז'-ל'",
                task: {
                    video_url: '',
                    memory_cards: 4,
                },
                img: g_memory,
            },
            {
                title: "זיהוי אותיות מ'-צ'",
                task: {
                    video_url: '',
                    memory_cards: 3,
                },
                img: g_memory,
            },
            {
                title: "זיהוי אותיות ק'-ת'",
                task: {
                    video_url: '',
                    memory_cards: 6,
                },
                img: g_memory,
            },
            {
                title: 'אותיות סופיות',
                task: {
                    video_url: '',
                    memory_cards: 7,
                },
                img: g_memory,
            },
            {
                title: 'צליל פותח',
                task: {
                    video_url: '',
                    memory_cards: 1,
                },
                img: g_memory,
            },
            {
                title: 'צליל סוגר',
                task: {
                    video_url: '',
                    memory_cards: 8,
                },
                img: g_memory,
            },
            {
                title: 'חרוזים',
                task: {
                    video_url: '',
                    memory_cards: 2,
                },
                img: g_memory,
            },
        ],
        img: g_memory,
    },
];
