import React, { useContext } from 'react';
import CloseIcon from '../../assets/icons/close_green.svg';
import messages from '../../assets/messages_he.json';
import { useMediaQueryWrap } from '../useMediaQueryWrap';
import TextButton from '../buttons/TextButton';
import CircularProgress from '@mui/material/CircularProgress';
import { AppContext } from '../../AppContext';
import { useAddress } from './useAddess';
import { get_txt_btn_font_size } from '../common';
import MainModal from '../layouts/MainModal';
import rabbitSvg from '../../assets/images/rabbit_login.svg';
import billySvg from 'src/assets/images/billy_payment.svg';
import TextField from '@mui/material/TextField';
import './AddressModal.css';

function AddresModal(): JSX.Element {
    const { state } = useContext(AppContext);
    const { getMQid } = useMediaQueryWrap();
    const { toggle_address_modal, onApi, canSubmit, updateValue, submbitAddress, is_in_last_class } = useAddress();
    const rid = getMQid();

    const is_course_finished = () => {
        if (state.adressStatus === 'close_modal') return;
        return state.courses[state.adressStatus].finished_course;
    };

    const get_image_src = () => {
        if (state.adressStatus === 'close_modal') return;
        return state.adressStatus === 'heb1' ? rabbitSvg : billySvg;
    };

    const get_sub_text1 = () => {
        if (state.adressStatus === 'close_modal') return '';
        return state.adressStatus === 'heb1'
            ? messages['address.modal.subtext1_heb']
            : messages['address.modal.subtext1_math'];
    };

    const get_sub_text2 = () => {
        if (state.adressStatus === 'close_modal') return '';
        return state.adressStatus === 'heb1'
            ? messages['address.modal.subtext2_heb']
            : messages['address.modal.subtext2_math'];
    };

    const get_gift_text = () => {
        if (state.adressStatus === 'close_modal') return '';
        return state.adressStatus === 'heb1'
            ? messages['address.modal.gift_sent_heb']
            : messages['address.modal.gift_sent_math'];
    };

    const render_action = () => {
        if (state.adressStatus === 'close_modal') return null;
        if (is_course_finished())
            return (
                <p className={'address_modal_subtextr'} style={{ color: '#F76E5B' }}>
                    {get_gift_text()}
                </p>
            );
        if (onApi) return <CircularProgress style={{ width: 50, height: 50, color: '#6CBFF7' }} />;
        return (
            <TextButton
                disabled={!canSubmit()}
                onClick={submbitAddress}
                text={messages['address.input_send']}
                color={'blue'}
                textColor={'white'}
                fontSize={get_txt_btn_font_size(rid)}
                width="75%"
                padding="0.5em"
            />
        );
    };

    return (
        <MainModal
            id={'address'}
            open={state.adressStatus !== 'close_modal'}
            close={() => toggle_address_modal('close_modal')}
            closeIcon={CloseIcon}
        >
            <div className="address_content">
                <p id={rid} className={'address_modal_header'}>
                    {messages['address.modal.title']}
                </p>
                <p id={rid} className={'address_modal_subtextr'}>
                    {get_sub_text1()} <br /> {get_sub_text2()}
                </p>
                <div className={'address_modal_bottom'}>
                    <img src={get_image_src()} alt="logo image" />
                    <div className={'address_modal_form_row'}>
                        <TextField
                            disabled={is_course_finished()}
                            required
                            label={messages['address.input.first_name']}
                            defaultValue={state.address.name}
                            onChange={(e) => updateValue('name', e.target.value)}
                        />
                        <TextField
                            disabled={is_course_finished()}
                            required
                            label={messages['address.input.last_name']}
                            defaultValue={state.address.last_name}
                            onChange={(e) => updateValue('last_name', e.target.value)}
                        />
                    </div>
                    <div className={'address_modal_form_row'}>
                        <TextField
                            disabled={is_course_finished()}
                            required
                            label={messages['address.input.city']}
                            defaultValue={state.address.city}
                            onChange={(e) => updateValue('city', e.target.value)}
                        />
                        <TextField
                            disabled={is_course_finished()}
                            required
                            label={messages['address.input.street']}
                            defaultValue={state.address.street}
                            onChange={(e) => updateValue('street', e.target.value)}
                        />
                    </div>
                    <p id={rid} className={'address_modal_note'}>
                        {is_in_last_class()
                            ? messages['address.modal.note_can_submit']
                            : messages['address.modal.note_cant_submit']}
                    </p>
                    {render_action()}
                </div>
            </div>
        </MainModal>
    );
}

export default AddresModal;
