import React from 'react';
import { IMicTask } from '../../wrapper';
import { useMic } from './useMic';
import micSvg from '../../assets/icons/mic.svg';
import micGif from '../../assets/anim/sound.gif';
import Snackbar from '@mui/material/Snackbar';
import './Mic.css';

export interface IMic {
    task?: IMicTask;
    mic_over_cb?: Function;
    btn_zoom: number;
    disable?: boolean;
    successSound?: boolean;
}

function Mic(props: IMic): JSX.Element {
    const { record, startRecord, reportErr } = useMic(props);

    return (
        <>
            <button
                className="btn-mic"
                onClick={startRecord}
                style={{ zoom: props.btn_zoom }}
                disabled={props.disable}
                aria-label="הפעל מיקרופון"
            >
                {!record ? <img className="micImg" src={micSvg} alt="מיקרופון" /> : null}
                {record ? <img className="micGif" src={micGif} alt="אנימציית מיקרופון" /> : null}
            </button>
            <Snackbar open={reportErr != undefined} onClose={() => null} message={reportErr} />
        </>
    );
}

export default Mic;
