import React from 'react';
import MainLayout from '../MainLayout';
import logoImage from '../../../assets/logo/blue.svg';
import MenuImg from '../../../assets/icons/blueMenu.svg';
import messages from '../../../assets/messages_he.json';
import { useMediaQueryWrap } from '../../useMediaQueryWrap';
import Socials from './Socials';
import './TextLayout.css';

function Contact(): JSX.Element {
    const { getMQid } = useMediaQueryWrap();
    const rid = getMQid();

    return (
        <MainLayout can_url="/contact" id={'text'} logo={logoImage} appMenuLogo={MenuImg}>
            <div className="tl_wrap">
                <div className="tl_wrap_inside" style={{ alignItems: 'center', gap: '2em' }}>
                    <h1 id={rid} className="tl_main_header">
                        {messages['contact.header']}
                    </h1>
                    <p id={rid} className="tl_text" style={{ textAlign: 'center' }}>
                        {messages['contact.text1']} <br /> {messages['contact.text2']}
                    </p>
                    <Socials />
                    <div className="contact_ending">
                        <p id={rid} className="appRightsText" style={{ textAlign: 'center' }}>
                            {messages['contact.ending.address']} <br /> {messages['contact.ending.phone']} <br />{' '}
                            {messages['contact.ending.mail']} {messages['contact.mail']}
                        </p>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default Contact;
