import React, { useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import { useAppMenu } from './useAppMenu';
import CloseIcon from '../assets/icons/close_blue.svg';
import myClassSVg from '../assets/icons/my_class.svg';
import packSvg from '../assets/icons/pack.svg';
import tasksSvg from '../assets/icons/more_tasks.svg';
import gamesSvg from '../assets/icons/games.svg';
import CircleButton from './buttons/CircleButton';
import messages from '../assets/messages_he.json';
import { useMediaQueryWrap } from './useMediaQueryWrap';
import { useShare } from './useShare';
import { AppContext } from 'src/AppContext';
import rudiSvg from 'src/assets/images/rudi.svg';
import billySvg from 'src/assets/images/billy.svg';
import './AppMenu.css';

interface IAppMenu {
    logo: string;
}

function AppMenu({ logo }: IAppMenu): JSX.Element {
    const {
        open,
        setOpen,
        width_pre,
        classroomClick,
        folderClick,
        moreTasksClick,
        niceToMeetClick,
        firstGradeClick,
        contactClick,
        gamesClick,
        aboutRoyClick,
        on_course_click,
    } = useAppMenu();

    const { getMQid } = useMediaQueryWrap();
    const { shareAppLink } = useShare();
    const { state } = useContext(AppContext);

    const toggle_course = () => {
        setOpen(false);

        const other_course_click =
            state.course_key === 'heb1' ? () => on_course_click('math1') : () => on_course_click('heb1');
        other_course_click();
    };

    const render_top_content = () => {
        if (state.course_key === '')
            return (
                <div className="app_menu_content_top">
                    <div className="app_menu_button_wraper">
                        <CircleButton
                            svg={rudiSvg}
                            onClick={() => on_course_click('heb1')}
                            ariaLabel={messages['course.to_heb1']}
                        />
                        <button
                            className="app_menu_btn_text"
                            onClick={() => on_course_click('heb1')}
                            aria-label={messages['course.to_heb1']}
                        >
                            {messages['course.to_heb1']}
                        </button>
                    </div>
                    <div className="app_menu_button_wraper">
                        <CircleButton
                            svg={billySvg}
                            onClick={() => on_course_click('math1')}
                            ariaLabel={messages['course.to_math1']}
                        />
                        <button
                            className="app_menu_btn_text"
                            onClick={() => on_course_click('math1')}
                            aria-label={messages['course.to_math1']}
                        >
                            {messages['course.to_math1']}
                        </button>
                    </div>
                </div>
            );

        const curr_course_img = state.course_key === 'heb1' ? rudiSvg : billySvg;
        const curr_course_text = state.course_key === 'heb1' ? messages['course.on_heb1'] : messages['course.on_math1'];

        const other_course_img = state.course_key === 'heb1' ? billySvg : rudiSvg;
        const other_course_text =
            state.course_key === 'heb1' ? messages['course.mv_to_math1'] : messages['course.mv_to_heb1'];
        return (
            <div className="app_menu_content_top">
                <div id="top" className="app_menu_button_wraper">
                    <CircleButton svg={curr_course_img} onClick={() => null} ariaLabel={curr_course_text} />
                    <p className="app_menu_btn_text" style={{ cursor: 'auto' }}>
                        {curr_course_text}
                    </p>
                </div>
                <div className="app_menu_button_wraper">
                    <CircleButton
                        svg={myClassSVg}
                        color={'green'}
                        onClick={classroomClick}
                        ariaLabel={messages['btn.classroom']}
                    />
                    <button
                        className="app_menu_btn_text"
                        onClick={classroomClick}
                        aria-label={messages['btn.classroom']}
                    >
                        {messages['btn.classroom']}
                    </button>
                </div>
                <div className="app_menu_button_wraper">
                    <CircleButton
                        svg={packSvg}
                        color={'orange'}
                        onClick={folderClick}
                        ariaLabel={messages['btn.pack']}
                    />
                    <button className="app_menu_btn_text" onClick={folderClick} aria-label={messages['btn.pack']}>
                        {messages['btn.pack']}
                    </button>
                </div>
                <div className="app_menu_button_wraper">
                    <CircleButton
                        svg={tasksSvg}
                        color={'pink'}
                        onClick={moreTasksClick}
                        ariaLabel={messages['btn.tasks']}
                    />
                    <button className="app_menu_btn_text" onClick={moreTasksClick} aria-label={messages['btn.tasks']}>
                        {messages['btn.tasks']}
                    </button>
                </div>
                <div className="app_menu_button_wraper">
                    <CircleButton
                        svg={gamesSvg}
                        color={'purple'}
                        onClick={gamesClick}
                        ariaLabel={messages['btn.games']}
                    />
                    <button className="app_menu_btn_text" onClick={gamesClick} aria-label={messages['btn.games']}>
                        {messages['btn.games']}
                    </button>
                </div>
                <div className="app_menu_button_wraper">
                    <CircleButton
                        svg={other_course_img}
                        color={'yellow'}
                        onClick={toggle_course}
                        ariaLabel={other_course_text}
                    />
                    <button className="app_menu_btn_text" onClick={toggle_course} aria-label={other_course_text}>
                        {other_course_text}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            <button className="app_menu_btn" onClick={() => setOpen(true)} aria-label="לפתיחת תפריט">
                <img id={getMQid()} src={logo} className="app_menu_btn_svg" alt="תפריט" />
            </button>
            <Drawer
                PaperProps={{
                    sx: {
                        backgroundColor: '#40A9EF',
                        overflowY: 'auto',
                        height: '100%',
                    },
                }}
                anchor={'left'}
                variant="temporary"
                open={open}
                onClose={() => setOpen(false)}
            >
                <nav className="app_menu_wrapper" style={{ width: window.innerWidth * width_pre }}>
                    <div className="app_menu_top">
                        <img
                            className="appmenu_close"
                            src={CloseIcon}
                            onClick={() => setOpen(false)}
                            alt="סגור תפריט"
                        ></img>
                    </div>
                    {render_top_content()}
                    <div className="app_menu_content_bottom">
                        <button
                            id={'noicon'}
                            className="app_menu_btn_text"
                            onClick={niceToMeetClick}
                            aria-label={messages['menu.nicetomeet']}
                        >
                            {messages['menu.nicetomeet']}
                        </button>
                        <button
                            id={'noicon'}
                            className="app_menu_btn_text"
                            onClick={aboutRoyClick}
                            aria-label={messages['menu.aboutroy']}
                        >
                            {messages['menu.aboutroy']}
                        </button>
                        <button
                            id={'noicon'}
                            className="app_menu_btn_text"
                            onClick={firstGradeClick}
                            aria-label={messages['menu.ready']}
                        >
                            {messages['menu.ready']}
                        </button>
                        <button
                            id={'noicon'}
                            className="app_menu_btn_text"
                            onClick={shareAppLink}
                            aria-label={messages['menu.share']}
                        >
                            {messages['menu.share']}
                        </button>
                        <button
                            id={'noicon'}
                            className="app_menu_btn_text"
                            onClick={contactClick}
                            aria-label={messages['menu.contact']}
                        >
                            {messages['menu.contact']}
                        </button>
                    </div>
                </nav>
            </Drawer>
        </>
    );
}

export default AppMenu;
