import React from 'react';
import rudiSvg from 'src/assets/images/rudi.svg';
import billySvg from 'src/assets/images/billy.svg';
import { ILetterId } from 'src/wrapper';
import './Maze.css';

export interface IMazeLetter {
    size: number;
    top: number;
    left: number;
    type: ILetterId;
}

function MazePlayer(props: IMazeLetter): JSX.Element {
    return (
        <div
            className="maze_player"
            id="maze_player"
            style={{
                top: props.top,
                left: props.left,
                width: props.size,
                height: props.size,
            }}
        >
            <img
                src={props.type === 'letter' ? rudiSvg : billySvg}
                width={props.size}
                height={props.size}
                alt="רודי הארנב"
            ></img>
        </div>
    );
}

export default MazePlayer;
