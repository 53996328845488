import React from 'react';
import './PaintMaze.css';

export interface IPaintMazeMouse {
    x: number;
    y: number;
    color: string;
    clicked: boolean;
}

function PaintMazeMouse(props: IPaintMazeMouse): JSX.Element {
    return (
        <svg
            className={`painter ${props.clicked ? 'clicked' : ''}`}
            width="68"
            height="99"
            viewBox="0 0 68 99"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ top: `${props.y}px`, left: `${props.x}px` }}
        >
            <path
                d="M29.7143 44.6366C31.3351 42.3497 33.2979 40.251 35.0399 38.0066C38.4386 33.76 41.8978 29.5347 45.5566 25.5156C48.8421 21.9775 52.3272 18.6456 55.9513 15.4987C57.4344 14.1837 59.0957 13.1354 60.8567 12.1903C61.86 11.6589 62.999 11.5153 64.1279 11.9805C65.9619 12.7617 66.7075 14.3166 66.2076 16.3176C65.9487 17.2469 65.6292 18.155 65.1887 19.0204C62.8158 23.832 60.222 28.4978 57.0049 32.8083C55.5723 34.7534 54.1005 36.6166 52.4079 38.3341C51.7891 38.9328 51.2309 39.5527 50.7119 40.2545C47.6405 44.3442 43.91 47.7938 40.639 51.6772C40.2052 52.1368 39.8106 52.6783 39.2378 52.953C36.8846 49.1999 33.5785 46.6083 29.7143 44.6366Z"
                fill="#896253"
            />
            <path
                d="M29.7131 44.6364C33.5986 46.5476 36.8834 49.1997 39.2366 52.9528C36.1248 55.8037 33.2731 58.8822 30.122 61.6513C27.7913 58.9946 24.9382 57.2426 21.435 56.7585C22.0525 55.0029 23.0334 53.3752 24.1139 51.8505C25.7135 49.6241 27.3523 47.4795 28.9911 45.3349C29.1369 45.114 29.425 44.8752 29.7131 44.6364Z"
                fill="#60411E"
            />
            <path
                d="M21.3546 56.7983C24.9183 57.3036 27.7109 59.0344 30.0416 61.6911C29.2917 62.8558 28.303 63.7325 27.3928 64.7729C26.5834 63.3997 25.404 62.3045 24.2033 61.2699C23.2235 60.381 21.9702 60.0761 20.7169 59.7713C20.3323 59.704 19.9871 59.7186 19.6451 59.5303C20.1888 58.5651 20.5901 57.6178 21.3546 56.7983Z"
                fill="#AA7D6C"
            />
            <path
                className="paint-color"
                fill={props.color}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.22312 68.4808C6.2224 68.481 6.22169 68.4812 6.22098 68.4814C5.38589 69.8884 4.89271 71.4836 4.68429 73.043C4.14085 77.6818 4.96057 82.1199 6.34976 86.4862C6.48543 86.874 6.68164 87.2832 6.89914 87.6318C7.43391 88.4322 8.185 88.4243 8.62551 87.5589C8.82928 87.2714 8.94182 86.9131 9.0488 86.5725C9.08366 86.4615 9.11791 86.3525 9.15454 86.2484C9.81808 84.1688 11.1195 82.7899 13.0835 81.8481C14.3715 81.2808 15.6775 80.8559 17.0619 80.5946C17.1548 80.5788 17.2479 80.5631 17.3412 80.5473C18.5581 80.342 19.8152 80.1299 20.9552 79.5832C20.9468 78.9486 20.8895 78.4532 20.7542 78.0645C20.9178 78.4599 20.9868 78.9776 20.9959 79.6657C22.6505 79.0233 24.0483 77.9504 25.3464 76.7744C28.4582 73.9234 29.3156 69.9393 27.8378 66.018C27.8008 65.9121 27.7532 65.8076 27.7052 65.7024C27.5777 65.4226 27.4483 65.1387 27.5127 64.8152C26.7865 63.5832 25.7455 62.6238 24.6874 61.6487C24.5661 61.5369 24.4445 61.4248 24.3232 61.3122C23.3434 60.4233 22.0901 60.1184 20.8367 59.8136L20.8367 59.8136C20.6794 59.7582 20.5215 59.7409 20.3653 59.7238C20.1606 59.7014 19.9589 59.6794 19.765 59.5726C17.8391 59.4393 15.9704 59.5302 14.1095 60.3722C10.3278 62.0347 8.00638 64.959 6.22312 68.4808ZM6.31918 68.4488C6.44128 68.4063 6.56598 68.3564 6.69068 68.3065C6.70617 68.3003 6.72167 68.2941 6.73716 68.2879C6.64844 68.3168 6.56452 68.3509 6.48152 68.3847C6.42737 68.4068 6.3736 68.4287 6.31918 68.4488ZM11.329 69.2319C11.6714 69.6958 11.9283 70.2735 12.0863 70.953C12.154 71.1677 12.1918 71.3719 12.2343 71.6012C12.2524 71.6988 12.2713 71.8009 12.2937 71.9103C12.3012 71.9075 12.3087 71.9046 12.3162 71.9018C12.3014 71.8261 12.288 71.7537 12.275 71.6837C12.2325 71.4544 12.1946 71.2502 12.127 71.0355C11.9692 70.3128 11.6962 69.7081 11.329 69.2319ZM16.8588 72.4722C17.6553 73.7266 17.2494 75.054 16.8263 76.43C16.8435 76.4462 16.8608 76.4615 16.8783 76.4757C17.3053 75.0866 17.7211 73.6988 16.8588 72.4722Z"
            />
        </svg>
    );
}

export default PaintMazeMouse;
