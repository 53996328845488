import React, { ReactElement } from 'react';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';
import playSvg from '../../assets/icons/play.svg';
import CircleButton from '../../common/buttons/CircleButton';
import { log_event } from 'src/common/common';
import './Video.css';

export interface multipleTasks {
    task_over_cb: Function;
    stops: number[];
    curr_stop: number;
}

export interface singleStop {
    stop?: number;
    singleStopReached: boolean;
    setSingleStopReached: Function;
}
interface IVideo {
    play: boolean;
    setPlay: Function;
    url: string;
    vid_over_cb: Function;
    img?: string | boolean | ReactElement;
    controls: boolean;
    progressInterval?: number;
    mulTasks?: multipleTasks;
    singleStop?: singleStop;
    log_click?: string;
}

function Video(props: IVideo) {
    const onProgress = (state: OnProgressProps) => {
        if (props.mulTasks) {
            const curr_stop = props.mulTasks.curr_stop;
            if (props.mulTasks.stops.length === 0 || curr_stop == props.mulTasks.stops.length) return;
            if (state.playedSeconds >= props.mulTasks.stops[curr_stop]) {
                props.setPlay(false);
                props.mulTasks.task_over_cb();
            }
        } else if (props.singleStop) {
            if (props.singleStop.singleStopReached) return;
            if (props.singleStop.stop && state.playedSeconds >= props.singleStop.stop) {
                props.singleStop.setSingleStopReached(true);
            }
        }
    };

    return (
        <div className="player-wrapper">
            <ReactPlayer
                className="react-player"
                width={'100%'}
                height={'100%'}
                playing={props.play}
                config={{ file: { attributes: { controlsList: 'nodownload nofullscreen' } } }}
                onContextMenu={(e: any) => e.preventDefault()}
                controls={props.controls}
                progressInterval={props.progressInterval}
                url={props.url}
                onEnded={() => props.vid_over_cb()}
                onPlay={() => props.setPlay(true)}
                onProgress={onProgress}
                light={props.img ?? false}
                playsinline={true}
                onClickPreview={() => {
                    props.setPlay(true);
                    if (props.log_click) log_event(props.log_click);
                }}
                playIcon={
                    <CircleButton
                        inf_anim={true}
                        svg={playSvg}
                        color={'green'}
                        onClick={() => null}
                        ariaLabel="הפעל וידאו"
                    />
                }
            />
        </div>
    );
}

export default Video;
