import React from 'react';
import './PaintMaze.css';

export interface IPaintMazeAnswer {
    text: string;
    size: number;
    top: number;
    left: number;
    color: string;
    on_click: () => void;
    is_selected: boolean;
}

function PaintMazeAnswer(props: IPaintMazeAnswer): JSX.Element {
    return (
        <div
            className="paint_maze_answer"
            style={{
                top: props.top,
                left: props.left,
                width: props.size,
                height: props.size,
                border: props.is_selected ? '2px solid white' : '',
            }}
            onClick={props.on_click}
        >
            <div className="paint-bucket">
                <svg width="100%" height="100%" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse
                        cx="37.385"
                        cy="8.49658"
                        rx="37.385"
                        ry="8.49658"
                        transform="matrix(-1 0 0 1 74.7695 58.874)"
                        fill="#484242"
                        fillOpacity="0.09"
                    />
                    <path
                        d="M69.0761 13.7197H6.82812L12.8179 65.9547H12.8405C13.1117 69.5259 24.2775 72.3965 37.9747 72.3965C51.6946 72.3965 62.8378 69.5259 63.109 65.9547L69.0761 13.7197Z"
                        fill="#E4E4E4"
                    />
                    <path
                        d="M37.9956 40.165C29.5873 40.165 20.0716 39.5547 13.1325 35.0341C5.83184 30.2649 2.2832 21.8341 2.2832 9.24438H3.9106C3.9106 33.6328 16.9524 38.5376 37.973 38.5376C46.11 38.5376 55.3093 37.9499 61.9319 33.6102C68.7353 29.1574 72.0353 21.1786 72.0353 9.24438H73.6627C73.6627 21.7663 70.1141 30.1971 62.836 34.9663C55.8744 39.5321 46.3812 40.165 37.9956 40.165Z"
                        fill="#A98F71"
                    />
                    <path
                        d="M47.6488 39.4643C47.6488 40.6396 46.6994 41.5889 45.5241 41.5889H30.2672C29.0919 41.5889 28.1426 40.6396 28.1426 39.4643V38.7636C28.1426 37.5882 29.0919 36.6389 30.2672 36.6389H45.5241C46.6994 36.6389 47.6488 37.5882 47.6488 38.7636V39.4643Z"
                        fill="#7B6143"
                    />
                    <path
                        d="M9.9707 14.9854C15.8474 17.336 23.3289 19.7093 37.9529 19.7093C53.2776 19.7093 60.081 17.3586 65.9351 14.9854C59.3803 16.771 49.2769 17.9011 37.9529 17.9011C26.6289 17.9011 16.5255 16.771 9.9707 14.9854Z"
                        fill="#8A9797"
                    />
                    <path
                        d="M73.7777 7.54932H2.12695V10.1034H2.14956C2.14956 10.126 2.14956 10.1486 2.14956 10.1712C2.14956 14.4432 18.1749 17.9014 37.9523 17.9014C57.7297 17.9014 73.7551 14.4432 73.7551 10.1712C73.7551 10.1486 73.7551 10.126 73.7551 10.1034V7.54932H73.7777Z"
                        fill="#C5C5C5"
                    />
                    <path
                        d="M73.754 7.73014C73.754 3.45822 57.7286 0 37.9512 0C18.1738 0 2.14844 3.45822 2.14844 7.73014C2.14844 12.0021 18.1738 15.4603 37.9512 15.4603C57.7286 15.4603 73.754 12.0021 73.754 7.73014Z"
                        fill="#EDEDED"
                    />
                    <path
                        d="M68.1267 7.73005C68.1267 4.13622 54.6103 1.22046 37.952 1.22046C21.2938 1.22046 7.77734 4.13622 7.77734 7.73005C7.77734 8.97321 9.38214 10.1259 12.1623 11.1205C17.4513 12.9965 58.4301 12.9965 63.7192 11.1205C66.5219 10.1259 68.1267 8.97321 68.1267 7.73005Z"
                        fill="#C5C5C5"
                    />
                    <path
                        d="M65.4079 10.3719C59.8665 8.34506 49.8492 7.00195 38.411 7.00195C26.9728 7.00195 16.9555 8.34506 11.4141 10.3719C16.9555 12.3988 26.9728 13.7419 38.411 13.7419C49.8255 13.7419 59.8428 12.3988 65.4079 10.3719Z"
                        fill="#A3D5E0"
                    />
                    <path
                        d="M59.4639 11.9592C61.761 11.4953 63.7739 10.958 65.4079 10.3719C59.8665 8.34506 49.8492 7.00195 38.411 7.00195C26.9728 7.00195 16.9555 8.34506 11.4141 10.3719C13.0244 10.958 15.0373 11.4953 17.3581 11.9592C22.947 10.8359 30.3356 10.1766 38.4347 10.1766C46.4864 10.1766 53.875 10.8359 59.4639 11.9592Z"
                        fill="#A3D5E0"
                    />
                    <path
                        className="bucket-color"
                        fill={props.color}
                        d="M39.5831 19.4665C39.4544 20.8869 38.017 26.0205 33.9857 26.0205C29.6803 26.0205 28.8877 21.5741 26.0555 17.524C24.9226 14.1253 18.209 13.6185 18.209 13.6185C18.209 13.6185 10.1949 12.4259 7.52555 9.94643C4.95384 6.07054 7.36304 -0.602079 39.5731 0.154703C79.3672 1.08967 68.0598 10.5584 66.2794 11.1704C64.8551 11.66 60.1469 14.2303 57.9708 15.4543C57.1795 18.3102 56.1897 24.4514 56.1904 26.47C56.1913 29.53 54.4109 30.142 53.8174 30.142C52.038 30.1422 52.035 28.4504 52.0361 27.082C52.037 25.8581 52.036 22.1862 51.4426 21.5741C50.5043 20.6064 49.6622 24.022 49.6622 25.246V33.2018C49.6622 34.6705 48.4752 35.4457 47.8817 35.6497C47.0904 35.6497 45.5078 35.1601 45.5078 33.2018V25.246C45.5078 27.082 41.3494 27.7199 40.761 25.2462C40.3809 23.6479 39.6754 21.1228 39.5831 19.4665Z"
                    />
                </svg>
                <p>{props.text}</p>
            </div>
        </div>
    );
}

export default PaintMazeAnswer;
