import React from 'react';
import CashGame from 'src/features/cash_game/CashGame';
import { ITask } from 'src/wrapper';

export interface IClassPuzzle {
    task?: ITask;
    contentHeight: number;
    onFinish: () => void;
}
export function useClassCashGame({ task, contentHeight, onFinish }: IClassPuzzle) {
    const render_cash_game = () => {
        if (!task) return null;
        if (!task.cashGame) return null;

        return (
            <CashGame
                width={window.innerWidth * 0.95}
                height={contentHeight * 0.95}
                onFinish={onFinish}
                object={task.cashGame}
            />
        );
    };

    const is_cash_game = () => {
        if (!task) return false;
        return task.cashGame !== undefined;
    };

    return {
        render_cash_game,
        is_cash_game,
    };
}
