import React, { useContext } from 'react';
import { AppContext } from 'src/AppContext';
import { useFolder } from './useFolder';
import TextButton from 'src/common/buttons/TextButton';
import playSvg from 'src/assets/icons/play.svg';
import './Folder.css';

interface FolderWrapProps {
    title: string;
    sub_folder: string;
    on_click: (sub_folder: string) => void;
    is_empty: boolean;
}

function FolderWrap({ title, sub_folder, on_click, is_empty }: FolderWrapProps): JSX.Element {
    const { state } = useContext(AppContext);
    const { goToMainTaskInClass } = useFolder();

    const render_task_video_btn = () => {
        if (sub_folder === 'moretask') return null;
        if (state.course_key === '') return null;
        if (!(state.course_key in state.courses)) return;

        const class_idx = Number(sub_folder);
        if (!state.courses[state.course_key].classes[class_idx].finished) return null;
        return (
            <TextButton
                onClick={() => goToMainTaskInClass(class_idx)}
                text={'למשימה'}
                textColor={'white'}
                fontSize={'1rem'}
                fontWeight={500}
                width="100%"
                icon={playSvg}
            />
        );
    };

    return (
        <div className="folder-group relative text-center">
            {!is_empty && (
                <div>
                    <div className="folder-paper"></div>
                    <div className="folder-paper"></div>
                </div>
            )}
            <div className="pocket z-2" onClick={() => on_click(sub_folder)}>
                <h3 className="text-xl font-bold">{title}</h3>
                <span>לעבודות שלי</span>
            </div>

            {render_task_video_btn()}
        </div>
    );
}

export default FolderWrap;
