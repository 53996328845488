import { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { uploadAddress } from '../api/server.api';
import { AddressItem, CourseName } from 'src/wrapper';
import { useServer } from '../api/useServer';

export function useAddress() {
    const { state, setState } = useContext(AppContext);
    const [onApi, setOnApi] = useState(false);
    const { handle_no_token_err } = useServer();

    const toggle_address_modal = (value: AddressItem) => {
        setState({
            ...state,
            adressStatus: value,
        });
    };

    const updateValue = (key: keyof typeof state.address, value: string) => {
        const address = { ...state.address };
        address[key] = value;
        setState({ ...state, address: address });
    };

    const is_in_last_class = () => {
        if (state.adressStatus === 'close_modal') return false;
        // make sure user is in the last=7 class
        const curr_course = state.courses[state.adressStatus as CourseName];
        return !!curr_course.classes[7 - 1].finished;
    };

    const canSubmit = () => {
        const valid_address =
            state.address.city !== '' &&
            state.address.last_name !== '' &&
            state.address.street !== '' &&
            state.address.name;

        return valid_address && is_in_last_class();
    };

    const onFinish = () => {
        setOnApi(false);
        const courses = { ...state.courses };
        const toggled_course = state.adressStatus as CourseName;
        courses[toggled_course].finished_course = true;

        setState({
            ...state,
            adressStatus: 'close_modal',
            courses: courses,
        });
    };

    const submbitAddress = () => {
        const token = localStorage.getItem('ib_token');
        if (!token) return;
        uploadAddress(token, state.address, state.adressStatus as CourseName, onFinish, handle_no_token_err);
        setOnApi(true);
    };

    return { toggle_address_modal, updateValue, onApi, canSubmit, submbitAddress, is_in_last_class };
}
