import React from 'react';
import WordDrag from 'src/features/word_drag_game/WordDrag';
import { ITask } from 'src/wrapper';

export interface IClassPuzzle {
    task?: ITask;
    contentHeight: number;
    onFinish: () => void;
}
export function useClassWordDrag({ task, contentHeight, onFinish }: IClassPuzzle) {
    const render_word_drag_game = () => {
        if (!task) return null;
        if (!task.wordDragGame) return null;

        return (
            <WordDrag
                width={window.innerWidth * 0.95}
                height={contentHeight * 0.95}
                onFinish={onFinish}
                word={task.wordDragGame}
            />
        );
    };

    const is_word_drag_game = () => {
        if (!task) return false;
        return task.wordDragGame !== undefined;
    };

    return {
        render_word_drag_game,
        is_word_drag_game,
    };
}
