import React from 'react';
import logoImage from '../assets/logo/blue.svg';
import MenuImg from '../assets/icons/blueMenu.svg';
import MainLayout from '../common/layouts/MainLayout';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { useMoreTask } from './useMoreTask';
import 'src/class/Class.css';

function MoreTask(): JSX.Element {
    const { render_camera, render_video, render_ab_ruler, title, render_answers_prog } = useMoreTask();
    const { getMQid, is_horiz } = useMediaQueryWrap();
    const rid = getMQid();

    const render = () => {
        if (!is_horiz) {
            return (
                <div className="classContentVert">
                    {render_video()}
                    {render_ab_ruler()}
                    {render_answers_prog()}

                    <div className="navButtonVerticalWrap" style={{ justifyContent: 'center' }}>
                        <>{render_camera()}</>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <div className="classContentHoriz">
                        {render_video()}
                        <div className="classHorizAbsAction">{render_camera()}</div>
                    </div>
                    <div className="horizActions">
                        {render_ab_ruler()}
                        {render_answers_prog()}
                    </div>
                </>
            );
        }
    };

    return (
        <MainLayout
            can_url={'/more-task'}
            id={`class_${rid}`}
            logo={logoImage}
            appMenuLogo={MenuImg}
            header_title={title}
        >
            <div className="classContent">{render()}</div>
        </MainLayout>
    );
}

export default MoreTask;
