import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './home_page/Home';
import Classroom from './classroom/Classroom';
import Wrapper from './wrapper';
import Class from './class/Class';
import Folder from './folder/Folder';
import { GoogleOAuthProvider } from '@react-oauth/google';
import NotFound from './common/layouts/NotFound';
import NiceToMeet from './common/layouts/static/NiceToMeet';
import FirstGrade from './common/layouts/static/FirstGrade';
import Contact from './common/layouts/static/Contact';
import MoreTasks from './more_tasks/moreTasks';
import MoreTask from './moreTask/MoreTask';
import Terms from './common/layouts/static/Term';
import Privacy from './common/layouts/static/Privacy';
import About from './common/layouts/static/About';
import PaymentRedirect from './common/payment/PaymentRedirect';
import Games from './games/Games';
import Game from './game/Game';
import LandingPageAlef from './landing/LandingPageAlef';
import AboutRoy from './common/layouts/static/AboutRoy';
import LandingPageSchool from './landing/LandingPageSchool';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const { REACT_APP_GOOGLE_ID } = process.env;
root.render(
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_ID ?? ''}>
        <Wrapper>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LandingPageAlef />} />
                    <Route path="/school" element={<LandingPageSchool />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="classroom" element={<Classroom />} />
                    <Route path="games" element={<Games />} />
                    <Route path="game" element={<Game />} />
                    <Route path="class" element={<Class />} />
                    <Route path="folder" element={<Folder />} />
                    <Route path="more-tasks" element={<MoreTasks />} />
                    <Route path="more-task" element={<MoreTask />} />
                    <Route path="nice-to-meet" element={<NiceToMeet />} />
                    <Route path="first-grade" element={<FirstGrade />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="terms" element={<Terms />} />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="payment_success" element={<PaymentRedirect isSuccessRedirect={true} />} />
                    <Route path="payment_fail" element={<PaymentRedirect isSuccessRedirect={false} />} />
                    <Route path="about-roy" element={<AboutRoy />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="about" element={<About />} />
                </Routes>
            </BrowserRouter>
        </Wrapper>
    </GoogleOAuthProvider>,
);
document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
