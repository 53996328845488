interface RhymeDict {
    [key: string]: Set<string>;
}

const balon = new Set<string>([
    'וילון',
    'אבי רון',
    'פעמון',
    'ביריון',
    'שעון',
    'שרון',
    'דאון',
    'עיפרון',
    'חילזון',
    'לימון',
    'רימון',
    'אפרסמון',
    'מלפפון',
    'גאון',
    'גרון',
    'חלון',
    'עפיפון',
    'אוירון',
    'מחירון',
    'ארון',
    'סלון',
    'חרמון',
    'חלמון',
    'עיתון',
    'בונבון',
    'טילון',
    'סילון',
    'דרקון',
    'מחשבון',
    'טלפון',
    'פלאפון',
    'אייפון',
    'המון',
    'שלגון',
    'גחון',
    'סבון',
    'ראשון',
    'גיליון',
    'גיבון',
    'פילון',
    'כלבלבון',
    'טיולון',
    'חברון',
    'מילון',
    'גגון',
    'גרביון',
    'מרתון',
    'סלמון',
    'פיון',
    'רון',
    'אדון',
    'שדון',
    'גלון',
    'יגון',
    'צנון',
    'לישון',
    'לשון',
    'אישון',
    'רעיון',
    'דביבון',
    'דובון',
    'מזרון',
    'מזרן',
    'טבעון',
    'מליון',
    'טריליון',
    'ביליון',
    'מיליון',
    'צימאון',
    'חידון',
    'שירון',
    'סיפורון',
    'ציפלון',
    'קטנטון',
    'פיצפון',
    'תימהון',
    'חרדון',
    'פיתון',
    'ילדון',
    'טירון',
    'קידון',
    'צידון',
    'גלגלון',
    'ממון',
    'ארמון',
    'עלון',
    'אלון',
    'אווירון',
    'שטיחון',
    'ספינון',
    'חולון',
    'קולון',
    'פזמון',
    'קלון',
    'פפיון',
    'ברון',
    'מטמון',
    'כישלון',
    'כישרון',
    'קרחון',
    'כשלון',
    'אשקלון',
    'קישון',
    'מלון',
    'אבי אורן',
    'אמיר אורן',
    'מעון',
    'מכון',
    'איילון',
    'אילון',
    'בית מלון',
    'פנתאון',
    'שיגעון',
    'מטבחון',
]);

const teeth = new Set<string>([
    'עיניים',
    'שעתיים',
    'יומיים',
    'שבועיים',
    'ציפורניים',
    'ברכיים',
    'מעיים',
    'ידיים',
    'לחיים',
    'שפתיים',
    'רגליים',
    'שוקיים',
    'אלפיים',
    'קרסוליים',
    'מספריים',
    'עניים',
    'נעליים',
    'גרביים',
    'כתפיים',
    'מותניים',
    'מאזניים',
    'ירכיים',
    'אופניים',
    'שניים',
    'אמריקאים',
    'מים',
    'משקפיים',
    'צהריים',
    'שתיים',
    'פעמיים',
    'גבעתיים',
    'ירושלים',
    'מכנסיים',
    'בנתיים',
    'בינתיים',
    'מעיים',
    'שמיים',
    'שנתיים',
    'מגפיים',
    'אוזניים',
    'מאזניים',
    'נחיריים',
    'עפעפיים',
    'כפליים',
    'שבעתיים',
    'ביניים',
    'אחוריים',
    'סוגריים',
    'חניכיים',
    'כיריים',
    'מלחציים',
    'מלקחיים',
    'נקודותיים',
    'ערביים',
    'קביים',
    'שוליים',
    'כנפיים',
    'כפיים',
    'מצילתיים',
    'מחניים',
    'גרשיים',
    'גפיים',
    'מצריים',
    'חיפזון',
]);

const rhymes: RhymeDict = {
    בלון: balon,
    חילזון: balon,
    שיניים: teeth,
};

export function useRhyme() {
    const check_rhyme = (rhyme: string, match: string): boolean => {
        console.log(match, rhyme);

        if (!(rhyme in rhymes)) return false;
        return rhymes[rhyme].has(match);
    };

    return { check_rhyme };
}
