import React from 'react';
import './WordDrag.css';
import Letter from 'src/common/buttons/Letter';
import { letterColors } from 'src/common/buttons/colors';

export interface IPuzzlePlace {
    size: number;
    left: number;
    top: number;
}

function WordDragPlace(props: IPuzzlePlace): JSX.Element {
    return (
        <div
            className="worddragPlaceHolder"
            style={{ top: props.top, left: props.left, width: props.size, height: props.size }}
        >
            <div className="worddragPlaceHolderInner">
                <Letter text={''} id={'letter'} zoom={props.size / 85.3} color={letterColors['gray']} />
            </div>
        </div>
    );
}

export default WordDragPlace;
