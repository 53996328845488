import React, { useContext } from 'react';
import { AppContext } from 'src/AppContext';
import { CourseName, init_app_state } from 'src/wrapper';
import { clearLoginStorage } from '../common';
import viSvg from 'src/assets/icons/small_vi.svg';
import xSvg from 'src/assets/icons/small_x.svg';
import lockSvg from 'src/assets/icons/small_lock.svg';
import { useAddress } from '../address/useAddess';
import messages from '../../assets/messages_he.json';
import { useNav } from '../useNav';
import { usePayment } from '../payment/usePayment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import rudiSvg from 'src/assets/images/rudi.svg';
import billySvg from 'src/assets/images/billy.svg';
import './HeaderAction.css';

export interface IUserProfile {
    closeUserMenu: () => void;
}

export function useUserProfile({ closeUserMenu }: IUserProfile) {
    const { state, setState } = useContext(AppContext);
    const { toggle_address_modal } = useAddress();
    const { navigateToHome } = useNav();
    const { toggle_payment_modal } = usePayment();

    const onLogOut = () => {
        closeUserMenu();
        clearLoginStorage();
        setState({ ...JSON.parse(JSON.stringify(init_app_state)), needSync: false });
        navigateToHome();
    };

    const render_course_details = (course: CourseName) => {
        if (course in state.courses) {
            return (
                <>
                    {render_course_progress_item(course)}
                    {render_gift_item(course)}
                </>
            );
        }
    };

    const render_course = (course: CourseName) => {
        const course_name = course === 'heb1' ? 'סיפור האותיות' : 'סיפור המספרים';
        const img_svg = course === 'heb1' ? rudiSvg : billySvg;
        return (
            <>
                <Accordion
                    sx={{
                        backgroundColor: '#E5F7FC',
                    }}
                >
                    <AccordionSummary
                        sx={{
                            margin: 0,
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={course_name}
                        id={course_name}
                    >
                        <img src={img_svg} alt="סיפור" height={50} width={50} />
                        <p className="UserMenuSubHeader">{course_name}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        {render_subscription_item(course in state.courses)}
                        {render_course_details(course)}
                    </AccordionDetails>
                </Accordion>
            </>
        );
    };

    const get_course_progress = (course: CourseName) => {
        const classes = state.courses[course].classes;
        let last = classes.length;
        for (let i = 0; i < classes.length; i++) {
            if (!classes[i].finished) {
                last = i + 1;
                break;
            }
        }
        return last;
    };

    const render_course_progress_item = (course: CourseName) => {
        const prog = get_course_progress(course);
        return (
            <div className="UserMenuItem">
                <p className="UserMenuItemHeader">{messages['user_profile.progress']}</p>
                <p className="UserMenuItemText">
                    שיעור <b style={{ color: '#0761A6' }}>{prog}</b> מתוך 8
                </p>
            </div>
        );
    };

    const render_subscription_item = (subscribed: boolean) => {
        const txt = subscribed ? messages['user_profile.subscription_on'] : messages['user_profile.subscription_off'];
        const svg = subscribed ? viSvg : xSvg;
        return (
            <div className="UserMenuItem">
                <p className="UserMenuItemHeader">{messages['user_profile.subscription']}</p>
                <img className="UserMenuIcon" src={svg} alt="תפריט"></img>
                <p className="UserMenuItemText">{txt}</p>
                {!subscribed && (
                    <>
                        {'-'}
                        <button
                            className="UserProfilePurchaseBtn"
                            aria-label={messages['purchase3']}
                            onClick={() => {
                                closeUserMenu();
                                toggle_payment_modal('on_sale');
                            }}
                        >
                            {messages['purchase3']}
                        </button>
                    </>
                )}
            </div>
        );
    };

    const render_gift_item = (course: CourseName) => {
        const text = state.courses[course].finished_course
            ? messages['user_profile.gift_sent']
            : messages['user_profile.gift_notfinished'];
        const iconSvg = state.courses[course].finished_course ? viSvg : lockSvg;
        return (
            <div
                id={'click'}
                className="UserMenuItem"
                onClick={() => {
                    closeUserMenu();
                    toggle_address_modal(course);
                }}
            >
                <p className="UserMenuItemHeader">{messages['user_profile.gift']}</p>
                <img className="UserMenuIcon" src={iconSvg} alt={text}></img>
                <p className="UserMenuItemText">{text}</p>
            </div>
        );
    };

    const render_logout_item = () => {
        return (
            <div id={'click'} className="UserMenuItem" style={{ border: 'none' }} onClick={onLogOut}>
                <p className="UserMenuItemHeader" style={{ textDecoration: 'underline', color: '#A3ABB1' }}>
                    התנתקות
                </p>
            </div>
        );
    };

    return {
        render_course,
        render_logout_item,
    };
}
