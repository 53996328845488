import React from 'react';
import logoImage from '../../assets/logo/white.svg';
import MenuImg from '../../assets/icons/yellowMenu.svg';
import mainImage from '../../assets/images/404.svg';
import MainLayout from './MainLayout';
import messages from '../../assets/messages_he.json';
import { useNav } from '../useNav';
import TextButton from '../buttons/TextButton';
import './NotFound.css';

function NotFound(): JSX.Element {
    const { navigateToLanding } = useNav();

    return (
        <MainLayout id={'notfound'} logo={logoImage} appMenuLogo={MenuImg}>
            <div className="nf_wrapper">
                <img src={mainImage} className={'nf_img'} alt="404 image" />
                <p className="nf_text">{messages['nf.text']}</p>
                <TextButton
                    onClick={navigateToLanding}
                    text={messages['nf.btn']}
                    color={'orangeAction'}
                    textColor={'white'}
                    fontSize={'1.5rem'}
                />
            </div>
        </MainLayout>
    );
}

export default NotFound;
