import React from 'react';
import './LogoButton.css';

interface ILogoButton {
    onClick: () => void;
    ariaLabel: string;
    svg: string;
    disabled?: boolean;
}

function LogoButton(props: ILogoButton): JSX.Element {
    return (
        <button className="logoBtn" onClick={props.onClick} disabled={props.disabled} aria-label={props.ariaLabel}>
            <img className="logoImg" src={props.svg} alt={props.ariaLabel} />
        </button>
    );
}

export default LogoButton;
