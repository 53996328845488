import { getAnalytics, logEvent } from 'firebase/analytics';
import { mqId } from './useMediaQueryWrap';
import messages from 'src/assets/messages_he.json';
import { CourseKey } from 'src/wrapper';

export const letters: string[] = [
    'א',
    'ב',
    'ג',
    'ד',
    'ה',
    'ו',
    'ז',
    'ח',
    'ט',
    'י',
    'כ',
    'ל',
    'מ',
    'נ',
    'ס',
    'ע',
    'פ',
    'צ',
    'ק',
    'ר',
    'ש',
    'ת',
];

export const numbers_to_10_backwards: string[] = ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1'];
export const numbers_to_20_backwards: string[] = [
    '20',
    '19',
    '18',
    '17',
    '16',
    '15',
    '14',
    '13',
    '12',
    '11',
    '10',
    '9',
    '8',
    '7',
    '6',
    '5',
    '4',
    '3',
    '2',
    '1',
];
export const number_to_10: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
export const number_to_10_reverse = ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1'];
export const number_to_20 = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
];
export const number_to_20_reverse = [
    '20',
    '19',
    '18',
    '17',
    '16',
    '15',
    '14',
    '13',
    '12',
    '11',
    '10',
    '9',
    '8',
    '7',
    '6',
    '5',
    '4',
    '3',
    '2',
    '1',
];

export const number_to_20_even_reverse = ['20', '18', '16', '14', '12', '10', '8', '6', '4', '2'];

export const get_txt_btn_font_size = (rid: mqId): string => {
    if (rid === 'mobile') return '1em';
    if (rid === 'tablet') return '1.25em';
    return '1.5em';
};

export const log_event = (event_name: string, course?: CourseKey) => {
    const log_prefix = course ? `${course}_` : '';
    const log = log_prefix + event_name;
    console.log(log);
    try {
        const analytics = getAnalytics();
        logEvent(analytics, log);
    } catch (e) {
        console.log('no analytics');
    }
};

export const openIbFacebook = () => {
    log_event('ib_facebook_click');
    window.open('https://www.facebook.com/DimyonBekufsa/');
};

export const openRoyFacebook = () => {
    log_event('roy_facebook_click');
    window.open('https://www.facebook.com/roy.geckt');
};

export const openRoyInstagram = () => {
    log_event('roy_instagram_click');
    window.open('https://www.instagram.com/roygeckt/');
};

export const openRoyTikTok = () => {
    log_event('roy_tiktok_click');
    window.open('https://www.tiktok.com/@roy_geckt');
};

export const openWhatsApp = () => {
    log_event('whatsapp_click');
    const number = '972585577191';
    const url = `https://api.whatsapp.com/send?phone=${number}&app_absent=0`;
    window.open(url);
};

export const sendMail = () => {
    log_event('mail_click');
    window.open(
        `mailto:${messages['contact.mail']}?subject=${encodeURIComponent(messages['contact.mailSubject']) || ''}`,
    );
};

export const clearLoginStorage = () => {
    localStorage.removeItem('ib_token');
    localStorage.removeItem('ib_picture');
    localStorage.removeItem('ib_name');
    localStorage.removeItem('ib_email');
};

export const is_reffered_by_soical = () => {
    if (document.referrer.includes('facebook')) return true;
    if (document.referrer.includes('instagram')) return true;
    return false;
};

export const is_in_app_broswer = () =>
    /FB_IAB/.test(navigator.userAgent) ||
    /FBAN/.test(navigator.userAgent) ||
    /FBAV/.test(navigator.userAgent) ||
    /Instagram/.test(navigator.userAgent);

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
};

export const scroll_to_element = (ele_id: string) => {
    const elem = document.getElementById(ele_id);
    if (!elem) return;
    elem.scrollIntoView();
};
