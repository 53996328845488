import React, { useContext } from 'react';
import messages from '../assets/messages_he.json';
import TextButton from '../common/buttons/TextButton';
import penSvg from '../assets/drawings/pen.svg';
import bookSvg from '../assets/drawings/book.svg';
import paintSvg from '../assets/drawings/paint.svg';
import eraserSvg from '../assets/drawings/Eraser.svg';
import rudyImg from 'src/assets/images/card-rudy.svg';
import billyImg from 'src/assets/images/card-billy.svg';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { AppContext } from '../AppContext';
import { get_txt_btn_font_size, log_event } from '../common/common';
import { useNav } from 'src/common/useNav';
import { usePayment } from 'src/common/payment/usePayment';
import { useLogin } from 'src/common/login/useLogin';
import { CourseName } from 'src/wrapper';
import { useLocation } from 'react-router-dom';
import HomeCard from './HomeCard';

export function useHome() {
    const { state, setState } = useContext(AppContext);
    const { getMQid, render_float_anim } = useMediaQueryWrap();
    const { navigateToClassroom } = useNav();
    const { toggle_payment_modal } = usePayment();
    const { toggle_login_modal } = useLogin();
    const location = useLocation();
    const rid = getMQid();

    const render_drawings = () => {
        if (rid === 'mobile' || !render_float_anim) return null;
        return (
            <>
                <img src={penSvg} className="homePen float float-delay" alt="pen animation" />
                <img src={bookSvg} className="homeBook float float-delay" alt="book animation" />
                <img src={paintSvg} className="homePaint float" alt="paint animation" />
                <img src={eraserSvg} className="homeEraser float float-delay" alt="eraser animation" />
            </>
        );
    };

    const on_course_click = (course: CourseName) => {
        if (!state.subscribed) {
            log_event(`home_payment_modal_${course}`);
            toggle_payment_modal(course);
            return;
        }
        if (!(course in state.courses)) {
            log_event(`home_payment_modal_on_sale`);
            toggle_payment_modal('on_sale');
            return;
        }

        setState({ ...state, course_key: course, curr_game_group_idx: -1, curr_game_idx: -1 });
        const exclude_from_navigating_to_classroom = ['/classroom', '/more-tasks', '/games', '/folder'];
        if (!exclude_from_navigating_to_classroom.includes(location.pathname)) navigateToClassroom();
    };

    const render_login = () => {
        if (!state.subscribed)
            return (
                <div id={rid} className="homeButtonSingleWrapper">
                    <TextButton
                        onClick={() => toggle_login_modal(true)}
                        text={messages['home.login']}
                        color={'orangeAction'}
                        textColor={'white'}
                        fontSize={get_txt_btn_font_size(rid)}
                    />
                </div>
            );
    };

    const render_course_cards = () => {
        return (
            <section className="SectionCards">
                <HomeCard
                    image={rudyImg}
                    title={messages['course.heb1_card']}
                    onClick={() => on_course_click('heb1')}
                />
                <HomeCard
                    image={billyImg}
                    title={messages['course.math1_card']}
                    onClick={() => on_course_click('math1')}
                />
            </section>
        );
    };

    return {
        render_course_cards,
        render_drawings,
        on_course_click,
        render_login,
    };
}
