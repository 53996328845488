import React from 'react';
import animationData from '../assets/anim/loading.json';
import Lottie from 'lottie-react';
import { useMediaQueryWrap } from './useMediaQueryWrap';

function Loading(): JSX.Element {
    const { is_horiz } = useMediaQueryWrap();
    const height = is_horiz ? '100vh' : '60vh';
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: height }}>
            <Lottie loop={true} autoPlay animationData={animationData} />
        </div>
    );
}

export default Loading;
