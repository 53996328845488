import React from 'react';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { useNav } from '../common/useNav';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import rabitSvg from '../assets/drawings/Rabbit2.svg';
import billySvg from 'src/assets/images/billy.svg';
import penSvg from '../assets/drawings/pen2.svg';
import appleSvg from '../assets/drawings/apple.svg';
import baloonSvg from '../assets/drawings/baloon.svg';
import tubeSvg from '../assets/drawings/tube.svg';
import paintSvg from '../assets/drawings/paint2.svg';
import { IClass } from '../wrapper';
import { uploadValue } from '../common/api/server.api';
import { log_event } from '../common/common';
import { classes_metadata } from 'src/metadata';
import { useServer } from 'src/common/api/useServer';
import { usePayment } from 'src/common/payment/usePayment';

export function useClassroom() {
    const { state, setState } = useContext(AppContext);

    const { navigateToClass, navigateToHome } = useNav();
    const { getMQid, render_float_anim } = useMediaQueryWrap();
    const rid = getMQid();
    const { handle_no_token_err } = useServer();
    const { toggle_payment_modal } = usePayment();

    React.useEffect(() => {
        if (state.course_key === '') navigateToHome();
    }, []);

    const get_classroom_list = (): IClass[] => {
        if (state.course_key in state.courses) return state.courses[state.course_key].classes;
        else return state.locked_classes;
    };

    const selectClass = (class_idx: number) => {
        if (!(state.course_key in state.courses) && state.course_key != '') {
            log_event(`classroom_payment_modal_${state.course_key}`);
            const payment_item = state.subscribed ? 'on_sale' : state.course_key;
            toggle_payment_modal(payment_item);
            return;
        }
        const token = localStorage.getItem('ib_token');
        if (!token) return;

        const courses = { ...state.courses };
        courses[state.course_key].curr_class_idx = class_idx;

        setState({ ...state, courses: courses });
        uploadValue(token, 'curr_class_idx', class_idx, state.course_key, handle_no_token_err);
        log_event(`class_select_${class_idx}`, state.course_key);
        navigateToClass();
    };

    const render_drawings = () => {
        if (rid === 'mobile' || !render_float_anim) return null;
        return (
            <>
                {state.course_key === 'heb1' && <img src={rabitSvg} className="crRabbit float" alt="animation" />}
                {state.course_key === 'math1' && <img src={billySvg} className="crRabbit float" alt="animation" />}
                <img src={penSvg} className="crPen float float-delay" alt="pen animation" />
                <img src={appleSvg} className="crApple float float-delay" alt="apple animation" />
                <img src={baloonSvg} className="crBaloon float float" alt="baloon animation" />
                <img src={tubeSvg} className="crTube float float-delay" alt="tube animation" />
                <img src={paintSvg} className="crPaint float float-delay" alt="paint animation" />
            </>
        );
    };

    const get_class_progress = (item: IClass): number => {
        if (item.locked) return 0;
        if (item.finished) return 100;
        if (state.course_key === '') return 0;

        const curr_class_idx = state.courses[state.course_key].curr_class_idx;
        const class_tasks = classes_metadata[state.course_key][curr_class_idx].tasks;
        return (item.taskIndex / class_tasks.length) * 100;
    };

    return { selectClass, get_class_progress, render_drawings, get_classroom_list };
}
