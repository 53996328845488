import React, { useContext } from 'react';
import CloseIcon from '../../assets/icons/close_red.svg';
import backIcon from '../../assets/icons/back.svg';
import googleSvg from '../../assets/icons/google.svg';
import rabbitSvg from '../../assets/images/rabbit_login.svg';
import messages from '../../assets/messages_he.json';
import { useMediaQueryWrap } from '../useMediaQueryWrap';
import { get_txt_btn_font_size } from '../common';
import TextButton from '../buttons/TextButton';
import { useLogin } from './useLogin';
import { AppContext } from '../../AppContext';
import MainModal from '../layouts/MainModal';
import Divider from '@mui/material/Divider';
import MailSvg from 'src/assets/icons/mail.svg';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { Alert, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

import './LoginModal.css';

type subPageType = 'main' | 'email_password' | 'forgot_password';

function LoginModal(): JSX.Element {
    const { state } = useContext(AppContext);
    const { getMQid } = useMediaQueryWrap();
    const {
        googleLoginClick,
        emailPasswordLoginClick,
        toggle_login_modal,
        loginErr,
        setLoginErr,
        email,
        setPassword,
        setEmail,
        can_login_via_email_password,
        can_send_reset_password,
        reset_password,
        successMsg,
        setSuccMsg,
        onResetApi,
    } = useLogin();

    const rid = getMQid();
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const [subPage, setSubPage] = React.useState('main' as subPageType);

    const render_email_password_only = () => {
        return (
            <>
                <form className="login_emails_form">
                    <TextField
                        id="email_password"
                        required
                        label={'אימייל'}
                        defaultValue={email}
                        variant="outlined"
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <FormControl required variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">סיסמא</InputLabel>
                        <OutlinedInput
                            onChange={(e) => setPassword(e.target.value)}
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="off"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="סיסמא"
                        />
                    </FormControl>
                </form>
                <div className="forgot_pass_wrap">
                    <TextButton
                        onClick={() => setSubPage('forgot_password')}
                        text={'?שכחתם סיסמא'}
                        textColor={'#0761A6'}
                        fontSize={'1em'}
                        fontWeight={400}
                        width="fit-content"
                        padding="0"
                    />
                </div>

                <TextButton
                    disabled={!can_login_via_email_password()}
                    onClick={() => emailPasswordLoginClick()}
                    text={'התחברות'}
                    color={'white'}
                    textColor={'#75828B'}
                    fontSize={get_txt_btn_font_size(rid)}
                    icon={MailSvg}
                    shadow={'0px 2px 11px rgba(0, 0, 0, 0.12)'}
                    fontWeight={400}
                    width="95%"
                />
            </>
        );
    };

    const render_forgot_password_only = () => {
        return (
            <>
                <form className="login_emails_form">
                    <TextField
                        id="email_password"
                        required
                        label={'אימייל'}
                        defaultValue={email}
                        variant="outlined"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </form>

                <TextButton
                    disabled={!can_send_reset_password()}
                    onClick={() => reset_password()}
                    text={'איפוס סיסמא'}
                    color={'white'}
                    textColor={'#75828B'}
                    fontSize={get_txt_btn_font_size(rid)}
                    icon={MailSvg}
                    shadow={'0px 2px 11px rgba(0, 0, 0, 0.12)'}
                    fontWeight={400}
                    width="95%"
                />
            </>
        );
    };

    const render_main = () => {
        return (
            <>
                <TextButton
                    onClick={() => googleLoginClick()}
                    text={messages['login.modal.textButton']}
                    color={'white'}
                    textColor={'#75828B'}
                    fontSize={get_txt_btn_font_size(rid)}
                    icon={googleSvg}
                    shadow={'0px 2px 11px rgba(0, 0, 0, 0.12)'}
                    fontWeight={400}
                    width="95%"
                />
                <Divider variant="middle" style={{ width: '100%', color: 'gray' }}>
                    או
                </Divider>
                <TextButton
                    onClick={() => setSubPage('email_password')}
                    text={'התחברות עם מייל וסיסמא'}
                    color={'white'}
                    textColor={'#75828B'}
                    fontSize={get_txt_btn_font_size(rid)}
                    icon={MailSvg}
                    shadow={'0px 2px 11px rgba(0, 0, 0, 0.12)'}
                    fontWeight={400}
                    width="95%"
                />
            </>
        );
    };

    const onCloseBackBtn = () => {
        if (subPage === 'main') toggle_login_modal(false);
        if (subPage === 'email_password') setSubPage('main');
        if (subPage === 'forgot_password') setSubPage('email_password');
    };

    return (
        <MainModal
            id={'login'}
            open={state.openLoginModal}
            close={onCloseBackBtn}
            closeIcon={subPage === 'main' ? CloseIcon : backIcon}
        >
            <div className="login_modal_wrap">
                <p id={rid} className={'login_modal_header'}>
                    {messages['login.modal.title']}
                </p>
                <p id={rid} className={'login_modal_subtextr'}>
                    {messages['login.modal.subtext']}
                </p>
                <img style={{ marginTop: '1.5em' }} src={rabbitSvg} alt="rabbit image" />
                <div className={'login_modal_bottom'}>
                    {subPage === 'main' && render_main()}
                    {subPage === 'email_password' && render_email_password_only()}
                    {subPage === 'forgot_password' && render_forgot_password_only()}
                    {(state.dur_login || onResetApi) && (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress color={'error'} />
                        </Box>
                    )}
                </div>
                <Snackbar
                    open={loginErr !== ''}
                    autoHideDuration={3500}
                    onClose={() => setLoginErr('')}
                    message={loginErr}
                />
                <Snackbar open={successMsg !== ''} autoHideDuration={5000} onClose={() => setSuccMsg('')}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {successMsg}
                    </Alert>
                </Snackbar>
            </div>
        </MainModal>
    );
}

export default LoginModal;
