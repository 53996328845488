import React from 'react';
import Roy from '../../assets/images/roy_about.webp';
import Wave from '../../assets/images/wave.svg';

import './sec-about.css';

function SecAbout() {
    return (
        <section className="sec-about bg-blue-light py-4 over-hide relative">
            <div id="SecAbout" className="anchor"></div>
            <div className="container flex justify-between">
                <div className="m-100 w-50 m-text-center">
                    <div className="about-img-wrap relative inline text-center">
                        <img className="wave float1" src={Wave} alt="Wave" />
                        <div className="about-img relative">
                            <div className="about-name text-white">
                                <h2 className="text-xl font-bold">רוי גקט</h2>
                                <div className="text-base">מעל 12 שנות נסיון בחינוך לגיל הרך</div>
                            </div>
                            <img className="img-cover" src={Roy} alt="Roy" />
                        </div>
                    </div>
                </div>
                <div className="m-100 w-50 desk-pr-1">
                    <h2 className="title-42">קצת עלי</h2>
                    <div className="text-xl font-medium mb-1">
                        שמי רוי גקט, אבל כנראה שאתם מכירים אותי בתור רוי הגנן מתכנית הטלויזיה ״החיים הסודיים של בני 5״.
                    </div>
                    <div>
                        <p>
                            בעל תואר ראשון בחינוך עם התמחות בחינוך הגיל הרך, ותואר שני MA בניהול מערכות חינוך (סמינר
                            הקיבוצים).
                        </p>
                        <p>
                            את דרכי במערכת החינוך התחלתי לפני 12 שנים בתפקיד מחנך כיתות א׳-ב׳ בתל אביב. כיום, מנהל את
                            מקבץ ״גני אשכול״ לגיל הרך בחיפה ועומד להתחיל את השנה השמינית שלי בתור גנן לגילאי 6-5 - חובה
                            וטרום חובה.
                        </p>
                        <br></br>
                        <p>
                            לאורך השנים הבנתי כי הלמידה המשמעותית ביותר מתקיימת דרך משחק. משחקים המשלבים חידות, אתגרים
                            ודמיון מאפשרים לילדים לעבור חוויה שהופכת את התהליך למוצלח, וביננו? גם אנחנו המבוגרים מעדיפים
                            למידה מתוך חוויות ואתגרים על פני שינון של חומר.
                        </p>
                        <br></br>
                        <p>
                            חינכתי מאות ילדים בעשור האחרון ובאמצעות שיטת הלימוד שלי הם לא רק נהנו ולמדו, אלא גם חיזקו את
                            ביטחונם העצמי ואת תחושת המסוגלות שלהם. כך, הפניות הרגשית שלהם ליצירת קשרים חברתיים (שאינם
                            פחות חשובים בגיל הזה) גדלה. תמיד רציתי להעניק מעצמי ולהגיע לעוד ועוד ילדים. ניסיתי למצוא דרך
                            להגיע לכל ילד וילדה בישראל בצורה אישית וחווייתית, כך שירגישו כאילו הם ממש איתי בגן. 
                        </p>
                        <br></br>
                        <p>
                            כך נולד אתר ״דמיון בקופסא״ בו אני מלווה את הילדים בהרפתקה של רודי הארנב ובילי החתולה, מדבר
                            איתם, מפעיל אותם ומקנה להם את אותם כלים שאפשרו למאות הילדים שחינכתי להגיע בביטחון לכיתה א׳.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SecAbout;
