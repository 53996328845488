import React from 'react';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { log_event } from '../common/common';
import { deleteFolderImg } from '../common/api/server.api';
import { useNav } from 'src/common/useNav';
import { classes_main_tasks_indices } from 'src/metadata';
import { useServer } from 'src/common/api/useServer';
import { usePayment } from 'src/common/payment/usePayment';
import { IImage } from 'src/wrapper';

export function useFolder() {
    const { state, setState } = useContext(AppContext);
    const { navigateToClass, navigateToHome } = useNav();
    const { handle_no_token_err } = useServer();
    const { toggle_payment_modal } = usePayment();
    const [selectedSubFolder, setSelectedSubFolder] = React.useState<IImage[]>([]);
    const [emptyFolderAlert, setEmptyFolderAlert] = React.useState(false);

    React.useEffect(() => {
        if (state.course_key === '') navigateToHome();
    }, []);

    const onSubFolderClick = (sub_folder: string) => {
        if (!(state.course_key in state.courses) && state.course_key != '') {
            log_event(`folder_payment_modal_${state.course_key}`);
            const payment_item = state.subscribed ? 'on_sale' : state.course_key;
            toggle_payment_modal(payment_item);
            return;
        }

        const filtered = state.courses[state.course_key].img_folder.filter((image) => image.sub_folder === sub_folder);
        setSelectedSubFolder(filtered);
        if (filtered.length === 0) setEmptyFolderAlert(true);
    };

    const deleteFolderImage = (img_id: string) => {
        log_event('delete_image_click', state.course_key);

        const token = localStorage.getItem('ib_token');
        if (!token) return;
        if (!(state.course_key in state.courses)) return;

        const courses = { ...state.courses };
        const new_folder = courses[state.course_key].img_folder.filter((i) => i.id !== img_id);
        courses[state.course_key].img_folder = new_folder;

        setState({ ...state, courses: courses });

        deleteFolderImg(token, img_id, handle_no_token_err);
    };

    const goToMainTaskInClass = (class_idx: number) => {
        if (state.course_key === '') return;
        if (!(state.course_key in state.courses)) return;
        const main_task_idx = classes_main_tasks_indices[state.course_key][class_idx];

        const courses = { ...state.courses };
        const curr_course = courses[state.course_key];
        curr_course.curr_class_idx = class_idx;

        const classes = curr_course.classes;
        const curr_class = classes[class_idx];
        curr_class.taskIndex = main_task_idx;

        setState({ ...state, courses: courses });

        log_event(`main_task_in_class_${class_idx}_click`, state.course_key);
        navigateToClass();
    };

    return {
        deleteFolderImage,
        goToMainTaskInClass,
        selectedSubFolder,
        setSelectedSubFolder,
        emptyFolderAlert,
        setEmptyFolderAlert,
        onSubFolderClick,
    };
}
