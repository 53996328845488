import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { useNav } from '../common/useNav';
import { log_event } from '../common/common';
import React from 'react';
import { usePayment } from 'src/common/payment/usePayment';

export function useGames() {
    const { state, setState } = useContext(AppContext);
    const { navigateToAGame, navigateToHome } = useNav();
    const { toggle_payment_modal } = usePayment();

    React.useEffect(() => {
        if (state.course_key === '') navigateToHome();
    }, []);

    const selectGameGroup = (group_idx: number) => {
        if (!(state.course_key in state.courses) && state.course_key != '') {
            log_event(`games_payment_modal_${state.course_key}`);
            const payment_item = state.subscribed ? 'on_sale' : state.course_key;
            toggle_payment_modal(payment_item);
            return;
        }
        setState({ ...state, curr_game_group_idx: group_idx });
        document.getElementById('mlGridWrapper')?.classList.add('withAnimation');
    };

    const selectGame = (game_idx: number) => {
        setState({ ...state, curr_game_idx: game_idx });
        log_event(`game_select_${state.curr_game_group_idx}_${game_idx}`, state.course_key);
        navigateToAGame();
    };

    const resetGameSelection = () => {
        setState({ ...state, curr_game_group_idx: -1, curr_game_idx: -1 });
        document.getElementById('mlGridWrapper')?.classList.remove('withAnimation');
    };

    return { selectGameGroup, resetGameSelection, selectGame };
}
