import React from 'react';
import errSound from '../../assets/sounds/error.mp3';
import sccSound from '../../assets/sounds/success1.mp3';

import useSound from 'use-sound';
import Letter from '../../common/buttons/Letter';
import { letterColors } from '../../common/buttons/colors';
import './Answers.css';

interface IAnswers {
    answers: string[];
    correct: string;
    onCorrect: () => void;
    zoom: number;
    successSound?: boolean;
}

function Answers(props: IAnswers): JSX.Element {
    const [playError] = useSound(errSound, { volume: 0.5 });
    const [playSuccess] = useSound(sccSound, { volume: 0.2 });

    const onClick = (ans: string) => {
        if (ans == props.correct) {
            if (props.successSound) playSuccess();
            props.onCorrect();
        } else playError();
    };

    return (
        <div className="answers_wrap">
            {props.answers.map((ans, index) => (
                <Letter
                    id={'number'}
                    key={index}
                    text={ans}
                    onClick={() => onClick(ans)}
                    zoom={props.zoom}
                    color={letterColors['blue']}
                />
            ))}
        </div>
    );
}

export default Answers;
