import React from 'react';
import HeorIc1 from '../../assets/images/hero-ic-1.svg';
import HeorIc2 from '../../assets/images/hero-ic-2.svg';
import HeorIc3 from '../../assets/images/hero-ic-3.svg';
import HeorIc4 from '../../assets/images/hero-ic-4.svg';
import phoneImg from '../../assets/images/new-phone.webp';
import { log_event, scroll_to_element } from 'src/common/common';

import './hero.css';

interface secProps {
    primBtnText: string;
}

function SectionTwoSchool({ primBtnText }: secProps) {
    return (
        <section className="hero relative over-hide" id="hero">
            <div className="container flex z-1">
                <div className="hero-content mb-1">
                    <h1 className="title-42 text-yellow">כך הילדים של היום אוהבים ללמוד</h1>
                    <div className="text-white">
                        <p>
                            יש להם מספיק חוברות עבודה מיושנות השייכות לשנות ה-90! יש היום דרך הרבה יותר מעניינת ויעילה
                            ללמוד.
                        </p>
                        <br></br>
                        <p>
                            נוציא את הדמיון מהקופסא ונעלה על דרך חדשנית, דיגיטלית ואינטראקטיבית, בעזרתה ילדיכם יהפכו
                            לאלופי המספרים, האותיות והצלילים. הם ירכשו את הכלים החשובים ביותר לקראת לימודי החשבון,
                            הקריאה והכתיבה דרך הרפתקה ומשחק.
                        </p>
                        <br></br>
                        <p className="font-bold text-xl font-medium">
                            חוויית AI אינטראקטיבית מיוחדת במינה: אני שואל, הילדים עונים למיקרופון, ומתקדמים לפי התשובה
                            שלהם!
                        </p>
                        <br></br>
                        <p>
                            שיעורי הקורסים מועברים דרך סיפורים ואנימציה שילדים אוהבים. ילדיכם יבצעו מגוון משימות,
                            באמצעותן יתקדמו בסיפור ויבצעו פעולות יצירתיות כמו לפתור חידות, לדבר איתי במיקרופון, לצייר,
                            לכתוב, לצלם ועוד. הם יהפכו לדמות מרכזית ואפילו לא ישימו לב שהם לומדים!
                        </p>
                        <br></br>
                        <p>
                            הקורסים האינטראקטיביים האלה <span className="font-bold">יחסכו לכם אלפי שקלים</span> שהייתם
                            משקיעים בשיעורים פרטיים או בקורסים פרונטליים, אותם אפשר לעשות רק פעם אחת.
                        </p>
                        <br></br>

                        <p>סיפורי דמיון בקופסא כוללים את כל המרכיבים שיש בקורס פרונטלי ואף יותר.</p>
                        <p className="font-bold"> תוכלו לעשות אותם שוב ושוב ללא הגבלה, ואפילו להעביר מילד לילד!</p>
                        <br></br>

                        <div className="text-yellow">
                            <p className="font-bold">בשביל הילדים זה משחק,</p>
                            <p className="font-bold">בשביל ההורים זה חינוך</p>
                        </div>
                        <button
                            className="bt-primary green"
                            onClick={() => {
                                scroll_to_element('SecSell');
                                log_event('landing_payment_modal2');
                            }}
                        >
                            {primBtnText}
                        </button>
                    </div>
                </div>
                <div className="hero-hold-img m-100 text-center">
                    <div className="hero-img-wrap relative">
                        <div>
                            <img src={HeorIc1} className="hero-icon z-2" role="presentaition" />
                            <img src={HeorIc2} className="hero-icon z-2" role="presentaition" />
                            <img src={HeorIc3} className="hero-icon z-2" role="presentaition" />
                            <img src={HeorIc4} className="hero-icon z-2" role="presentaition" />
                        </div>
                        <img src={phoneImg} className="hero-boy z-2 float1" alt="תמונה מהקורס" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionTwoSchool;
