import React, { useState } from 'react';
import { AppContext } from './AppContext';
import Loading from './common/Loading';
import { getUserState, user2state } from './common/api/server.api';
import { initializeApp } from 'firebase/app';
import { clearLoginStorage, log_event } from './common/common';

const {
    REACT_APP_FIREBASE_KEY,
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_BUCKET,
    REACT_APP_MESSAGING_SENDER_ID,
    REACT_APP_ID,
    REACT_APP_MEASUREMENT_ID,
} = process.env;

export interface IImage {
    img_src: string;
    sub_folder: string; // tip / moretask / class number / 'promo'
    title: string;
    id: string;
}

export type abFixType = 'suffix' | 'prefix';

export interface IWordFixMatch {
    ab: string;
    type: abFixType;
}

export interface IMicTask {
    word_match?: string[];
    rhyme?: string;
    ab_match?: string;
    word_fix_match?: IWordFixMatch;
    force?: boolean;
}

export interface IProgressVideo {
    video_stops: number[];
    tasks: IProggressTask[];
    ab_ruler: boolean;
}

export type IProggressTask = Pick<ITask, 'micTask' | 'ab_highlight' | 'ab_type' | 'answers' | 'none'>;

export interface IAnswerTask {
    answers: string[];
    correct: string;
}

export type puzzlleLvl = 1 | 2 | 3 | 4 | 5;

export interface IPuzzleTask {
    lvl: puzzlleLvl;
    letter_type: ILetterId;
}

export type cameraFinish = 'folder' | 'classroom' | 'none';
export interface ICameraTask {
    finish_to: cameraFinish; // if camera task is last - set where to go after
}

export type ILetterId = 'number' | 'letter';

export interface ITask {
    video_url: string;
    progressVideo?: IProgressVideo;
    render_time?: number;

    // feature-tasks:render at render time if its on
    micTask?: IMicTask;
    cameraTask?: ICameraTask;
    ab_highlight?: string; // highlight letter
    ab_select?: number; // select letter - amount specify how many
    ab_ruler_match?: string[];
    ab_type?: ILetterId; // must come with either of the ab_tasks above
    answers?: IAnswerTask;
    puzzle?: IPuzzleTask;
    maze?: IPuzzleTask;
    paintMaze?: number;
    memory_cards?: number;
    wordDragGame?: string;
    cashGame?: string;
    fillAddress?: boolean;
    instructions?: string;

    // render at the end
    pressNext?: boolean; // next button
    pressBack?: boolean; // back button - by default we render allways

    // render during
    pressNextDur?: boolean; // next button

    // some logic
    none?: boolean; // no task
    ab_select_finish?: boolean;
    ab_select_hold?: boolean; // holds previous selction of letters
    finish_to_classroom?: boolean;
    finish_to_folder?: boolean;
    successSound?: boolean;
}

export interface IClass {
    locked: boolean;
    taskIndex: number; // progress of class
    finished?: boolean;
}

export interface IClassMetadata {
    materials: string[];
    time_len: string;
    tasks: ITask[];
    title: string;
    img: string;
}

export interface IMoreTask {
    locked: boolean; // saved in server but unused
    progress: number;
}

export interface IMoreTaskMetadata {
    materials: string[];
    title: string;
    video_url?: string;
    img: string;
    static_ab_ruler?: ILetterId;
    prog_answers_task?: ITask;
}

export type CourseName = 'heb1' | 'math1';
export type CourseKey = CourseName | '';
export type PaymentItem = CourseName | 'close_modal' | 'all' | 'on_sale';
export type AddressItem = CourseName | 'close_modal';
export type CourseDict = {
    [key in string]: ICourse;
};

export const payment_deatails = {
    all: { price: 397, name: 'צמד חמד חובה ל-א׳' },
    heb1: { price: 297, name: 'קורס האותיות והצלילים' },
    math1: { price: 297, name: 'קורס חשבון ומספרים' },
    on_sale: { price: 159, name: 'קורס שני בהנחה' },
};

export const course_key_to_page_headers = {
    heb1: 'סיפור האותיות',
    math1: 'סיפור המספרים',
    '': '',
};

export interface ICourse {
    classes: IClass[];
    more_tasks: IMoreTask[];
    curr_more_task_idx: number;
    curr_class_idx: number;
    img_folder: IImage[];
    finished_course: boolean;
    date_paid?: Date;
}

export interface IAppState {
    // user data
    courses: CourseDict;
    address: IAddress;
    // app data
    course_key: CourseKey;
    subscribed: boolean;
    curr_game_idx: number;
    curr_game_group_idx: number;
    needSync: boolean;
    dur_login: boolean;
    cameraOpen: boolean;
    paymentStatus: PaymentItem;
    adressStatus: AddressItem;
    openLoginModal: boolean;
    // default locked data
    locked_classes: IClass[];
}

export interface IAddress {
    name: string;
    last_name: string;
    city: string;
    street: string;
}

export interface IUserState {
    courses: CourseDict;
    address?: IAddress;
}

const locked_classes_init: IClass[] = Array(8).fill({
    locked: true,
    taskIndex: 0,
});

export const init_app_state: IAppState = {
    courses: {},
    address: {
        name: '',
        last_name: '',
        city: '',
        street: '',
    },
    course_key: '',
    subscribed: false,
    needSync: true,
    dur_login: false,
    paymentStatus: 'close_modal',
    openLoginModal: false,
    cameraOpen: false,
    adressStatus: 'close_modal',
    curr_game_idx: -1,
    curr_game_group_idx: -1,
    locked_classes: locked_classes_init,
};

const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
    appId: REACT_APP_ID,
    measurementId: REACT_APP_MEASUREMENT_ID,
};

export interface IGame {
    task?: ITask;
    mul_tasks?: ITask[];
    title: string;
    img: string;
}

export interface IGameGroup {
    games: IGame[];
    title: string;
    img: string;
    desc: string;
}

const Wrapper = (props: any) => {
    const [state, setState] = useState<IAppState>(JSON.parse(JSON.stringify(init_app_state)));

    const syncState = (user_data?: IUserState) => {
        // server err - sent undef. e.g.: no token (switched device / rotated)
        if (!user_data) {
            log_event('sync_state_no_user_data');
            clearLoginStorage();
            setState({
                ...state,
                needSync: false,
            });
            return;
        }
        if (Object.keys(user_data).length) {
            const new_state = user2state(user_data, state);
            setState({
                ...state,
                ...new_state,
                needSync: false,
            });
            // console.log('syncState', new_state);
        } else {
            // empty dict sent by server: not sure can happen again
            log_event('sync_state_no_email');
            clearLoginStorage();
            setState({
                ...state,
                needSync: false,
            });
        }
    };

    React.useEffect(() => {
        try {
            initializeApp(firebaseConfig);
        } catch (e) {
            console.log(e);
        }

        if (!state.needSync) return;
        if (state.dur_login) return;

        const token = localStorage.getItem('ib_token');
        if (!token) {
            clearLoginStorage();
            setState({
                ...state,
                needSync: false,
            });
            return;
        }

        getUserState(token, syncState);
    }, []);

    const render = () => {
        if (state.needSync) return <Loading />;
        else return props.children;
    };

    return <AppContext.Provider value={{ state, setState }}>{render()}</AppContext.Provider>;
};

export default Wrapper;
