import React, { useRef } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import img1 from '../../assets/images/reco/1.webp';
import img2 from '../../assets/images/reco/2.webp';
import img3 from '../../assets/images/reco/3.webp';
import img4 from '../../assets/images/reco/4.webp';
import img5 from '../../assets/images/reco/5.webp';
import img6 from '../../assets/images/reco/6.webp';
import img7 from '../../assets/images/reco/7.webp';
import img8 from '../../assets/images/reco/8.webp';
import img9 from '../../assets/images/reco/9.webp';
import img10 from '../../assets/images/reco/10.webp';
import img11 from '../../assets/images/reco/11.webp';
import img12 from '../../assets/images/reco/12.webp';
import img13 from '../../assets/images/reco/13.webp';
import img14 from '../../assets/images/reco/14.webp';
import img15 from '../../assets/images/reco/15.webp';
import img16 from '../../assets/images/reco/16.webp';
import img17 from '../../assets/images/reco/17.webp';
import img18 from '../../assets/images/reco/18.webp';
import img19 from '../../assets/images/reco/19.webp';
import img20 from '../../assets/images/reco/20.webp';
import img21 from '../../assets/images/reco/21.webp';
import img22 from '../../assets/images/reco/22.webp';
import img23 from '../../assets/images/reco/23.webp';
import img24 from '../../assets/images/reco/24.webp';

import img25 from '../../assets/images/reco/25.webp';
import img26 from '../../assets/images/reco/26.webp';
import img27 from '../../assets/images/reco/27.webp';
import img28 from '../../assets/images/reco/28.webp';
import img29 from '../../assets/images/reco/29.webp';
import img30 from '../../assets/images/reco/30.webp';
import img31 from '../../assets/images/reco/31.webp';
import img32 from '../../assets/images/reco/32.webp';
import img33 from '../../assets/images/reco/33.webp';
import img34 from '../../assets/images/reco/34.webp';

import img35 from '../../assets/images/reco/35.webp';
import img36 from '../../assets/images/reco/36.webp';
import img37 from '../../assets/images/reco/37.webp';
import img38 from '../../assets/images/reco/38.webp';
import img39 from '../../assets/images/reco/39.webp';

import 'swiper/css';
import 'swiper/css/navigation';
import './sec-recom.css';
// import { log_event } from 'src/common/common';

const reco_images = [
    img30,
    img34,
    img29,
    img32,
    img24,
    img25,
    img33,
    img20,
    img11,
    img10,
    img17,
    img18,
    img19,
    img21,
    img12,
    img13,
    img14,
    img15,
    img16,
    img2,
    img1,
    img5,
    img3,
    img4,
    img6,
    img7,
    img9,
    img8,
    img22,
    img23,
    img26,
    img27,
    img28,
    img31,
    img35,
    img36,
    img37,
    img38,
    img39,
];

SwiperCore.use([Navigation]);

// interface secRecomProps {
//     primBtnText: string;
// }

function SecRecom() {
    const swiperRef = useRef<any>(null);

    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.slideNext();
        }
    };
    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.slidePrev();
        }
    };

    const swiperOptions = {
        navigation: {
            nextEl: '.swiper-button-prev',
            prevEl: '.swiper-button-next',
        },
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: 18,
        loop: true,
        onSwiper: (swiper: any) => {
            swiperRef.current = swiper;
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 18,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 22,
            },
        },
    };

    return (
        <section className="sec-recom py-4 bg-yellow relative">
            <div id="SecRecom" className="anchor"></div>
            <div className="container relative">
                <div className="container text-center">
                    <h2 className="title-42">
                        מה קורה לילדים שקיבלו תוספת חיזוק <span className="text-primary">חובה ל-א׳ </span>
                        של דמיון בקופסא
                    </h2>
                    <div className="slider-recom-wrap relative">
                        <div className="swiper-button-next" onClick={handleNextClick}></div>
                        <div className="swiper-button-prev" onClick={handlePrevClick}></div>
                        <Swiper {...swiperOptions} className="slider-recom">
                            {reco_images.map((img, i) => (
                                <SwiperSlide key={i}>
                                    <div className="slider-recom-item">
                                        <img src={img} alt="" />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <br></br>
                </div>
            </div>
        </section>
    );
}

export default SecRecom;
