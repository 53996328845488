import React, { useContext } from 'react';
import CloseIcon from '../../assets/icons/close_red.svg';
import messages from '../../assets/messages_he.json';
import { useMediaQueryWrap } from '../useMediaQueryWrap';
import { get_txt_btn_font_size } from '../common';
import TextButton from '../buttons/TextButton';
import CircularProgress from '@mui/material/CircularProgress';
import { usePayment } from './usePayment';
import { AppContext } from '../../AppContext';
import MainModal from '../layouts/MainModal';
import TextField from '@mui/material/TextField';
import rabbitSvg from 'src/assets/images/rabbit_login.svg';
import billySvg from 'src/assets/images/billy_payment.svg';
import rudiBillySvg from 'src/assets/images/rudy-billy.svg';
import Checkbox from '@mui/material/Checkbox';
import { CourseName, payment_deatails } from 'src/wrapper';
import './PaymentModal.css';

function PaymentModal(): JSX.Element {
    const { state } = useContext(AppContext);
    const { getMQid } = useMediaQueryWrap();
    const {
        OnPayClick,
        iframeUrl,
        toggle_payment_modal,
        loadingIFrame,
        can_purchase,
        email,
        setEmail,
        email2,
        setEmail2,
    } = usePayment();
    const rid = getMQid();

    const render_main_btn_or_loading = () => {
        if (loadingIFrame)
            return <CircularProgress style={{ width: 45, height: 45, color: '#F76E5B', marginBottom: '1em' }} />;

        return (
            <TextButton
                disabled={!can_purchase()}
                onClick={OnPayClick}
                text={messages['payment.modal.textBtn']}
                color={'orangeAction'}
                textColor={'white'}
                fontSize={get_txt_btn_font_size(rid)}
                width="75%"
                padding="0.5em"
            />
        );
    };

    const get_payment_header = () => {
        if (state.paymentStatus === 'close_modal') return '';
        const name = payment_deatails[state.paymentStatus].name;
        return `רכישה והרשמה - ${name}`;
    };

    const get_payment_subtext = () => {
        if (state.paymentStatus === 'close_modal') return '';
        const price = payment_deatails[state.paymentStatus].price;
        return `המחיר היום, לכל החיים - ${price} ₪`;
    };

    const get_image_src = () => {
        if (state.paymentStatus === 'heb1') return rabbitSvg;
        if (state.paymentStatus === 'all') return rudiBillySvg;
        if (state.paymentStatus === 'math1') return billySvg;
        if (state.paymentStatus === 'on_sale') {
            const paid_course = Object.keys(state.courses)[0] as CourseName;
            if (paid_course == 'heb1') return billySvg;
            return rabbitSvg;
        }
    };

    const render_content_main = () => {
        if (state.paymentStatus === 'close_modal') return;
        const image_src = get_image_src();
        return (
            <>
                <div>
                    <p id={rid} className={'payment_modal_subtextr'}>
                        {get_payment_subtext()}
                    </p>
                    <br></br>
                </div>
                <img src={image_src} alt="logo image" />

                {state.paymentStatus != 'on_sale' && (
                    <div className="payment_form_wrapper">
                        <p style={{ color: 'gray', fontWeight: 500 }}>הזינו כתובת מייל אליה ישלחו פרטי ההתחברות</p>
                        <form className="payment_emails_form">
                            <TextField
                                required
                                type={'email'}
                                label={'אימייל'}
                                defaultValue={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                required
                                type={'email'}
                                label={'הזינו אימייל שנית'}
                                defaultValue={email2}
                                onChange={(e) => setEmail2(e.target.value)}
                            />
                        </form>
                    </div>
                )}
                <div className="payment_action_bottom">{render_main_btn_or_loading()}</div>
                {state.paymentStatus === 'on_sale' && <p className="bottom_msg">* לאחר התשלום יש לרענן את האתר</p>}
                {state.paymentStatus != 'on_sale' && (
                    <div style={{ color: 'gray', padding: '1em' }}>
                        אני מאשר ל׳דמיון בקופסא׳ לשלוח לי עדכונים וחדשות
                        <Checkbox disabled checked />
                    </div>
                )}
            </>
        );
    };

    const render_content = () => {
        return (
            <div className="payment_modal_wrap">
                <p id={rid} className={'payment_modal_header'}>
                    {get_payment_header()}
                </p>
                {render_content_main()}
            </div>
        );
    };

    return (
        <MainModal
            id={'login'}
            open={state.paymentStatus !== 'close_modal'}
            close={() => toggle_payment_modal('close_modal')}
            closeIcon={CloseIcon}
        >
            {iframeUrl === '' && render_content()}
            {iframeUrl !== '' && <iframe className="payment_iframe" src={iframeUrl}></iframe>}
        </MainModal>
    );
}

export default PaymentModal;
