import React from 'react';
import Letter from '../../common/buttons/Letter';
import { letters, numbers_to_10_backwards } from '../../common/common';
import { letterColors } from '../../common/buttons/colors';
import { Grid } from '@mui/material';
import { ILetterId } from 'src/wrapper';
import './AbRuler.css';

interface IAbRuler {
    letters?: string[];
    onClick?: (letter: string) => void;
    zoom: number;
    id?: ILetterId;
}

function AbRuler(props: IAbRuler): JSX.Element {
    const items = props.id === 'number' ? numbers_to_10_backwards : letters;
    return (
        <div className="ab_wrap">
            <Grid container spacing={1} justifyContent={'center'}>
                {items.map((letter, index) => (
                    <Letter
                        key={index}
                        text={letter}
                        id={props.id ?? 'letter'}
                        color={props.letters?.includes(letter) ? letterColors['blue'] : letterColors['gray']}
                        onClick={props.onClick}
                        zoom={props.zoom}
                    />
                ))}
            </Grid>
        </div>
    );
}

export default AbRuler;
