import { useContext } from 'react';
import { clearLoginStorage } from '../common';
import { AppContext } from 'src/AppContext';
import { init_app_state } from 'src/wrapper';
import { useNav } from '../useNav';

export function useServer() {
    const { setState } = useContext(AppContext);
    const { navigateToHome } = useNav();

    const handle_no_token_err = (error: any) => {
        if (error.response.status !== 511) return;
        clearLoginStorage();
        setState({ ...JSON.parse(JSON.stringify(init_app_state)), needSync: false });
        navigateToHome();
        alert('שמנו לב שהתחברתם עם מכשיר אחר, נא להתחבר מחדש');
    };

    return { handle_no_token_err };
}
