import React from 'react';
import logoImage from '../assets/logo/blue.svg';
import MenuImg from '../assets/icons/blueMenu.svg';
import MainLayout from '../common/layouts/MainLayout';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { useGame } from './useGame';
import GameSuccess from './GameSuccess';
import 'src/class/Class.css';

function Game(): JSX.Element {
    const {
        gameOver,
        render_puzzle,
        is_puzzle,
        render_maze,
        is_maze,
        render_word_drag_game,
        is_word_drag_game,
        render_memory_cards,
        is_memory_cards,
        render_paint_maze,
        is_paint_maze,
        render_video,
        render_ab_ruler_prog,
        render_mic_prog,
        render_mic,
        render_answers_during,
        render_game_instructions,
        render_cash_game,
        is_cash_game,
    } = useGame();
    const { getMQid, is_horiz } = useMediaQueryWrap();
    const rid = getMQid();

    const render = () => {
        if (is_cash_game()) return render_cash_game();
        if (is_paint_maze()) return render_paint_maze();
        if (is_memory_cards()) return render_memory_cards();
        if (is_puzzle()) return render_puzzle();
        if (is_maze()) return render_maze();
        if (is_word_drag_game()) return render_word_drag_game();
        if (!is_horiz) {
            return (
                <div className="classContentVert">
                    {render_video()}
                    {render_ab_ruler_prog()}
                    {render_answers_during()}
                    <div className="navButtonVerticalWrap" style={{ justifyContent: 'center' }}>
                        {render_mic()}
                        {render_mic_prog()}
                        {render_game_instructions()}
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <div className="classContentHoriz">
                        {render_video()}
                        <div className="classHorizAbsAction">
                            {render_mic()}
                            {render_mic_prog()}
                            {render_game_instructions()}
                        </div>
                    </div>
                    <div className="horizActions">
                        {render_ab_ruler_prog()}
                        {render_answers_during()}
                    </div>
                </>
            );
        }
    };

    return (
        <MainLayout can_url="/game" id={`class_${rid}`} logo={logoImage} appMenuLogo={MenuImg}>
            <div className="classContent">{render()}</div>
            {gameOver && <GameSuccess />}
        </MainLayout>
    );
}

export default Game;
