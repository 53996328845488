interface ABDict {
    [key: string]: string;
}

const eng_heb_dict: ABDict = {
    א: 'a',
    ב: 'b',
    ג: 'g',
    ד: 'd',
    ה: 'h',
    ו: 'v',
    ז: 'z',
    ח: 'ch',
    ט: 't',
    י: 'y',
    כ: 'k',
    ל: 'l',
    מ: 'm',
    נ: 'n',
    ס: 's',
    ע: 'a',
    פ: 'p',
    צ: 's',
    ק: 'k',
    ר: 'r',
    ש: 's',
    ת: 't',
};

const ab_dict: ABDict = {
    pallet: 'א',
    olive: 'א',
    ollie: 'א',
    //polish: 'א',
    'are they': 'א',
    הלב: 'א',
    lyft: 'א',
    'out of': 'א',
    את: 'ב',
    email: 'ג',
    dimmer: 'ג',
    מייל: 'ג',
    אימייל: 'ג',
    kingman: 'ג',
    קימל: 'ג',
    keep: 'ג',
    // timid: 'ג',
    demon: 'ג',
    female: 'ג',
    'he made': 'ג',
    'he-man': 'ג',
    jimin: 'ג',
    dmacc: 'ג',
    a: 'ה',
    pay: 'ה',
    ace: 'ה',
    // chase: 'ה',
    // 'got it': 'ה',
    // ballet: 'ה',
    80: 'ה',
    egg: 'ה',
    9: 'ז',
    // games: 'ז', פ', 'ס
    dying: 'ז',
    vine: 'ז',
    // lauren: 'ז',
    sighing: 'ז',
    האם: 'ז',
    johnny: 'ז',
    tonight: 'ז',
    // חיים: 'ז',
    psy: 'ז',
    שי: 'ז',
    טייץ: 'ז',
    sign: 'ז',
    'not in': 'ז',
    line: 'ז',
    lion: 'ז',
    lying: 'ז',
    design: 'ז',
    2: 'ז',
    "i'm": 'ז',
    חיים: 'ז',
    align: 'ז',
    סליים: 'ז',
    יין: 'ז',
    running: 'ז',
    // egg: 'ז',
    donnie: 'ז',
    lonnie: 'ז',
    het: 'ח',
    set: 'ח',
    faith: 'ח',
    saint: 'ח',
    checked: 'ח',
    pig: 'ח',
    cake: 'ח',
    quit: 'ח',
    6: 'ח',
    crit: 'ח',
    kit: 'ח',
    fat: 'ח',
    fit: 'ח',
    pet: 'ח',
    hit: 'ח',
    tate: 'ח',
    snake: 'ח',
    head: 'ח',
    date: 'ט',
    תת: 'ט',
    ditch: 'ט',
    dead: 'ט',
    dudt: 'ט',
    state: 'ט',
    dit: 'ט',
    it: 'ט',
    at: 'ט',
    cop: 'כ',
    cough: 'כ',
    קרה: 'כ',
    קח: 'כ',
    cox: 'כ',
    bass: 'כ', // 'פ'
    goff: 'כ',
    song: 'כ',
    top: 'כ',
    cast: 'כ',
    car: 'כ',
    got: 'כ',
    cars: 'כ',
    gold: 'כ',
    them: 'מ',
    name: 'מ',
    בן: 'מ',
    names: 'מ',
    when: 'מ',
    // family: 'מ',
    ewald: 'מ',
    eminem: 'מ',
    moon: 'נ',
    לול: 'נ',
    לוד: 'נ',
    know: 'נ',
    known: 'נ',
    // tent: 'נ',
    knowing: 'נ',
    'blue moon': 'נ',
    tommy: 'ס',
    dumbest: 'ס',
    diamond: 'ס',
    nami: 'ס',
    שמח: 'ס',
    שם: 'ס',
    5: 'ס',
    dummy: 'ס',
    צמח: 'ס',
    איך: 'ס',
    'i mean': 'ס',
    'call me': 'ס',
    'stop it': 'ס',
    'found it': 'ס',
    הרבה: 'ס',
    camas: 'ס',
    domics: 'ס',
    thomas: 'ס',
    zombie: 'ס',
    zombies: 'ס',
    gummies: 'ס',
    'gummy bear': 'ס',
    'damn in': 'ס',
    'damn it': 'ס',
    'that means': 'ס',
    coming: 'ס',
    batman: 'ס',
    baby: 'פ',
    bay: 'פ',
    תהיי: 'פ',
    היי: 'פ',
    games: 'פ',
    bane: 'פ',
    // spank: 'פ',
    tech: 'פ',
    say: 'פ',
    addict: 'צ',
    dhadak: 'צ',
    traffic: 'צ',
    paddock: 'צ',
    סטטיק: 'צ',
    magic: 'צ',
    שאדי: 'צ',
    30: 'צ',
    fatty: 'צ',
    psychic: 'צ',
    batik: 'צ',
    titanic: 'צ',
    עדי: 'צ',
    ביג: 'צ',
    betika: 'צ',
    tragic: 'צ',
    gandhi: 'צ',
    gothic: 'צ',
    beak: 'צ',
    pool: 'ק',
    google: 'ק',
    goof: 'ק',
    colts: 'ק',
    cool: 'ק',
    who: 'ק',
    school: 'ק',
    coof: 'ק',
    כוס: 'ק',
    אוף: 'ק',
    resh: 'ר',
    zedge: 'ר',
    beige: 'ר',
    french: 'ר',
    leitch: 'ר',
    watch: 'ר',
    like: 'ר',
    rach: 'ר',
    late: 'ר',
    iah: 'ר',
    which: 'ר',
    rate: 'ר',
    play: 'ר',
    laois: 'ר',
    leche: 'ר',
    chase: 'ר',
    waze: 'ר',
    blaze: 'ר',
    flash: 'ר',
    lays: 'ר',
    grace: 'ר',
    // איש: 'ר',
    cheat: 'ש',
    gene: 'ש',
    cheap: 'ש',
    beam: 'ש',
    chain: 'ש',
    teen: 'ש',
    team: 'ש',
    bean: 'ש',
    pain: 'ש',
    סין: 'ש',
    chin: 'ש',
    thin: 'ש',
    keen: 'ש',
    jane: 'ש',
    king: 'ש',
    gin: 'ש',
    tina: 'ש',
    jean: 'ש',
    in: 'ש',
    theme: 'ש',
    shein: 'ש',
    shin: 'ש',
    10: 'ש',
    // 16: 'ש',
    // היא: 'ש',
    duff: 'ת',
    stuff: 'ת',
    songs: 'ת',
    staff: 'ת',
    powerpuff: 'ת',
    טס: 'ת',
    קו: 'ת',
    dusk: 'ת',
    // dad: 'ת',
};

export function useAB() {
    const check_ab = (ab: string, match: string): boolean => {
        if (!/[\u0590-\u05FF]/.test(match)) match = match.replace(/[^\x00-\x7F]/g, '');
        console.log(match, ab);

        if (match in ab_dict) {
            if (ab == ab_dict[match]) return true;
        }
        if (match.charAt(0) == ab) return true;
        if (eng_heb_dict[ab] === match.charAt(0)) return true;

        if (match == 'hey') return ab == 'פ' || ab == 'ט' || ab == 'ח' || ab == 'ר';
        if (match == 'they') return ab == 'פ' || ab == 'ר';
        if (match == 'call') return ab == 'ק' || ab == 'כ';
        if (match == 'cops') return ab == 'ק' || ab == 'כ';
        if (match == 'fit') return ab == 'ח' || ab == 'ט';
        if (match == 'babe') return ab == 'מ' || ab == 'פ';
        if (match == 'stop') return ab == 'כ' || ab == 'ת';
        if (match == 'meme') return ab == 'ג' || ab == 'ח';
        if (match == 'kate') return ab == 'ה' || ab == 'ח';
        if (match == 'daddy') return ab == 'ז' || ab == 'צ' || ab == 'ס';
        if (match == '8') return ab == 'ה' || ab == 'ט';
        if (match === 'ביי') return ab === 'פ' || ab === 'ה' || ab == 'ז';
        if (match === 'בי') return ab === 'פ' || ab === 'ה';
        if (match === 'אין') return ab === 'מ' || ab === 'ש';
        if (match === 'ebay') return ab === 'פ' || ab === 'ה';
        return false;
    };

    return { check_ab };
}
