import React from 'react';
import Close from '../../assets/images/close.svg';
import { useLogin } from 'src/common/login/useLogin';
import { useShare } from 'src/common/useShare';
import { scroll_to_element } from 'src/common/common';

interface MobileMenuProps {
    isOpen: boolean;
    toggleMenu: () => void;
}

function MobileMenu({ isOpen, toggleMenu }: MobileMenuProps) {
    const { toggle_login_modal } = useLogin();
    const { shareAppLink } = useShare();

    return (
        <nav className="menu only-tablet" aria-expanded={isOpen ? 'true' : 'false'}>
            <button onClick={toggleMenu} className="app_menu_close only-tablet" aria-label="סגור תפריט">
                <img src={Close} className="" alt="סגור" />
            </button>
            <ul className="flex">
                <li>
                    <a
                        style={{ fontWeight: 'bold' }}
                        onClick={() => {
                            toggleMenu();
                            scroll_to_element('SecSell');
                        }}
                    >
                        להרשמה
                    </a>
                </li>
                <li>
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            toggle_login_modal(true);
                            toggleMenu();
                        }}
                    >
                        התחברות
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            toggleMenu();
                            scroll_to_element('SecRecom');
                        }}
                    >
                        הורים ממליצים
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            toggleMenu();
                            scroll_to_element('SecCourse');
                        }}
                    >
                        איך עובד הקורס
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            toggleMenu();
                            scroll_to_element('SecFAQ');
                        }}
                    >
                        שאלות ותשובות
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            toggleMenu();
                            scroll_to_element('SecAbout');
                        }}
                    >
                        אודות
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            toggleMenu();
                            scroll_to_element('Footer');
                        }}
                    >
                        צרו קשר
                    </a>
                </li>
                <li>
                    <a style={{ cursor: 'pointer' }} onClick={shareAppLink}>
                        שתפו חברים
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default MobileMenu;
