import React, { useRef } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ynet0 from 'src/landing//assets/images/media/ynet0.webp';
import ynet1 from 'src/landing//assets/images/media/ynet1.webp';
import ynet2 from 'src/landing//assets/images/media/ynet2.webp';
import ynet3 from 'src/landing//assets/images/media/ynet3.webp';
import life1 from 'src/landing//assets/images/media/life1.webp';
import life2 from 'src/landing//assets/images/media/life2.webp';
import rafiImg from 'src/landing//assets/images/media/rafi.webp';
import Video from 'src/features/video/Video';

import 'swiper/css';
import 'swiper/css/navigation';
import './sec-slider.css';

interface sliderImg {
    img: string;
    link?: string;
    alt: string;
    event?: string;
}

const slider_images: sliderImg[] = [
    {
        // link: 'https://www.youtube.com/watch?v=AyYGzsw06yA',
        img: life1,
        alt: 'החיים הסודיים ',
        event: 'secret_life_1_click',
    },
    {
        // link: 'https://www.youtube.com/watch?v=-wyOGX0pl6U',
        img: life2,
        alt: 'החיים הסודיים ',
        event: 'secret_life_2_click',
    },
    {
        link: 'https://youtu.be/RzX8tScwmEQ?si=iTlgnUcDAfpSd2iR',
        img: rafiImg,
        event: 'rafi_vid_click',
        alt: 'כתבה רפי רשף',
    },
    {
        // link: 'https://www.ynet.co.il/articles/0,7340,L-5065510,00.html',
        img: ynet0,
        alt: 'כתבה א ב',
    },
    {
        // link: 'https://www.ynet.co.il/article/5071859',
        img: ynet1,
        alt: 'כתבה',
    },
    {
        // link: 'https://www.ynet.co.il/articles/0,7340,L-5274748,00.html',
        img: ynet2,
        alt: 'כתבה',
    },
    {
        // link: 'https://www.ynet.co.il/articles/0,7340,L-5119660,00.html',
        img: ynet3,
        alt: 'כתבה ',
    },
];

function SecSlider() {
    const swiperRef = useRef<any>(null);

    const swiperOptions = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 1,
        loop: true,
        onSwiper: (swiper: any) => {
            swiperRef.current = swiper;
        },
    };

    const [play, setPlay] = React.useState(false); // not genereic!

    const render_item = (simg: sliderImg) => {
        if (simg.link)
            return (
                <div className="slider-item-in">
                    <Video
                        url={simg.link}
                        vid_over_cb={() => null}
                        img={simg.img}
                        play={play}
                        setPlay={setPlay}
                        controls={true}
                        log_click={simg.event}
                    />
                </div>
            );
        return <img className="slider-item-in" src={simg.img} alt="" />;
    };

    return (
        <section className="sec-slider py-4 bg-yellow over-hide">
            <div className="container text-center">
                <h2 className="title-42">בא לכם שנכיר קצת יותר?</h2>
                <div className="text-xl mb-1">איזה כיף! הנה כמה מההופעות שלי בתקשורת</div>
                <div className="slider-wrap relative">
                    <Swiper {...swiperOptions} className="slider">
                        {slider_images.map((simg, i) => (
                            <SwiperSlide key={i}>
                                <div className="slider-item">{render_item(simg)}</div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </section>
    );
}

export default SecSlider;
