import React from 'react';
import { openRoyFacebook, openRoyInstagram, openRoyTikTok, openWhatsApp, sendMail } from 'src/common/common';
import Tiktok from 'src/landing/assets/images/tiktok.svg';
import Instag from 'src/landing/assets/images/instag.svg';
import Faceb from 'src/landing/assets/images/facebook.svg';
import Whats from 'src/landing/assets/images/whatsapp.svg';
import Mail from 'src/landing/assets/images/mail.svg';
import './Socials.css';

function Socials(): JSX.Element {
    return (
        <div className="socials flex-center">
            <button onClick={openRoyTikTok}>
                <img className="" src={Tiktok} alt="Tiktok" />
            </button>
            <button onClick={openRoyInstagram}>
                <img className="" src={Instag} alt="Instagram" />
            </button>
            <button onClick={openRoyFacebook}>
                <img className="" src={Faceb} alt="Facebook" />
            </button>
            <button onClick={openWhatsApp}>
                <img className="" src={Whats} alt="Whatsapp" />
            </button>
            <button onClick={sendMail}>
                <img className="" src={Mail} alt="Mail" />
            </button>
        </div>
    );
}

export default Socials;
