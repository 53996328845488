import React, { useContext } from 'react';
import { useMediaQueryWrap } from './useMediaQueryWrap';
import { useNav } from './useNav';
import { CourseName } from 'src/wrapper';
import { AppContext } from 'src/AppContext';

export function useAppMenu() {
    const [open, setOpen] = React.useState(false);
    const {
        navigateToClassroom,
        navigateToFolder,
        navigateToMoreTasks,
        navigateToNiceToMeet,
        navigateToFirstGrade,
        navigateToContact,
        navigateToGames,
        navigateToHome,
        navigateToAboutRoy,
    } = useNav();
    const { getMQid } = useMediaQueryWrap();
    const { state, setState } = useContext(AppContext);

    const rid = getMQid();
    let width_pre = 0.25;
    if (rid === 'mobile') width_pre = 0.85;
    if (rid === 'tablet') width_pre = 0.5;

    const classroomClick = () => {
        setOpen(false);
        navigateToClassroom();
    };

    const folderClick = () => {
        setOpen(false);
        navigateToFolder();
    };

    const moreTasksClick = () => {
        setOpen(false);
        navigateToMoreTasks();
    };

    const niceToMeetClick = () => {
        setOpen(false);
        navigateToNiceToMeet();
    };

    const aboutRoyClick = () => {
        setOpen(false);
        navigateToAboutRoy();
    };

    const firstGradeClick = () => {
        setOpen(false);
        navigateToFirstGrade();
    };

    const contactClick = () => {
        setOpen(false);
        navigateToContact();
    };

    const gamesClick = () => {
        setOpen(false);
        navigateToGames();
    };

    const homeClick = () => {
        setOpen(false);
        navigateToHome();
    };

    const on_course_click = (course: CourseName) => {
        setState({ ...state, course_key: course, curr_game_group_idx: -1, curr_game_idx: -1 });
        const exclude_from_navigating_to_classroom = ['/classroom', '/more-tasks', '/games', '/folder'];
        if (!exclude_from_navigating_to_classroom.includes(location.pathname)) navigateToClassroom();
    };

    return {
        setOpen,
        open,
        width_pre,
        classroomClick,
        folderClick,
        moreTasksClick,
        niceToMeetClick,
        firstGradeClick,
        contactClick,
        gamesClick,
        homeClick,
        aboutRoyClick,
        on_course_click,
    };
}
