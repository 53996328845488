import React from 'react';
import Letter from '../../common/buttons/Letter';
import { letterColorType, letterColors } from '../../common/buttons/colors';
import useSound from 'use-sound';
import sccSound from '../../assets/sounds/success1.mp3';
import './WordDrag.css';

export interface IWordDragItem {
    size: number;
    text: string;
    left: number;
    top: number;
    finish_cb: Function;
    bounds: DOMRect;
    target_left: number;
    target_top: number;
    target_cell: number;
    has_target: boolean;
    pos_to_cell_idx: (left: number, top: number) => number;
}

function WordDragItem(props: IWordDragItem): JSX.Element {
    const [color, setColor] = React.useState('blue' as letterColorType);
    const [finish, setFinish] = React.useState(false);
    const [isMouse, setIsMouse] = React.useState(false);
    const itemRef: React.RefObject<HTMLDivElement> = React.createRef();
    const [playSuccess] = useSound(sccSound, { volume: 0.2 });

    function inScreen(x: number, y: number) {
        return (
            x < props.bounds.width + props.bounds.left &&
            x > props.bounds.left &&
            y < props.bounds.height + props.bounds.top &&
            y > props.bounds.top
        );
    }

    function colide(x: number, y: number) {
        const cell_idx = props.pos_to_cell_idx(x, y);
        return cell_idx === props.target_cell;
    }

    function OnMove(x: number, y: number) {
        if (!itemRef.current) return;
        if (!inScreen(x, y)) return;

        itemRef.current.style.zIndex = '2';

        // touching from center of rect
        itemRef.current.style.left = x - props.bounds.left - props.size / 2 + 'px';
        itemRef.current.style.top = y - props.bounds.top - props.size / 2 + 'px';

        if (!props.has_target) {
            setColor('orange');
            return;
        }

        const col = colide(x, y);
        const color = col ? 'green' : 'orange';
        setColor(color);
    }

    function onMoveEnd(x: number, y: number) {
        setColor('blue');

        if (!itemRef.current) return;
        itemRef.current.style.zIndex = '1';

        if (!props.has_target) return;

        const col = colide(x, y);
        if (col) {
            playSuccess({ forceSoundEnabled: true });
            itemRef.current.style.left = props.target_left + 'px';
            itemRef.current.style.top = props.target_top + 'px';
            props.finish_cb();
            setColor('green');
            setFinish(true);
        }
    }

    function onTouchMove(e: any) {
        if (finish) return;
        const touchLocation = e.targetTouches[0];
        OnMove(touchLocation.clientX, touchLocation.clientY);
    }

    function onTouchEnd(e: any) {
        if (finish) return;
        const touchLocation = e.changedTouches[0];
        onMoveEnd(touchLocation.clientX, touchLocation.clientY);
    }

    function onMouseMove(e: any) {
        if (finish) return;
        if (!isMouse) return;
        OnMove(e.pageX, e.pageY);
    }

    function onMouseUp(e: any) {
        if (finish) return;
        setIsMouse(false);
        onMoveEnd(e.pageX, e.pageY);
    }

    function onMousedown() {
        if (finish) return;
        setIsMouse(true);
    }

    return (
        <div
            className="worddragItemWrap"
            style={{ top: props.top, left: props.left, width: props.size, height: props.size }}
            ref={itemRef}
            onMouseDown={() => onMousedown()}
            onMouseMove={(ev) => onMouseMove(ev)}
            onMouseUp={(ev) => onMouseUp(ev)}
            onTouchEnd={(ev) => onTouchEnd(ev)}
            onTouchMove={(ev) => onTouchMove(ev)}
        >
            <Letter
                text={props.text}
                id={'letter'}
                zoom={props.size / 85.3}
                color={letterColors[color]}
                onClick={!finish ? () => null : undefined}
            />
        </div>
    );
}

export default WordDragItem;
