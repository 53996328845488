import { number_to_10_reverse, number_to_20_reverse, letters, number_to_20_even_reverse } from 'src/common/common';

interface WrongLetterCoord {
    x: number;
    y: number;
}

interface WrongLetterCoordDict {
    [key: number]: WrongLetterCoord;
}

interface puzzleLvl {
    coord_dict: WrongLetterCoordDict;
    content: string[];
}

interface PuzzleLvlDict {
    [key: number]: puzzleLvl;
}

interface puzzleGame {
    puzzle_levels: PuzzleLvlDict;
}

interface puzzleGames {
    [key: string]: puzzleGame;
}

export function usePuzzleConfig() {
    const letter_lvl1: puzzleLvl = {
        content: letters,
        coord_dict: {
            2: { x: 2, y: 2 },
            3: { x: 3, y: 1 },
            7: { x: 4, y: 0 },
            12: { x: 5, y: 1 },
            17: { x: 6, y: 2 },
            18: { x: 7, y: 1 },
            19: { x: 8, y: 2 },
        },
    };

    const letter_lvl2: puzzleLvl = {
        content: letters,
        coord_dict: {
            5: { x: 2, y: 2 },
            3: { x: 3, y: 1 },
            1: { x: 3, y: 2.5 },
            7: { x: 4, y: 0 },
            11: { x: 1, y: 1 },
            8: { x: 8, y: 2 },
            12: { x: 5, y: 1 },
            17: { x: 6, y: 2 },
            18: { x: 7, y: 1 },
            20: { x: 8, y: 0 },
            15: { x: 5, y: 2.5 },
        },
    };

    const letter_lvl3: puzzleLvl = {
        content: letters,
        coord_dict: {
            4: { x: 2, y: 2 },
            2: { x: 3, y: 1 },
            0: { x: 3, y: 2.5 },
            8: { x: 4, y: 0 },
            12: { x: 1, y: 1 },
            10: { x: 8, y: 2 },
            7: { x: 4, y: 2 },
            6: { x: 9, y: 2 },
            14: { x: 0, y: 2 },
            11: { x: 5, y: 1 },
            15: { x: 6, y: 2 },
            17: { x: 7, y: 1 },
            20: { x: 8, y: 0 },
            19: { x: 10, y: 1.5 },
            13: { x: 5, y: 2.5 },
        },
    };

    const letter_lvl4: puzzleLvl = {
        content: letters,
        coord_dict: {
            4: { x: 2, y: 2 },
            2: { x: 3, y: 1 },
            0: { x: 3, y: 2.5 },
            8: { x: 4, y: 0 },
            12: { x: 1, y: 1 },
            10: { x: 8, y: 2 },
            7: { x: 4, y: 2 },
            6: { x: 9, y: 2 },
            14: { x: 0, y: 2 },
            11: { x: 5, y: 1 },
            15: { x: 6, y: 2 },
            17: { x: 7, y: 1 },
            20: { x: 8, y: 0 },
            19: { x: 10, y: 1.5 },
            13: { x: 5, y: 2.5 },
            18: { x: 1, y: 2.5 },
            3: { x: 6, y: 0.5 },
            9: { x: 2, y: 0 },
        },
    };

    const letter_levels: PuzzleLvlDict = {
        1: letter_lvl1,
        2: letter_lvl2,
        3: letter_lvl3,
        4: letter_lvl4,
    };

    const letter_game: puzzleGame = {
        puzzle_levels: letter_levels,
    };

    const number_lvl1: puzzleLvl = {
        content: number_to_10_reverse,
        coord_dict: {
            1: { x: 5, y: 2 },
            2: { x: 2, y: 2 },
            3: { x: 7, y: 1 },
            5: { x: 3, y: 0 },
            8: { x: 6, y: 0 },
            7: { x: 4, y: 0 },
        },
    };

    const number_lvl2: puzzleLvl = {
        content: number_to_20_reverse,
        coord_dict: {
            0: { x: 2, y: 0 },
            2: { x: 2, y: 2 },
            3: { x: 3, y: 1 },
            5: { x: 8, y: 0 },
            7: { x: 4, y: 0 },
            9: { x: 6, y: 0 },
            15: { x: 6, y: 2 },
            10: { x: 1, y: 1 },
            12: { x: 5, y: 1 },
            17: { x: 4, y: 2 },
            18: { x: 7, y: 1 },
            19: { x: 8, y: 2 },
        },
    };

    const number_lvl3: puzzleLvl = {
        content: number_to_20_even_reverse,
        coord_dict: {
            0: { x: 2, y: 0 },
            2: { x: 7, y: 1 },
            3: { x: 3, y: 1 },
            5: { x: 5, y: 1 },
            7: { x: 4, y: 0 },
            9: { x: 6, y: 0 },
        },
    };

    const number_levels: PuzzleLvlDict = {
        1: number_lvl1,
        2: number_lvl2,
        3: number_lvl3,
    };

    const number_game: puzzleGame = {
        puzzle_levels: number_levels,
    };

    const games: puzzleGames = {
        letter: letter_game,
        number: number_game,
    };

    return { games };
}
