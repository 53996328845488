import React from 'react';
import ShipC from '../../assets/images/ship-1.svg';
import ShipB from '../../assets/images/ship-2.svg';
import ShipA from '../../assets/images/ship-3.svg';
import Night1 from '../../assets/images/night-1.svg';
import Night2 from '../../assets/images/night-2.svg';
import Night3 from '../../assets/images/night-3.svg';
import Night4 from '../../assets/images/night-4.svg';
import Video from 'src/features/video/Video';
import videoImage from 'src/assets/images/landing_math_vid_image.webp';
import wizardSvg from 'src/landing/assets/images/wizard-numbers.svg';
import billySvg from 'src/assets/images/billy.svg';
import { useMediaQueryWrap } from 'src/common/useMediaQueryWrap';
import './sec-night.css';

const vid_url = 'https://youtu.be/wcR8ge-DcCo?si=yIiFTmz3VuodTH16';

interface secNightMathProps {
    sentence_one: string;
}

function SecNightMath({ sentence_one }: secNightMathProps) {
    const [play, setPlay] = React.useState(false);
    const { getMQid, render_float_anim } = useMediaQueryWrap();
    const rid = getMQid();

    const render_drawings = () => {
        if (rid === 'mobile' || !render_float_anim) return null;
        return (
            <>
                <img src={wizardSvg} className="landing_float_left float float-delay" alt="animation" />
                <img src={billySvg} className="landing_float_billy float float-delay" alt="animation" />
            </>
        );
    };

    return (
        <section id="SecNight" className="sec-night py-4 relative text-center text-white">
            {render_drawings()}
            <div className="container z-2">
                <div className="all-ships flex justify-center">
                    <img className="ship_svg" src={ShipA} alt="ShipA" />
                    <img className="ship_svg" src={ShipB} alt="ShipB" />
                    <img className="ship_svg" src={ShipC} alt="ShipC" />
                </div>
                <h2 className="title-42 mx-auto">חשבון עם בילי החתולה</h2>
                <p className="text-xl font-bold">
                    {' '}
                    הופכים את הילדים <span className="text-yellow">לאלופי המספרים</span>
                </p>
                <br></br>
                <p>{sentence_one}</p>
                <p>בעזרת הילדים והקוסם מחשבון, היא מפתחת את התפיסה הכמותית שלה ומגלה את סוד משאלת ליבה.</p>
                <div className="night-icons grid grid-cols-4">
                    <div>
                        <img src={Night1} alt="night1" />
                        <div className="text-base mx-auto">פיתוח התפיסה הכמותית </div>
                    </div>
                    <div>
                        <img src={Night2} alt="night2" />
                        <div className="text-base mx-auto">
                            סיפור אינטראקטיבי המלווה באנימציה שילדים אוהבים בו מתקדמים על ידי פתרון חידות, הפעלת
                            מיקרופון, יצירה וצילום עבודות
                        </div>
                    </div>
                    <div>
                        <img src={Night3} alt="night3" />
                        <div className="text-base mx-auto">
                            גישה לכל החיים <br></br>מתאים לנייד, לטאבלט ולמחשב
                        </div>
                    </div>
                    <div>
                        <img src={Night4} alt="night4" />
                        <div className="text-base mx-auto">
                            תיק עבודות - איזור אישי <br></br>בונוס: מגוון חידות ומשחקים נוספים באתר
                        </div>
                    </div>
                </div>
                <div className="mx-auto mt-1 max-w-670">
                    <Video
                        url={vid_url}
                        vid_over_cb={() => null}
                        img={videoImage}
                        play={play}
                        setPlay={setPlay}
                        controls={true}
                        log_click={'landing_math_vid'}
                    />
                </div>
                <div className="flex-col items-center justify-center mt-1">
                    <div className="flex items-center justify-center">
                        <div className="flex text-start">
                            <ul className="text-white ul-ok">
                                <li>זיהוי והכרת המספרים הכתובים בעשרת הראשונה והשנייה</li>
                                <li>חיזוק ופיתוח יכולות הספירה והמנייה</li>
                                <li>התאמת מספר לכמות</li>
                                <li>הכרת המספרים הזוגיים והאי-זוגיים</li>
                                <li>השוואת קבוצות והכרת סימני גדול\קטן\שווה</li>
                                <li>הכרה ותרגול מושגי מרחב: לפני ואחרי</li>
                                <li>פיתוח יכולות חיבור וחיסור בעשרת הראשונה והשנייה</li>
                                <li>הכרת הצורות ההנדסיות</li>
                                <li>פיתוח חשיבה יצירתית</li>
                                <li>פיתוח המוטוריקה העדינה</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SecNightMath;
