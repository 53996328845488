import axios from 'axios';
import { logErrorinServer } from './server.api';

const getUserInfo = async (access_token: string, finish: (email: string, picture: string, name: string) => void) => {
    const url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`;
    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${access_token}`,
                Accept: 'application/json',
            },
        });
        finish(response.data.email, response.data.picture, response.data.name);
    } catch (error: any) {
        logErrorinServer(`login_get_user_info_err`, document.referrer);
    }
};
export default getUserInfo;
