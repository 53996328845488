import html2canvas from 'html2canvas';
import messages from '../assets/messages_he.json';
import { log_event } from './common';
import { IImage } from 'src/wrapper';
import { downloadFolderImg } from './api/server.api';
import { useState } from 'react';
import { useServer } from 'src/common/api/useServer';

export function useShare() {
    const [durShareImg, setDurShareImg] = useState(false);
    const { handle_no_token_err } = useServer();

    const shareAppLink = async () => {
        log_event('share_site_click');
        const data = {
            url: 'https://imagineinabox.com/',
            title: messages['app.title'],
        };

        try {
            await navigator.share(data);
        } catch (err) {
            console.error(err);
        }
    };

    const shareHtmlElement = async (element: HTMLElement) => {
        html2canvas(element).then((canvas) => {
            canvas.toBlob(async (blob) => {
                if (!blob) return;
                const data = {
                    files: [
                        new File([blob], 'image.png', {
                            type: blob.type,
                        }),
                    ],
                };
                try {
                    await navigator.share(data);
                } catch (err) {
                    console.error(err);
                }
            });
        });
    };

    const shareFolderImage = (image: IImage) => {
        log_event('share_image_click');
        const element = document.getElementById(image.id);
        if (!element) return;

        if (image.img_src.startsWith('http')) {
            const token = localStorage.getItem('ib_token');
            if (!token) return;
            setDurShareImg(true);
            downloadFolderImg(
                token,
                image.id,
                (img) => {
                    setDurShareImg(false);
                    if (img == '') return;
                    element.removeChild(element.children[1]);
                    const new_img = document.createElement('img');
                    new_img.src = `data:image/png;base64,${img}`;
                    new_img.className = 'folderImage';
                    element.appendChild(new_img);
                    shareHtmlElement(element);
                },
                handle_no_token_err,
            );
        } else {
            shareHtmlElement(element);
        }
    };

    return { shareAppLink, shareFolderImage, durShareImg };
}
