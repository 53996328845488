import React, { useEffect } from 'react';
import PuzzleItem from './PuzzleItem';
import PuzzlePlace from './PuzzlePlace';
import { useMediaQueryWrap } from '../../common/useMediaQueryWrap';
import Turnscreen from '../../common/layouts/TurnScreen';
import { ILetterId, puzzlleLvl } from 'src/wrapper';
import { usePuzzleConfig } from './PuzzleConfig';

export interface IPuzzle {
    width: number;
    height: number;
    onFinish: () => void;
    lvl: puzzlleLvl;
    type: ILetterId;
}

function Puzzle({ width, height, onFinish, lvl, type }: IPuzzle): JSX.Element {
    const itemRef: React.RefObject<HTMLDivElement> = React.createRef();
    const [bounds, setBounds] = React.useState<undefined | DOMRect>(undefined);
    const { is_horiz, should_turn_screen } = useMediaQueryWrap();
    const { games } = usePuzzleConfig();
    const { puzzle_levels } = games[type];

    const puzzle_lvl = puzzle_levels[lvl];
    const wrong_letters = puzzle_lvl.coord_dict;
    const total_letters = type == 'letter' ? 22 : 20;

    const correct_amount_init = puzzle_lvl.content.length - Object.keys(wrong_letters).length;
    const [correct_amount, set_correct_amount] = React.useState(correct_amount_init);
    const rows = 2;

    useEffect(() => {
        if (!itemRef.current) return;
        const bounds = itemRef.current.getBoundingClientRect();
        setBounds(bounds);
    }, [is_horiz, height, width]);

    const gap = 4;
    const fwidth = width - gap;
    const letter_step = fwidth / (total_letters / rows);
    const letter_size = letter_step * 0.8;
    const pad_size = letter_step * 0.2;

    const letter_places = puzzle_lvl.content.map((l, index) => {
        const col = index % (total_letters / rows); // x
        const row = Math.floor(index / (total_letters / rows)); // y
        return {
            x: fwidth - letter_step * (col + 1) + pad_size / 2,
            y: height - letter_step * (2 - row),
        };
    });

    const letter_finish_cb = () => {
        const new_amount = correct_amount + 1;
        set_correct_amount(new_amount);
        if (new_amount == puzzle_lvl.content.length) onFinish();
    };

    const render = () => {
        if (should_turn_screen()) return <Turnscreen />;
        return (
            <div ref={itemRef} className="puzzle_wrap" style={{ width: width, height: height }}>
                <div
                    className="puzzlePlaceHolderSpace"
                    style={{
                        top: height - letter_step * 2 - pad_size,
                        left: gap,
                        width: fwidth - gap,
                        height: (letter_size + pad_size) * 2,
                    }}
                ></div>
                {letter_places.map((coord, index) => (
                    <PuzzlePlace key={index} size={letter_size} y={coord.y} x={coord.x} />
                ))}
                {puzzle_lvl.content.map((letter, index) => {
                    const correct_idx = !(index in wrong_letters);
                    const x = index in wrong_letters ? letter_places[wrong_letters[index].x].x : letter_places[index].x;
                    const y =
                        index in wrong_letters ? (wrong_letters[index].y * letter_step) / 2 : letter_places[index].y;
                    return (
                        <PuzzleItem
                            bounds={bounds!}
                            key={index}
                            text={letter}
                            size={letter_size}
                            isDrag={!correct_idx}
                            finish_cb={letter_finish_cb}
                            y={y}
                            x={x}
                            tx={letter_places[index].x}
                            ty={letter_places[index].y}
                        />
                    );
                })}
            </div>
        );
    };

    return render();
}

export default Puzzle;
