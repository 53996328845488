import React from 'react';
import MainLayout from '../MainLayout';
import logoImage from '../../../assets/logo/blue.svg';
import MenuImg from '../../../assets/icons/blueMenu.svg';
import './TextLayout.css';

function Privacy(): JSX.Element {
    return (
        <MainLayout can_url="/privacy" id={'text'} logo={logoImage} appMenuLogo={MenuImg}>
            <div className="tl_wrap">
                <div className="tl_wrap_inside">
                    <p dir="RTL">
                        <strong>מדיניות פרטיות&nbsp;</strong>
                        <strong>
                            <span dir="LTR">&nbsp;</span>
                        </strong>
                    </p>
                    <p dir="RTL">תיאור של מדיניות האתר ביחס לשמירה על פרטיות המשתמשים: &nbsp;</p>
                    <ol>
                        <li>
                            <strong>עוגיות (</strong>
                            <strong>
                                <span dir="LTR">Cookies</span>
                            </strong>
                            <strong>)</strong>
                            <ol>
                                <li>
                                    באתר עשוי להיעשות שימוש ב&apos;עוגיות&apos; (<span dir="LTR">Cookies</span>) לצורך
                                    תפעולו השוטף והתקין, כדי לייתר את הצורך בהזנת שם המשתמש והסיסמה בכל פעם שמשתמש מבקש
                                    לעשות שימוש בשירותים באתר המחייבים רישום, כדי לאסוף נתונים סטטיסטיים אודות השימוש
                                    באתר, כדי לאמת פרטים, כדי להתאים את האתר להעדפות האישיות של המשתמשים ולצרכי אבטחת
                                    מידע.
                                </li>
                                <li>
                                    העוגיות יכולות להכיל מידע מגוון כדוגמת הדפים שבהם המשתמש ביקר, משך הזמן ששהה באתר,
                                    מהיכן הגיע אל האתר ועוד.
                                </li>
                                <li>
                                    דפדפני אינטרנט מודרניים כוללים אפשרות לנטרל קבלת עוגיות, אולם ייתכן שנטרול העוגיות
                                    יפריע לחוויית השימוש בחלק מהשירותים והתכונות באתר.
                                </li>
                                <li>
                                    מובהר כי צוות האתר רשאי להקנות היתר לצדדים שלישיים לערוך את מערך ניהול הפרסומים באתר
                                    האינטרנט. מובהר כי השליטה על הפרסומים עשויה להיות מתופעלת על ידם ולשם כך הם עשויים
                                    להציב את העוגיות במחשב המשתמש. כאמור, פעילות העוגיות נועדה לנטר את התנועה באתר לרבות
                                    מעקב אחר השימוש בעמודי האתר ולחיצה על פרסומים שונים באתר.&nbsp;
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>אבטחת המידע</strong>
                            <ol>
                                <li>
                                    האתר מכבד את פרטיות המשתמשים ומפעיל מערכות אבטחה מתקדמות לשם אבטחת המידע שנמסר על
                                    ידיהם. למרות מאמצי אבטחת המידע אין ודאות מוחלטת כי המידע לא ייחשף במקרה של פריצה
                                    למערכות האתר או שרתי האתר. המשתמש מסכים כי האתר לא יישא בשום אחריות לחשיפת המידע בכל
                                    מקרה של פריצה למערכות האתר או שרתיו. המשתמש מוותר על כל דרישה, תביעה או טענה נגד
                                    האתר בשל כך.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>יצירת קשר</strong>
                            <ol>
                                <li>
                                    אנו מזמינים את המשתמש לפנות אלינו בכל בירור אודות האתר או תנאי השימוש בו, תוך ציון
                                    הפרטים המלאים, ומבטיחים לטפל בפניות אלה בהקדם. ניתן ליצור עמנו קשר באחת מהדרכים
                                    הבאות
                                    <span dir="LTR">:</span>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <p dir="RTL">כתובתנו: חיפה</p>
                    <p dir="RTL">טלפון: 050-822-9518</p>
                    <p dir="RTL">
                        מייל:&nbsp;<span dir="LTR">dimyonbekufsa@gmail.com</span>
                    </p>
                </div>
            </div>
        </MainLayout>
    );
}

export default Privacy;
