import React from 'react';
import logo from '../../../assets/logo/logo192.jpg';

function About(): JSX.Element {
    const head1 = "Accurately represents your app's identity:";
    const str1 = "app's";
    return (
        <div style={{ direction: 'ltr', padding: '1em' }}>
            <img src={logo} alt="app logo" />
            <h1>Google OAuth2 Homepage</h1>
            <p>
                <u>{head1}</u> The <a href="https://imagineinabox.com/"> imagineinabox </a>app is a digital interactive
                course. imagineinabox is a Startup based in Israel.
            </p>
            <p>
                <u>What will this app do with user data?</u> The only user data received is: name, email and profile
                picture. These will be saved and re-displayed to the user. The email will be used to indentify the user.
                The name and profile picture will be used as the name and picture of your accout on imagineinabox.
            </p>
            <p>
                <u>How does this app enhance user functionality?</u> This app just contains static content. Some content
                is only displayed to signed in users. The only enhancement of functionality is access to additional
                content.
            </p>
            <p>
                <u>Link to Privacy Policy:</u> <a href="https://imagineinabox.com/privacy">Click here.</a>
            </p>
            <p>
                <u>Link to Terms of service:</u> <a href="https://imagineinabox.com/terms">Click here.</a>
            </p>
            <p>
                <u>Describe the content, context, or connection to the app:</u> the imagineinabox App is this website,
                which is a digital interactive course.
            </p>
            <h1>Google OAuth2 Limited Use Disclosure</h1>
            <p>
                This app does not request restricted scopes, but if it did, the {str1} use of information received from
                Google APIs will adhere to the{' '}
                <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                    Google API Services User Data Policy
                </a>
                , including the Limited Use requirements.
            </p>
            <h1>Copyright</h1>
            <p>
                If you have a copyright complaint, please contact us at dimyonbekufsa@gmail.com and include the
                imagineinabox.com url that contains the alleged content, identification of the work claimed to have been
                infringed including the name and reply email address of the copyright holder/representative, an
                assertion that the use of the material is not authorized and an assertion that you are the copyright
                holder/representative.
            </p>
        </div>
    );
}

export default About;
