import React from 'react';
import MainLayout from '../MainLayout';
import logoImage from '../../../assets/logo/blue.svg';
import MenuImg from '../../../assets/icons/blueMenu.svg';
import { useMediaQueryWrap } from '../../useMediaQueryWrap';
import messages from '../../../assets/messages_he.json';
import img2 from 'src/assets/images/nicetomeet/nice_to_meet2.webp';
import { useNav } from '../../useNav';
import './TextLayout.css';

function NiceToMeet(): JSX.Element {
    const { getMQid } = useMediaQueryWrap();
    const rid = getMQid();
    const { navigateToAboutRoy, navigateToFirstGrade } = useNav();

    return (
        <MainLayout can_url="/nice-to-meet" id={'text'} logo={logoImage} appMenuLogo={MenuImg}>
            <div className="tl_wrap">
                <div className="tl_wrap_inside">
                    <h1 id={rid} className="tl_main_header">
                        {messages['hello.header']}
                    </h1>
                    <p id={rid} className="tl_sub_header">
                        {messages['hello.sub1']}{' '}
                        <button
                            id={rid}
                            className="tl_link"
                            onClick={navigateToAboutRoy}
                            aria-label={messages['hello.sub1_link']}
                        >
                            {messages['hello.sub1_link']}
                        </button>
                    </p>
                    <p id={rid} className="tl_text">
                        {messages['hello.text6']}
                    </p>
                    <h2 id={rid} className="tl_sub_header">
                        {messages['hello.sub3']}
                    </h2>
                    <p id={rid} className="tl_text">
                        {messages['hello.text11']} <br />
                    </p>
                    <div className="tl_img_wrap">
                        <img className="tl_img" src={img2} alt="תמונת נעים להכיר"></img>
                    </div>
                    <p id={rid} className="tl_text">
                        {messages['hello.text12']}
                        <br /> <br /> {messages['hello.text13']} <br /> {messages['hello.text14']}
                        <button
                            id={rid}
                            className="tl_link"
                            onClick={navigateToFirstGrade}
                            aria-label={messages['hello.link14']}
                        >
                            {messages['hello.link14']}
                        </button>
                        <br /> <br />
                        {messages['hello.text16']} <br /> {messages['hello.text17']} <br />
                        {messages['hello.text18']} <br /> <br />
                        {messages['hello.text19']}
                        <br /> <br />
                        {messages['hello.text20']} <br /> <br /> {messages['hello.text21']}
                    </p>
                </div>
            </div>
        </MainLayout>
    );
}

export default NiceToMeet;
