import React from 'react';
import { useMediaQueryWrap } from '../useMediaQueryWrap';
import Modal from '@mui/material/Modal';
import './MainModal.css';

export type modalId = 'address' | 'login' | 'camera' | 'folder';

export interface IMainModal {
    id: modalId;
    closeIcon?: string;
    children: React.ReactNode;
    open: boolean;
    close: () => void;
}

function MainModal(props: IMainModal): JSX.Element {
    const { get_modal_width, is_horiz } = useMediaQueryWrap();
    const [mw, setMw] = React.useState(window.innerWidth * get_modal_width());

    React.useEffect(() => {
        setMw(window.innerWidth * get_modal_width());
        if (!is_horiz) return;
        if (props.id !== 'camera' && props.id !== 'folder') return;
        const custom_shrink = props.id === 'camera' ? 0.9 : 0.7;
        const newwidth = window.innerHeight * 1.145 * custom_shrink;
        setMw(newwidth);
    }, [is_horiz]);

    return (
        <Modal open={props.open} onClose={props.close}>
            <div
                className="modalWrap"
                id="modalWrapId"
                style={{
                    width: mw,
                }}
            >
                <div className="modal_content" id={props.id}>
                    {props.closeIcon && (
                        <div className="close_modal_wrap">
                            <img
                                className="close_modal_img"
                                src={props.closeIcon}
                                onClick={props.close}
                                alt="סגור"
                            ></img>
                        </div>
                    )}
                    {props.children}
                </div>
            </div>
        </Modal>
    );
}

export default MainModal;
