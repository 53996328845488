import simple_ins from 'src/assets/sounds/paint_simple.mp3';
import ins from 'src/assets/sounds/paint.mp3';

export interface IPaintMazeCellAnswer {
    color: string;
    text: string;
}

export interface IPaintMazeCell {
    riddle?: string;
    color?: string; // color = answer
    fixed_answer?: IPaintMazeCellAnswer;
}

export interface IPaintMazeLvl {
    answer_control: number[];
    cells: IPaintMazeCell[];
    default_answer: string;
    instructions: string;
}

interface IPaintMazeDict {
    [key: number]: IPaintMazeLvl;
}

const cells = 15 * 7;

export function usePaintMazeConfig() {
    const lvl1: IPaintMazeCell[] = [...Array(cells).keys()].map(() => {
        return {};
    });

    lvl1[45] = { fixed_answer: { color: 'yellow', text: '10' } };
    lvl1[60] = { fixed_answer: { color: 'red', text: '9' } };
    lvl1[75] = { fixed_answer: { color: 'green', text: '6' } };
    lvl1[90] = { fixed_answer: { color: 'brown', text: '5' } };

    lvl1[3] = { riddle: '0' };
    lvl1[4] = { riddle: '1+6' };
    lvl1[5] = { riddle: '3+5' };
    lvl1[6] = { riddle: '8' };
    lvl1[7] = { riddle: '9+0', color: 'red' };
    lvl1[8] = { riddle: '5+2' };
    lvl1[9] = { riddle: '1+3' };
    lvl1[10] = { riddle: '4+4' };
    lvl1[11] = { riddle: '3' };
    lvl1[18] = { riddle: '7+1' };
    lvl1[19] = { riddle: '3+0' };
    lvl1[20] = { riddle: '2+1' };
    lvl1[21] = { riddle: '7+2', color: 'red' };
    lvl1[22] = { riddle: '6+3', color: 'red' };
    lvl1[23] = { riddle: '8+1', color: 'red' };
    lvl1[24] = { riddle: '4+3' };
    lvl1[25] = { riddle: '7+0' };
    lvl1[26] = { riddle: '1+3' };
    lvl1[33] = { riddle: '8' };
    lvl1[34] = { riddle: '2+5' };
    lvl1[35] = { riddle: '3+6', color: 'red' };
    lvl1[36] = { color: 'red' };
    lvl1[37] = { riddle: '0+9', color: 'red' };
    lvl1[38] = { riddle: '1+8', color: 'red' };
    lvl1[39] = { riddle: '2+7', color: 'red' };
    lvl1[40] = { riddle: '3+5' };
    lvl1[41] = { riddle: '2+6' };
    lvl1[48] = { riddle: '4+4' };
    lvl1[49] = { riddle: '5+4', color: 'red' };
    lvl1[50] = { riddle: '3+7', color: 'yellow' };
    lvl1[51] = { riddle: '9+1', color: 'yellow' };
    lvl1[52] = { riddle: '5+5', color: 'yellow' };
    lvl1[53] = { riddle: '7+3', color: 'yellow' };
    lvl1[54] = { color: 'yellow' };
    lvl1[55] = { riddle: '4+5', color: 'red' };
    lvl1[56] = { riddle: '8' };

    lvl1[63] = { riddle: '6+1' };
    lvl1[64] = { riddle: '2+2' };
    lvl1[65] = { riddle: '2+8', color: 'yellow' };
    lvl1[66] = { riddle: '3+3', color: 'green' };
    lvl1[67] = { riddle: '4+6', color: 'yellow' };
    lvl1[68] = { riddle: '4+2', color: 'green' };
    lvl1[69] = { riddle: '0+10', color: 'yellow' };
    lvl1[70] = { riddle: '1' };
    lvl1[71] = { riddle: '6+2' };

    lvl1[78] = { riddle: '3+1' };
    lvl1[79] = { riddle: '7' };
    lvl1[80] = { color: 'yellow' };
    lvl1[81] = { riddle: '10+0', color: 'yellow' };
    lvl1[82] = { riddle: '4+1', color: 'brown' };
    lvl1[83] = { riddle: '1+9', color: 'yellow' };
    lvl1[84] = { riddle: '2+8', color: 'yellow' };
    lvl1[85] = { riddle: '3+0' };
    lvl1[86] = { riddle: '3' };

    lvl1[93] = { riddle: '2' };
    lvl1[94] = { riddle: '0+4' };
    lvl1[95] = { riddle: '6+4', color: 'yellow' };
    lvl1[96] = { riddle: '8+2', color: 'yellow' };
    lvl1[97] = { riddle: '2+3', color: 'brown' };
    lvl1[98] = { riddle: '7+3', color: 'yellow' };
    lvl1[99] = { color: 'yellow' };
    lvl1[100] = { riddle: '4' };
    lvl1[101] = { riddle: '' };

    const lvl2: IPaintMazeCell[] = [...Array(cells).keys()].map(() => {
        return {};
    });

    lvl2[30] = { fixed_answer: { color: 'red', text: '10' } };
    lvl2[45] = { fixed_answer: { color: 'green', text: '9' } };
    lvl2[60] = { fixed_answer: { color: '#2196f3', text: '8' } };
    lvl2[75] = { fixed_answer: { color: 'yellow', text: '7' } };
    lvl2[90] = { fixed_answer: { color: 'purple', text: '6' } };

    lvl2[4] = { riddle: '6+4', color: 'red' };
    lvl2[5] = { riddle: '3+7', color: 'red' };
    lvl2[9] = { riddle: '1+9', color: 'red' };
    lvl2[10] = { riddle: '10+0', color: 'red' };

    lvl2[18] = { riddle: '9+1', color: 'red' };
    lvl2[19] = { riddle: '4+6', color: 'red' };
    lvl2[20] = { riddle: '10+0', color: 'red' };
    lvl2[21] = { riddle: '5+5', color: 'red' };
    lvl2[22] = { riddle: '3' };
    lvl2[23] = { riddle: '8+2   ', color: 'red' };
    lvl2[24] = { riddle: '6+4', color: 'red' };
    lvl2[25] = { riddle: '7+3', color: 'red' };
    lvl2[26] = { riddle: '2+8', color: 'red' };

    lvl2[33] = { riddle: '5+2', color: 'yellow' };
    lvl2[34] = { riddle: '7+0', color: 'yellow' };
    lvl2[35] = { riddle: '6+1', color: 'yellow' };
    lvl2[36] = { riddle: '2+5', color: 'yellow' };
    lvl2[37] = { riddle: '4+3', color: 'yellow' };
    lvl2[38] = { riddle: '7', color: 'yellow' };
    lvl2[39] = { riddle: '3+4', color: 'yellow' };
    lvl2[40] = { riddle: '1+6', color: 'yellow' };
    lvl2[41] = { riddle: '2+5', color: 'yellow' };

    lvl2[48] = { riddle: '2' };
    lvl2[49] = { riddle: '1+8', color: 'green' };
    lvl2[50] = { riddle: '7+2', color: 'green' };
    lvl2[51] = { riddle: '9+0', color: 'green' };
    lvl2[52] = { riddle: '5+4', color: 'green' };
    lvl2[53] = { riddle: '2+7', color: 'green' };
    lvl2[54] = { riddle: '6+3', color: 'green' };
    lvl2[55] = { riddle: '4+5', color: 'green' };
    lvl2[56] = { riddle: '1' };

    lvl2[63] = { riddle: '3' };
    lvl2[64] = { riddle: '1+0' };
    lvl2[65] = { riddle: '7+1', color: '#2196f3' };
    lvl2[66] = { riddle: '8', color: '#2196f3' };
    lvl2[67] = { riddle: '6+2', color: '#2196f3' };
    lvl2[68] = { riddle: '3+5', color: '#2196f3' };
    lvl2[69] = { riddle: '4+4', color: '#2196f3' };
    lvl2[70] = { riddle: '5+0' };
    lvl2[71] = { riddle: '1+1' };

    lvl2[78] = { riddle: '0+3' };
    lvl2[79] = { riddle: '11' };
    lvl2[80] = { riddle: '2+2' };
    lvl2[81] = { riddle: '4+2', color: 'purple' };
    lvl2[82] = { riddle: '3+3', color: 'purple' };
    lvl2[83] = { riddle: '5+1', color: 'purple' };
    lvl2[84] = { riddle: '4' };
    lvl2[85] = { riddle: '3+1' };
    lvl2[86] = { riddle: '4+0' };

    lvl2[93] = { riddle: '2+2' };
    lvl2[94] = { riddle: '3' };
    lvl2[95] = { riddle: '5' };
    lvl2[96] = { riddle: '4+1' };
    lvl2[97] = { riddle: '1+5', color: 'purple' };
    lvl2[98] = { riddle: '3+2' };
    lvl2[99] = { riddle: '4+1' };
    lvl2[100] = { riddle: '2+3' };
    lvl2[101] = { riddle: '5' };

    const lvl3: IPaintMazeCell[] = [...Array(cells).keys()].map(() => {
        return {};
    });

    lvl3[60] = { fixed_answer: { color: 'cyan', text: '5<' } };
    lvl3[75] = { fixed_answer: { color: 'red', text: '=5' } };
    lvl3[90] = { fixed_answer: { color: 'green', text: '5>' } };

    lvl3[5] = { riddle: '7+2', color: 'cyan' };
    lvl3[6] = { riddle: '4+2', color: 'cyan' };
    lvl3[7] = { riddle: '6+4', color: 'cyan' };
    lvl3[8] = { riddle: '8', color: 'cyan' };
    lvl3[9] = { riddle: '4+2', color: 'cyan' };
    lvl3[10] = { riddle: '1+9', color: 'cyan' };
    lvl3[11] = { riddle: '6', color: 'cyan' };

    lvl3[19] = { riddle: '8', color: 'cyan' };
    lvl3[20] = { riddle: '3+3', color: 'cyan' };
    lvl3[21] = { riddle: '5', color: 'red' };
    lvl3[22] = { riddle: '10', color: 'cyan' };
    lvl3[23] = { riddle: '6+1', color: 'cyan' };
    lvl3[24] = { riddle: '7', color: 'cyan' };
    lvl3[25] = { riddle: '3+2', color: 'red' };
    lvl3[26] = { riddle: '4+2', color: 'cyan' };
    lvl3[27] = { riddle: '5+2', color: 'cyan' };

    lvl3[34] = { riddle: '6', color: 'cyan' };
    lvl3[35] = { riddle: '3+2', color: 'red' };
    lvl3[36] = { color: 'yellow' };
    lvl3[37] = { riddle: '4+1', color: 'red' };
    lvl3[38] = { riddle: '9', color: 'cyan' };
    lvl3[39] = { riddle: '1+4', color: 'red' };
    lvl3[40] = { color: 'yellow' };
    lvl3[41] = { riddle: '5', color: 'red' };
    lvl3[42] = { riddle: '10', color: 'cyan' };

    lvl3[49] = { riddle: '2+5', color: 'cyan' };
    lvl3[50] = { riddle: '7', color: 'cyan' };
    lvl3[51] = { riddle: '2+3', color: 'red' };
    lvl3[52] = { riddle: '5+1', color: 'cyan' };
    lvl3[53] = { riddle: '4+3', color: 'cyan' };
    lvl3[54] = { riddle: '6', color: 'cyan' };
    lvl3[55] = { riddle: '4+1', color: 'red' };
    lvl3[56] = { riddle: '4+4', color: 'cyan' };
    lvl3[57] = { riddle: '5+5', color: 'cyan' };

    lvl3[64] = { riddle: '9', color: 'cyan' };
    lvl3[65] = { riddle: '1+5', color: 'cyan' };
    lvl3[66] = { riddle: '2+2', color: 'green' };
    lvl3[67] = { riddle: '4+3', color: 'cyan' };
    lvl3[68] = { riddle: '3+3', color: 'cyan' };
    lvl3[69] = { riddle: '8+1', color: 'cyan' };
    lvl3[70] = { riddle: '3+1', color: 'green' };
    lvl3[71] = { riddle: '3+6', color: 'cyan' };
    lvl3[72] = { riddle: '5+3', color: 'cyan' };

    lvl3[79] = { riddle: '1+6', color: 'cyan' };
    lvl3[80] = { riddle: '6+2', color: 'cyan' };
    lvl3[81] = { riddle: '4', color: 'green' };
    lvl3[82] = { riddle: '7', color: 'cyan' };
    lvl3[83] = { riddle: '2+5', color: 'cyan' };
    lvl3[84] = { riddle: '2+4', color: 'cyan' };
    lvl3[85] = { riddle: '1+2', color: 'green' };
    lvl3[86] = { riddle: '7+1', color: 'cyan' };
    lvl3[87] = { riddle: '9', color: 'cyan' };

    lvl3[94] = { riddle: '2+4', color: 'cyan' };
    lvl3[95] = { riddle: '8', color: 'cyan' };
    lvl3[96] = { riddle: '1', color: 'green' };
    lvl3[97] = { riddle: '5+1', color: 'cyan' };
    lvl3[98] = { riddle: '10', color: 'cyan' };
    lvl3[99] = { riddle: '7+3', color: 'cyan' };
    lvl3[100] = { riddle: '3', color: 'green' };
    lvl3[101] = { riddle: '3+4', color: 'cyan' };
    lvl3[102] = { riddle: '11', color: 'cyan' };

    const lvl4: IPaintMazeCell[] = [...Array(cells).keys()].map(() => {
        return {};
    });

    lvl4[60] = { fixed_answer: { color: 'yellow', text: '5<' } };
    lvl4[75] = { fixed_answer: { color: 'cyan', text: '=5' } };
    lvl4[90] = { fixed_answer: { color: 'brown', text: '5>' } };

    lvl4[2] = { riddle: '10', color: 'yellow' };
    lvl4[3] = { riddle: '8', color: 'yellow' };
    lvl4[4] = { riddle: '7', color: 'yellow' };
    lvl4[5] = { riddle: '6', color: 'yellow' };
    lvl4[6] = { riddle: '8', color: 'yellow' };
    lvl4[7] = { riddle: '1', color: 'brown' };
    lvl4[8] = { riddle: '5', color: 'cyan' };
    lvl4[9] = { riddle: '5', color: 'cyan' };
    lvl4[10] = { riddle: '5', color: 'cyan' };
    lvl4[11] = { riddle: '7', color: 'yellow' };
    lvl4[12] = { riddle: '6', color: 'yellow' };
    lvl4[13] = { riddle: '10', color: 'yellow' };

    lvl4[17] = { riddle: '7', color: 'yellow' };
    lvl4[18] = { riddle: '6', color: 'yellow' };
    lvl4[19] = { riddle: '8', color: 'yellow' };
    lvl4[20] = { riddle: '7', color: 'yellow' };
    lvl4[21] = { riddle: '9', color: 'yellow' };
    lvl4[22] = { riddle: '3', color: 'brown' };
    lvl4[23] = { riddle: '5', color: 'cyan' };
    lvl4[24] = { riddle: '5', color: 'cyan' };
    lvl4[25] = { riddle: '5', color: 'cyan' };
    lvl4[26] = { riddle: '9', color: 'yellow' };
    lvl4[27] = { riddle: '8', color: 'yellow' };
    lvl4[28] = { riddle: '9', color: 'yellow' };

    lvl4[32] = { riddle: '8', color: 'yellow' };
    lvl4[33] = { riddle: '10', color: 'yellow' };
    lvl4[34] = { riddle: '8', color: 'yellow' };
    lvl4[35] = { riddle: '9', color: 'yellow' };
    lvl4[36] = { riddle: '7', color: 'yellow' };
    lvl4[37] = { riddle: '4', color: 'brown' };
    lvl4[38] = { riddle: '6', color: 'yellow' };
    lvl4[39] = { riddle: '7', color: 'yellow' };
    lvl4[40] = { riddle: '10', color: 'yellow' };
    lvl4[41] = { riddle: '6', color: 'yellow' };
    lvl4[42] = { riddle: '7', color: 'yellow' };
    lvl4[43] = { riddle: '6', color: 'yellow' };

    lvl4[47] = { riddle: '9', color: 'yellow' };
    lvl4[48] = { riddle: '1', color: 'brown' };
    lvl4[49] = { riddle: '9', color: 'yellow' };
    lvl4[50] = { riddle: '6', color: 'yellow' };
    lvl4[51] = { riddle: '10', color: 'yellow' };
    lvl4[52] = { riddle: '2', color: 'brown' };
    lvl4[53] = { riddle: '8', color: 'yellow' };
    lvl4[54] = { riddle: '6', color: 'yellow' };
    lvl4[55] = { riddle: '9', color: 'yellow' };
    lvl4[56] = { riddle: '10', color: 'yellow' };
    lvl4[57] = { riddle: '3', color: 'brown' };
    lvl4[58] = { riddle: '10', color: 'yellow' };

    lvl4[62] = { riddle: '7', color: 'yellow' };
    lvl4[63] = { riddle: '6', color: 'yellow' };
    lvl4[64] = { riddle: '2', color: 'brown' };
    lvl4[65] = { riddle: '3', color: 'brown' };
    lvl4[66] = { riddle: '1', color: 'brown' };
    lvl4[67] = { riddle: '3', color: 'brown' };
    lvl4[68] = { riddle: '1', color: 'brown' };
    lvl4[69] = { riddle: '3', color: 'brown' };
    lvl4[70] = { riddle: '4', color: 'brown' };
    lvl4[71] = { riddle: '2', color: 'brown' };
    lvl4[72] = { riddle: '9', color: 'yellow' };
    lvl4[73] = { riddle: '8', color: 'yellow' };

    lvl4[77] = { riddle: '5', color: 'cyan' };
    lvl4[78] = { riddle: '5', color: 'cyan' };
    lvl4[79] = { riddle: '5', color: 'cyan' };
    lvl4[80] = { riddle: '4', color: 'brown' };
    lvl4[81] = { riddle: '4', color: 'brown' };
    lvl4[82] = { riddle: '1', color: 'brown' };
    lvl4[83] = { riddle: '2', color: 'brown' };
    lvl4[84] = { riddle: '4', color: 'brown' };
    lvl4[85] = { riddle: '1', color: 'brown' };
    lvl4[86] = { riddle: '5', color: 'cyan' };
    lvl4[87] = { riddle: '5', color: 'cyan' };
    lvl4[88] = { riddle: '5', color: 'cyan' };

    lvl4[92] = { riddle: '5', color: 'cyan' };
    lvl4[93] = { riddle: '5', color: 'cyan' };
    lvl4[94] = { riddle: '5', color: 'cyan' };
    lvl4[95] = { riddle: '5', color: 'cyan' };
    lvl4[96] = { riddle: '5', color: 'cyan' };
    lvl4[97] = { riddle: '5', color: 'cyan' };
    lvl4[98] = { riddle: '5', color: 'cyan' };
    lvl4[99] = { riddle: '5', color: 'cyan' };
    lvl4[100] = { riddle: '5', color: 'cyan' };
    lvl4[101] = { riddle: '5', color: 'cyan' };
    lvl4[102] = { riddle: '5', color: 'cyan' };
    lvl4[103] = { riddle: '5', color: 'cyan' };

    const games: IPaintMazeDict = {
        1: {
            cells: lvl1,
            answer_control: [45, 60, 75, 90],
            default_answer: 'yellow',
            instructions: ins,
        },
        2: {
            cells: lvl2,
            answer_control: [30, 45, 60, 75, 90],
            default_answer: 'red',
            instructions: ins,
        },
        3: {
            cells: lvl3,
            answer_control: [60, 75, 90],
            default_answer: 'cyan',
            instructions: ins,
        },
        4: {
            cells: lvl4,
            answer_control: [60, 75, 90],
            default_answer: 'cyan',
            instructions: simple_ins,
        },
    };

    return { games };
}
