import React from 'react';
import MemoryCards from 'src/features/memory_cards_game/MemoryCards';
import { ITask } from 'src/wrapper';

export interface IClassMaze {
    task?: ITask;
    contentHeight: number;
    onFinish: () => void;
}
export function useClassMemoryCards({ task, contentHeight, onFinish }: IClassMaze) {
    const render_memory_cards = () => {
        if (!task) return null;
        if (!task.memory_cards) return null;
        return (
            <MemoryCards
                width={window.innerWidth * 0.75}
                height={contentHeight * 0.95}
                onFinish={onFinish}
                lvl={task.memory_cards}
            />
        );
    };

    const is_memory_cards = () => {
        if (!task) return false;
        return task.memory_cards !== undefined;
    };

    return {
        render_memory_cards,
        is_memory_cards,
    };
}
