import { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { useNav } from '../useNav';
import { useGoogleLogin } from '@react-oauth/google';
import getUserInfo from '../api/google.api';
import {
    EmailPasswordReset,
    IAddDeviceResponse,
    IEmailPassResetResponse,
    ILoginEmailPassResponse,
    addDevice,
    getUserState,
    getUserToken,
    logErrorinServer,
    loginEmailPassword,
    user2state,
} from '../api/server.api';
import { IUserState } from '../../wrapper';
import messages from '../../assets/messages_he.json';
import { clearLoginStorage, is_reffered_by_soical, log_event, validateEmail } from '../common';

const on_soical_platform_err =
    'היי! המערכת שלנו מזהה כי אתם גולשים כרגע דרך מערכת פייסבוק/אינסטגרם. כדי לחבר משתמש חדש חפשו בגוגל ״דמיון בקופסא״ ופיתחו את האתר דרך הדפדפן במכשירכם';

export function useLogin() {
    const { state, setState } = useContext(AppContext);
    const { navigateToHome } = useNav();
    const [loginErr, setLoginErr] = useState('');
    const [successMsg, setSuccMsg] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [onResetApi, setOnResetApi] = useState(false);

    const toggle_login_modal = (value: boolean) => {
        if (value) log_event('login_modal_open');
        setState({
            ...state,
            openLoginModal: value,
        });
    };

    const handle_err = (err_msg: string) => {
        setLoginErr(err_msg);
        clearLoginStorage();
        setState({
            ...state,
            dur_login: false,
        });
    };

    const onExistUser = (res: IAddDeviceResponse, user_data: IUserState) => {
        if (res === 'success') {
            log_event('login_click_success_exist_user');
            const new_state = user2state(user_data, state);
            setState({ ...state, ...new_state, openLoginModal: false, dur_login: false });
            navigateToHome();
            return;
        } else if (res === 'fail') {
            handle_err(messages['error.mulDevice']);
            log_event('login_multiple_device_err');
        } else {
            handle_err(messages['error.general']);
            log_event('existuser_login_failed');
        }
    };

    const onGetUserState = (user_data?: IUserState) => {
        // console.log(user_data);
        if (!user_data) {
            log_event('login_no_user_data');
            handle_err(messages['error.general']);
            return;
        }
        if (Object.keys(user_data).length === 0) {
            log_event('login_click_user_not_exist');
            handle_err('משתמש לא קיים');
        } else {
            const email = localStorage.getItem('ib_email'); // saves a DB call using the email.
            addDevice(email!, (res: IAddDeviceResponse) => onExistUser(res, user_data));
        }
    };

    const on_token = (token?: string) => {
        if (!token) {
            log_event('login_no_token');
            handle_err('משתמש לא קיים');
            return;
        }
        localStorage.setItem('ib_token', token);
        getUserState(token, onGetUserState);
    };

    // email - password

    const can_login_via_email_password = (): boolean => {
        return !!validateEmail(email) && password !== '';
    };

    const can_send_reset_password = (): boolean => {
        return !!validateEmail(email);
    };

    const onResetPassword = (res: IEmailPassResetResponse) => {
        if (res === 'success') {
            log_event('email_reset_pass_success');
            setSuccMsg('הסיסמא אופסה ונשלחה למייל');
        } else if (res === 'not_exist') {
            log_event('email_reset_pass_user_not_exist');
            handle_err('משתמש לא קיים');
        } else handle_err(messages['error.general']);
        setOnResetApi(false);
    };

    const reset_password = () => {
        if (!can_send_reset_password()) return;
        log_event('reset_password_click');
        setOnResetApi(true);
        EmailPasswordReset(email.toLocaleLowerCase(), onResetPassword);
    };

    const onEmailPasswordLoginSuccess = (res: ILoginEmailPassResponse) => {
        if (res === 'success') {
            localStorage.setItem('ib_email', email.toLocaleLowerCase());
            getUserToken(email.toLocaleLowerCase(), on_token);
        } else if (res === 'not_exist') {
            log_event('email_login_user_not_exist');
            handle_err('משתמש לא קיים');
        } else if (res === 'wrong_password') {
            log_event('email_login_wrong_password');
            handle_err('סיסמא שגויה');
        } else handle_err(messages['error.general']);
    };

    const emailPasswordLoginClick = () => {
        if (is_reffered_by_soical()) {
            log_event('on_soical_login_err');
            setLoginErr(on_soical_platform_err);
            return;
        }
        if (!can_login_via_email_password()) return;
        log_event('email_login_click');
        setState({
            ...state,
            dur_login: true,
        });
        loginEmailPassword(email.toLocaleLowerCase(), password, onEmailPasswordLoginSuccess);
    };

    // google

    const onGoogleInfoSuccess = (email: string, picture: string, name: string) => {
        localStorage.setItem('ib_picture', picture);
        localStorage.setItem('ib_name', name);
        localStorage.setItem('ib_email', email);
        setState({
            ...state,
            dur_login: true,
        });
        getUserToken(email, on_token);
    };

    const googleLoginClickAux = useGoogleLogin({
        onSuccess: (codeResponse) => {
            log_event('login_click_google_success');
            getUserInfo(codeResponse.access_token, onGoogleInfoSuccess);
        },
        onError: (errorResponse) => {
            log_event('login_click_failed');
            logErrorinServer(errorResponse.error ?? 'google login on error', document.referrer);
            alert(messages['error.general']);
        },
        onNonOAuthError: (nonOAuthError) => {
            logErrorinServer(`login_click_failed_no_auth: ${nonOAuthError.type}`, document.referrer);
        },
    });

    const googleLoginClick = () => {
        log_event('google_login_click');
        if (is_reffered_by_soical()) {
            log_event('on_soical_login_err');
            setLoginErr(on_soical_platform_err);
            return;
        }
        googleLoginClickAux();
    };

    return {
        googleLoginClick,
        emailPasswordLoginClick,
        toggle_login_modal,
        loginErr,
        setLoginErr,
        email,
        setPassword,
        password,
        setEmail,
        can_login_via_email_password,
        can_send_reset_password,
        successMsg,
        setSuccMsg,
        reset_password,
        onResetApi,
    };
}
